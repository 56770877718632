import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface IOwnProps extends AccordionItemProps {
  title: string;
  children: ReactNode | ReactNode[];
  gapPanel?: number | number[];
}

const AccordionTemplate: React.FC<IOwnProps> = ({ title, children, gapPanel, ...rest }) => {
  return (
    <AccordionItem {...rest}>
      <h2>
        <AccordionButton p={1.5} bg="primaryColor" color="white" borderRadius={4} _hover={'none'}>
          <Box as="span" flex="1" textAlign="center" fontSize="18px" fontWeight={'bold'}>
            {title}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel display="flex" flexDir="column" gap={gapPanel} pb={4}>
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default AccordionTemplate;
