import { useQuery } from "@tanstack/react-query";
import { configApi } from "../../configApi"

export interface IResultState{
    id: string;
    name: string;
    initials: string;
}


const RequestGetState = async ()=>{
    const response = await configApi.get<IResultState[]>('general-records/state/')
    return response.data
}


export const useRequestGetState = () =>{
    return useQuery({
        queryKey: ['get_state'],
        queryFn: RequestGetState,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    })
}