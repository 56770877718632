import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';
import { IGetRegistration } from '../Registration/useGetRegistration';

const VerifyExemptionStandBy = async (selectiveProcessId: string) => {
  const response = await configApi.get<IGetRegistration>('registration/there-is-registration-standby/', {
    params: {
      selective_process_id: selectiveProcessId,
    },
  });
  return response.data;
};

export const useVerifyExemptionStandBy = (selectiveProcessId: string) => {
  return useQuery({
    queryKey: ['verify_exemption_standby'],
    queryFn: () => VerifyExemptionStandBy(selectiveProcessId),
    refetchOnWindowFocus: false,
    gcTime: 0,
  });
};
