import { UseToastOptions } from '@chakra-ui/react';
import { configApi } from '../services/configApi';

export interface IDecodeToken {
  token_type: string;
  exp: number;
  iat: number;
  jti: string;
  user_id: string;
}

export const decodeToken = (): IDecodeToken | undefined => {
  let canUseLocalStorage = false;

  try {
      canUseLocalStorage = !!window.localStorage;
  } catch (e) {
      console.warn("LocalStorage não está acessível: ", e);
  }

  if (canUseLocalStorage) {
    const token = localStorage.getItem('token');
    if (token) {
      const bodyToken = token.split('.')[1];
      return JSON.parse(atob(bodyToken));
    }
  }

  return undefined;
};

export const handleDeleteAttach = (
  attachmentId: string,
  onToast: (props: UseToastOptions) => void,
  refetch: () => void,
) => {
  configApi
    .delete(`/attachment/delete/${attachmentId}/`)
    .then(() => {
      onToast({
        title: 'Sucesso',
        description: 'Anexo excluido com sucesso',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      refetch();
    })
    .catch(() => {
      onToast({
        title: 'Erro',
        description: 'Erro na solicitação, tente novamente mais tarde',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    });
};
