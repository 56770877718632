import { Badge, Flex, ListItem, Spinner, Stack, UnorderedList } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ISimpleResource, useRequestResources } from '../../../services/hooks/Resources/useRequestResources';
import { RiSignalWifiErrorLine } from 'react-icons/ri';
import MainCard from '../../../components/MainCard/Index';
import { TextL, TextM, TextXS, TextXXS } from '../../../components/Text/Index';
import { TiArrowRight } from 'react-icons/ti';
import { badgeColor } from '../../../utils/badgeFunction';
const Resources: React.FC = () => {
  const navigate = useNavigate();
  const { data, error, isLoading } = useRequestResources();

  const handleShowResource = (resource: ISimpleResource, index: number) => {
    const list = (
      <ListItem key={index}>
        <Flex gap={1} align="center">
          <TiArrowRight fill="#0064de" size={25} />
          <TextM
            cursor="pointer"
            onClick={() => {
              navigate('/painel/recursos/mostrar-mais', {
                state: resource.resource_id,
              });
            }}
            color="colorLink"
          >
            {resource?.period_identification || 'Nao informado'} {'  '}
            <TextXXS as="span" color="#afafaf" fontWeight="bold">
              - {resource?.period}
            </TextXXS>
            <Badge
              fontSize={'11px'}
              ml={2}
              borderRadius={4}
              textAlign="center"
              lineHeight="normal"
              p={'4px'}
              bg={badgeColor(`${resource?.resource_is_open ? 'Em andamento' : 'Finalizado'}`)?.bgColor || 'black'}
              color={badgeColor(`${resource?.resource_is_open ? 'Em andamento' : 'Finalizado'}`)?.color || 'white'}
            >
              {resource?.resource_is_open ? 'Em andamento' : 'Finalizado'}
            </Badge>
          </TextM>
        </Flex>
      </ListItem>
    );
    if (resource.is_against_question) {
      if (resource.show_resource_against_question) {
        return list;
      } else return undefined;
    } else {
      return list;
    }
  };

  return (
    <MainCard height="100%" title="ÁREA DO CANDIDATO" subtitle="- MEUS RECURSOS">
      {(isLoading && (
        <Stack height="100%" justify="center" align="center">
          <Spinner size="lg" />
        </Stack>
      )) ||
        (error && (
          <Stack height="100%" color="red" justify="center" align="center">
            <RiSignalWifiErrorLine size={50} />
            <TextL fontWeight="bold" fontSize="16px">
              Erro ao trazer os dados!
            </TextL>
          </Stack>
        )) ||
        (data &&
          data?.map((resource) => (
            <Stack key={resource.selective_process_id} px={['10px', '10px', '10px', '50px', '50px']}>
              <Stack>
                <TextM fontWeight="bold">{resource.selective_process_name}</TextM>
                {resource?.vacancies?.map((vacancie, index) => (
                  <UnorderedList key={index} ml={6} display="flex" flexDir="column" gap={1}>
                    {/* <ListItem>
                      <TextS>
                        <strong>{resource.is_cultural_house ? 'Turma: ' : 'Vaga/Cargo:'} </strong>{' '}
                        {vacancie.vacancy_name}
                      </TextS>
                    </ListItem> */}
                    <UnorderedList listStyleType={'""'}>
                      {vacancie?.resources?.map((resource, index) => handleShowResource(resource, index))}
                    </UnorderedList>
                  </UnorderedList>
                ))}
              </Stack>
            </Stack>
          ))) || (
          <Stack p={10}>
            <TextXS>VOCÊ AINDA NÃO POSSUI RECURSOS PROTOCOLADOS.</TextXS>
          </Stack>
        )}
    </MainCard>
  );
};

export default Resources;
