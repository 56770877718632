import { Flex, Stack, useDisclosure, useToast } from '@chakra-ui/react';
import InputTemplate from '../../../components/InputTemplate/Index';

//imports do HookForm
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//Yup

import CustomButton from '../../../components/Button/Index';
import * as Yup from 'yup';

import { configApi } from '../../../services/configApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosEyeOff } from 'react-icons/io';
import CustomModal from '../../Modal/ModalTemplate';

import protegido from '../../../assets/protegido.png';
import { TextXS } from '../../Text/Index';

const schema = Yup.object().shape({
  new_password: Yup.string().min(8, 'A senha deve ter no mínimo 8 caracteres').required('Senha é obrigatória'),
  new_password_confirm: Yup.string()
    .oneOf([Yup.ref('new_password')], 'As senhas devem coincidir')
    .required('Confirmação de senha é obrigatória'),
});

const FormResetPassword: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const path = useLocation().pathname as string;
  const token = path.split('/');
  const toast = useToast();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const recoveryRequest = (data: FieldValues) => {
    configApi
      .post('auth/reset-password/' + token[token.length - 2] + '/', {
        new_password: data.new_password,
        new_password_confirm: data.new_password_confirm,
      })
      .then(() => {
        onOpen();
      })
      .catch((error) => {
        toast({
          title: 'Erro',
          description: error?.response?.data?.message || 'Erro na solicitação',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      });
  };
  return (
    <>
      <Stack mt={'-20px'} as="form" w="100%" onSubmit={handleSubmit(recoveryRequest)}>
        <Stack gap="60px" w="100%">
          <Stack gap={3}>
            <InputTemplate
              icon={<IoIosEyeOff size={30} />}
              label="Senha"
              type="password"
              {...register('new_password')}
            />
            <TextXS color={'red'}>{errors.new_password?.message}</TextXS>
            <InputTemplate
              icon={<IoIosEyeOff size={30} />}
              label="Confirmar Senha"
              type="password"
              {...register('new_password_confirm')}
            />
            <TextXS color={'red'}>{errors.new_password_confirm?.message}</TextXS>
          </Stack>

          <Flex flexDirection="column" gap="20px">
            <CustomButton label="REDEFINIR SENHA" type="submit" />
          </Flex>
        </Stack>
      </Stack>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        imageSrc={protegido}
        modalTitle="SENHA REDEFINIDA COM SUCESSO"
        primaryButtonLabel="VOLTAR PARA TELA DE LOGIN"
        primaryButtonOnClick={() => {
          navigate('/login');
        }}
      >
        <TextXS>
          Sua senha foi alterada com sucesso. Agora você pode acessar sua conta usando sua nova senha. Se quiser voltar
          para a tela de login e acessar sua conta, basta clicar no botão: Ir para a Tela de Login.
        </TextXS>
        <TextXS>
          Se você não realizou esta alteração, por favor, entre em contato imediatamente com nossa equipe de suporte
          para garantir a segurança da sua conta.
        </TextXS>
      </CustomModal>
    </>
  );
};

export default FormResetPassword;
