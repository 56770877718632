import { useMutation } from '@tanstack/react-query';
import { configApi } from '../../configApi';

export interface INewImpugnation {
  objection?: string;
}

const createImpugnation = async (data: INewImpugnation, selective_process: string) => {
  const formData = new FormData();

  if (data.objection) {
    formData.append('objection', data.objection);
  }

  if (selective_process) {
    formData.append('selective_process', selective_process);
  }

  const response = await configApi.post(`selective-process/selective-process-impugnation/create/`, formData);
  return response;
};

export const usecreateImpugnation = (selective_process: string) => {
  return useMutation({
    mutationFn: (data: INewImpugnation) => createImpugnation(data, selective_process),
    mutationKey: ['createImpugnation', selective_process],
  });
};
