import { Box, Flex, FormControl, Link, Stack, useToast } from '@chakra-ui/react';
import InputTemplate from '../../InputTemplate/Index';
import { IoIosEyeOff } from 'react-icons/io';

//imports do HookForm
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//Yup
import { object, string } from 'yup';
import CustomButton from '../../Button/Index';
import { useContext, useState } from 'react';
import { ContextAuth } from '../../../contexts/Authentication';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MessageError from '../../MessageError';
import { TextXS } from '../../Text/Index';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const schema = object({
  email: string().required('O campo é obrigatório').email('Formato de e-mail inválido!'),
  password: string().required('Campo Obrigatório'),
});

export interface INavigationProps {
  route?: string;
  selectiveProcessId?: string;
}

const FormSignIn: React.FC = () => {
  const [searchParams] = useSearchParams();
  const route = searchParams?.get('route');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  // const { executeRecaptcha } = useGoogleReCaptcha();

  // const handleReCaptchaVerify = useCallback(async () => {
  //   if (!executeRecaptcha) {
  //     console.log('Execute recaptcha not yet available');
  //     return;
  //   }

  //   const token = await executeRecaptcha();
  //   console.log(token);
  // }, [executeRecaptcha]);

  const toast = useToast();

  const { signIn } = useContext(ContextAuth);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const loginRequest = (data: FieldValues) => {
    setIsSubmitting(true);
    signIn(data.email, data.password)
      .then(() => {
        navigate(route || '/painel');
      })
      .catch(() => {
        toast({
          title: 'Erro ao Logar',
          description: 'Verifique seu e-mail e senha',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
    // handleReCaptchaVerify().then(() => {
    // });
  };
  return (
    <Stack as="form" w="100%" onSubmit={handleSubmit(loginRequest)}>
      <Flex id="recaptch-box" position="absolute" />
      <FormControl display="flex" flexDirection="column" gap={['5px', '5px', '20px', '20px', '20px']} w="100%">
        <Box>
          <InputTemplate label="E-mail" type="email" {...register('email')} />
          <MessageError>{errors.email?.message}</MessageError>
        </Box>
        <Box>
          <InputTemplate icon={<IoIosEyeOff size={30} />} label="Senha" type="password" {...register('password')} />
          <MessageError>{errors?.password?.message}</MessageError>
          <TextXS mt="2%" textAlign="right">
            <Link
              onClick={() => {
                navigate('/recuperar-senha');
              }}
            >
              Esqueceu a senha?
            </Link>
          </TextXS>
        </Box>

        <Flex flexDirection="column" gap="20px" mt="30px">
          {!isSubmitting ? (
            <CustomButton
              className="g-recaptcha"
              data-sitekey="6Lf06fwpAAAAAFXLC-hhIeQG-l28DEiiEa2Sb9R-"
              data-callback="onSubmit"
              data-action="submit"
              label="ENTRAR"
              type="submit"
            />
          ) : (
            <CustomButton label="Entrando..." />
          )}

          {/* <CustomButton
            onClick={() => {
              navigate('/cadastro');
            }}
            bgColor="transparent"
            label="CADASTRE-SE"
            textColor="black"
            borderColor="primaryColor"
          /> */}
        </Flex>
        {/* <Flex alignItems="center" justifyContent="center" my={4}>
          <Box borderBottom="1px solid black" flex={1} />
          <TextXS mx={2}>OU</TextXS>
          <Box borderBottom="1px solid black" flex={1} />
        </Flex> */}
      </FormControl>
    </Stack>
  );
};

export default FormSignIn;
