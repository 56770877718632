import { Box, Divider, Flex, Stack } from '@chakra-ui/react';
import MainCard from '../../../components/MainCard/Index';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetImpugnationDetail } from '../../../services/hooks/Impugnation/useGetImpugnationDetail';
import { useContext, useEffect } from 'react';
import { ContextAuth } from '../../../contexts/Authentication';
import { TextXL, TextXS } from '../../../components/Text/Index';
import { DefaultButton } from '../../../components/ButtonDefault/Index';

const ImpugnationDetail: React.FC = () => {
  const navigate = useNavigate();
  const impugnationId = useLocation().state as string;
  const getImpugnation = useGetImpugnationDetail(impugnationId);
  const { candidate } = useContext(ContextAuth);

  useEffect(() => {
    if (!impugnationId) navigate(-1);
  }, []);

  return (
    <MainCard title="ÁREA DO CANDIDATO - " subtitle="IMPUGNAÇÃO CONTRA EDITAL">
      <Stack px={['10px', '10px', '10px', '50px', '50px']} gap={5} flexDir="column">
        <TextXL>Dados da solicitação</TextXL>
        <Flex align="center" gap={1}>
          <TextXS fontWeight="bold">Processo seletivo:</TextXS>
          <TextXS>
            {getImpugnation?.data?.selective_process?.type} - {getImpugnation.data?.selective_process.name}
          </TextXS>
        </Flex>
        <Flex align="center" gap={1}>
          <TextXS fontWeight="bold">Nome completo:</TextXS>
          <TextXS>{candidate?.user?.full_name}</TextXS>
        </Flex>

        <Flex align="center" gap={1}>
          <TextXS fontWeight="bold">CPF:</TextXS>
          <TextXS>{getImpugnation?.data?.candidate.cpf}</TextXS>
        </Flex>

        <Flex align="center" gap={1}>
          <TextXS fontWeight="bold">Data de envio:</TextXS>
          <TextXS>{getImpugnation?.data?.created_at?.br || ''}</TextXS>
        </Flex>
        <Stack>
          <TextXS fontWeight="bold">Detalhes da solicitação:</TextXS>
          <Stack
            borderRadius={4}
            boxShadow={' rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;'}
            border="1px solid #999999"
            minH="150px"
            h="fit-content"
            p={5}
          >
            <TextXS whiteSpace="break-spaces">{getImpugnation.data?.objection}</TextXS>
          </Stack>
        </Stack>
        {getImpugnation?.data?.selective_process?.no_reply && <Divider borderColor="gray" />}
        {getImpugnation?.data?.selective_process?.no_reply && (
          <Box>
            <TextXL>Dados da análise da solicitação</TextXL>
            <Flex gap={1}>
              <TextXS fontWeight="bold">Situação: </TextXS>
              <TextXS>{getImpugnation?.data?.status?.name}</TextXS>
            </Flex>
            {getImpugnation.data?.date_of_response_objection && (
              <Flex gap={1}>
                <TextXS fontWeight="bold">Data da análise: </TextXS>
                <TextXS>{getImpugnation?.data?.date_of_response_objection}</TextXS>
              </Flex>
            )}

            {getImpugnation.data?.response_objection && (
              <Stack>
                <TextXS fontWeight="bold">Justificativa da situação:</TextXS>
                <Stack
                  borderRadius={4}
                  boxShadow={' rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;'}
                  border="1px solid #999999"
                  minH="150px"
                  h="fit-content"
                  p={5}
                >
                  <TextXS whiteSpace="break-spaces">{getImpugnation.data?.response_objection}</TextXS>
                </Stack>
              </Stack>
            )}
          </Box>
        )}

        <Stack alignItems="flex-end">
          <DefaultButton
            onClick={() => {
              navigate(-1);
            }}
            _hover={{
              bg: '#5389ca',
            }}
            label="Voltar"
          />
        </Stack>
      </Stack>
    </MainCard>
  );
};

export default ImpugnationDetail;
