import { Spinner, Stack } from '@chakra-ui/react';
import MainCard from '../../../../components/MainCard/Index';
import { useGetRegistration } from '../../../../services/hooks/Registration/useGetRegistration';
import { useLocation } from 'react-router-dom';
import InvoicePDFButton from '../../../../components/PDFs/DownloadPDFButton/invoiceButton';
import { TextS } from '../../../../components/Text/Index';
interface IOwnProps {
  previousRoute?: string;
  registrationId?: string;
}

const PaymentReissue: React.FC<IOwnProps> = () => {
  const navProps = useLocation().state as IOwnProps;
  const getRegistration = useGetRegistration(navProps?.registrationId || '');
  return (
    <MainCard title="ÁREA DO CANDIDATO - " subtitle="FORMA DE PAGAMENTO">
      {getRegistration?.isLoading ? (
        <Stack px={['10px', '10px', '10px', '50px', '50px']}>
          <Spinner />
        </Stack>
      ) : (
        <Stack height="100%" px={['10px', '10px', '10px', '50px', '50px']}>
          <TextS>
            <strong>Atenção!</strong> Sua inscrição somente será efetivada após a confirmação do pagamento da taxa de
            inscrição e sua compensação bancária.
          </TextS>
          <TextS>
            <strong>Valor da inscrição: </strong> R$ {getRegistration.data?.selective_process_vacancy?.value || '--'}
          </TextS>

          <InvoicePDFButton registrationId={navProps?.registrationId || ''} />
        </Stack>
      )}
    </MainCard>
  );
};

export default PaymentReissue;
