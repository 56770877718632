import { useContext } from 'react';
import { ContextAuth } from '../contexts/Authentication';
import { decodeToken } from '../utils/functions';
import { configApi } from './configApi';
import { Flex, Spinner } from '@chakra-ui/react';

interface ProtectedRouteProps {
  authenticatedRoute: JSX.Element;
  noAuthRoute: JSX.Element;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ authenticatedRoute, noAuthRoute }) => {
  const { isAuth, signOut, loadingCandidate } = useContext(ContextAuth);

  if (loadingCandidate) {
    return (
      <Flex align="center" justify="center" height="100%" w="100%" flexDir="column" overflow="auto">
        <Spinner size="lg" />
      </Flex>
    );
  }

  const isAuthenticated = localStorage.getItem('token');

  if (isAuth === undefined) return;

  const dataToken = decodeToken();

  if (isAuthenticated && isAuth && dataToken) {
    const now = new Date();

    const brazilTime = new Date(now.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' })).getTime();
    const convertToMillisecond = dataToken.exp * 1000;
    if (brazilTime > convertToMillisecond) {
      signOut();
      return noAuthRoute;
    }
    configApi.defaults.headers.common.Authorization = `Bearer ${isAuthenticated}`;
    return authenticatedRoute;
  }

  return noAuthRoute;
};
