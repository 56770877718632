import { useQuery } from "@tanstack/react-query";
import { configApi } from "../../configApi"

interface IResultExemption{
    id: string;
    name: string;
}


const RequestGetExemptionType= async ()=>{
    const response = await configApi.get<IResultExemption[]>('selective-process/exemption-type/',{params:{is_active: true}})
    return response.data
}


export const useRequestGetExemptionType = () =>{
    return useQuery({
        queryKey: ['get_exemption_type'],
        queryFn: RequestGetExemptionType,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    })
}