import { useMutation } from '@tanstack/react-query';
import { configApi } from '../../configApi';
import { ICandidateData } from '../../../contexts/types';

export interface ISocialName {
  recent_photo?: FileList;
  declaration_of_request?: FileList;
  document_with_photo?: FileList;
  social_name?: string;
  ordering_by_social_name?: string;
  restroom_type?: string;
}

export interface IResponseSocialName {
  candidate: ICandidateData;
  declaration_of_request: string;
  document_with_photo: string;
  id: string;
  ordering_by_social_name: boolean;
  recent_photo: string;
  restroom_type: string;
  social_name: string;
}

const SocialName = async (data: ISocialName) => {
  const formData = new FormData();

  if (data.recent_photo && data.recent_photo.length > 0) {
    for (let index = 0; index < data.recent_photo.length; index++) {
      formData.append('recent_photo', data.recent_photo[index]);
    }
  }

  if (data.declaration_of_request && data.declaration_of_request.length > 0) {
    for (let index = 0; index < data.declaration_of_request.length; index++) {
      formData.append('declaration_of_request', data.declaration_of_request[index]);
    }
  }

  if (data.document_with_photo && data.document_with_photo.length > 0) {
    for (let index = 0; index < data.document_with_photo.length; index++) {
      formData.append('document_with_photo', data.document_with_photo[index]);
    }
  }

  if (data.social_name) {
    formData.append('social_name', data.social_name);
  }

  if (data.ordering_by_social_name) {
    formData.append('ordering_by_social_name', data.ordering_by_social_name);
  }

  if (data.restroom_type) {
    formData.append('restroom_type', data.restroom_type);
  }

  const response = await configApi.post<IResponseSocialName>(`candidate/social-name/create/`, formData);
  return response;
};

export const useSocialName = () => {
  return useMutation({
    mutationFn: (data: ISocialName) => SocialName(data),
    mutationKey: ['PostSocialName'],
  });
};
