import { useQuery } from "@tanstack/react-query";
import { configApi } from "../../configApi"

interface IResultState{
    id: string;
    name: string;
}


const RequestGetDocumentType = async ()=>{
    const response = await configApi.get<IResultState[]>('general-records/identification-document-type/')
    return response.data
}


export const useRequestGetDocumentType = () =>{
    return useQuery({
        queryKey: ['get_document_type'],
        queryFn: RequestGetDocumentType,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    })
}