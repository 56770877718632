import { useMutation } from '@tanstack/react-query';
import { configApi } from '../../configApi';

export interface INewExemption {
  type?: string;
  registration?: string;
  nis?: string;
  mother_name?: string;
  document_number?: string;
  document_issuing_body?: string;
  document_issuing_body_uf?: string;
  attachmentExemption?: string;
}

const NewExemption = async (data: INewExemption) => {
  const formData = new FormData();

  if (data.attachmentExemption && data.attachmentExemption.length > 0) {
    for (let index = 0; index < data.attachmentExemption.length; index++) {
      formData.append('files', data.attachmentExemption[index]);
    }
  }

  if (data.type) {
    formData.append('type', data.type);
  }

  if (data.registration) {
    formData.append('registration', data.registration);
  }

  if (data.nis) {
    formData.append('nis', data.nis);
  }

  if (data.mother_name) {
    formData.append('mother_name', data.mother_name);
  }

  if (data.document_number) {
    formData.append('document_number', data.document_number);
  }

  if (data.document_issuing_body) {
    formData.append('document_issuing_body', data.document_issuing_body);
  }

  if (data.document_issuing_body_uf) {
    formData.append('document_issuing_body_uf', data.document_issuing_body_uf);
  }

  const response = await configApi.post(`exemption/candidate/create/`, formData);
  return response.data;
};

export const useNewExemption = () => {
  return useMutation({
    mutationFn: (data: INewExemption) => NewExemption(data),
    mutationKey: ['new_exemption'],
  });
};
