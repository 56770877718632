import React from 'react';
import {
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import CustomButton from '../Button/Index';
import { TextS } from '../Text/Index';

interface CustomModalProps extends ModalContentProps {
  isOpen: boolean;
  onClose: () => void;
  modalTitle: string;
  primaryButtonLabel?: string;
  imageSrc?: string;
  primaryButtonOnClick?: () => void;
  secondaryButtonLabel?: string;
  secondaryButtonOnClick?: () => void;
  children: React.ReactNode;
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  imageSrc,
  modalTitle,
  primaryButtonLabel,
  primaryButtonOnClick,
  secondaryButtonLabel,
  secondaryButtonOnClick,
  children,
  ...rest
}) => {
  return (
    <Modal scrollBehavior="inside" isCentered closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        marginStart={3}
        marginEnd={3}
        gap={0}
        display="flex"
        alignItems={'center'}
        maxW={['100%', '300px', '800px', '800px', '800px']}
        padding={'30px'}
        minW="300px"
        {...rest}
      >
        <ModalHeader gap={4} display="flex" flexDirection="column" alignItems="center" textAlign="center">
          {imageSrc && <Image w="85px" h="85px" src={imageSrc} />}
          <TextS fontWeight="bold">{modalTitle}</TextS>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody scrollBehavior="initial" p={[0, 0, 4, 4, 4]}>
          {children}
        </ModalBody>
        <ModalFooter
          display="flex"
          flexDirection={['column', 'column', 'row', 'row', 'row']}
          w={['100%', '100%', 'auto', 'auto', 'auto']}
          alignItems={'center'}
          gap={3}
        >
          {primaryButtonLabel && (
            <CustomButton
              borderColor="primaryColor"
              label={primaryButtonLabel}
              colorScheme="blue"
              onClick={primaryButtonOnClick}
              fontSize="14px"
            />
          )}
          {secondaryButtonLabel && secondaryButtonOnClick && (
            <CustomButton
              label={secondaryButtonLabel}
              bgColor="transparent"
              textColor="black"
              borderColor="primaryColor"
              colorScheme="blue"
              onClick={secondaryButtonOnClick}
            />
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
