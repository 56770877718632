import { Button, Flex, FormControl, Input, Link, Radio, RadioGroup, Spinner, Stack, useToast } from '@chakra-ui/react';
import MainCard from '../../../components/MainCard/Index';
import { useLocation, useNavigate } from 'react-router-dom';

import * as Yup from 'yup';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateMaxFile, validateTypeFile, validationSizeFile } from '../../../utils/validationForm';
import { IResponseSocialName, useSocialName } from '../../../services/hooks/Registration/usePostSocialName';
import { useContext, useEffect } from 'react';
import { ContextAuth } from '../../../contexts/Authentication';
import { useUpdateSocialName } from '../../../services/hooks/Registration/useUpdateSocialName';
import { convertUrlToFile } from '../../../utils/getFileByUrl';
import { TextXS } from '../../../components/Text/Index';

interface INavigationProps {
  previousRoute?: string;
  registrationId?: string;
}

const SocialName: React.FC = () => {
  const NavProps = useLocation().state as INavigationProps;
  const navigate = useNavigate();
  const socialName = useSocialName();
  const toast = useToast();
  const { candidate, setCandidate } = useContext(ContextAuth);
  const updateSocialName = useUpdateSocialName();

  useEffect(() => {
    if (!NavProps?.registrationId) navigate('/painel');
  }, []);

  const schema = Yup.object().shape({
    social_name: Yup.string().required('Campo obrigatório'),
    ordering_by_social_name: Yup.string().required('Campo obrigatório'),
    restroom_type: Yup.string().required('Campo obrigatório'),
    recent_photo: Yup.mixed()
      .test('Required', 'Campo obrigatório', (file) => {
        const listFile = file as FileList;

        if (listFile?.length == 0) {
          return false;
        }

        return true;
      })
      .test('validationSizeFile', 'Tamanho máximo para arquivos é de 2MB', (file) => {
        const listFile = file as FileList;

        if (listFile?.length > 0) {
          return validationSizeFile(listFile);
        }

        return false;
      })
      .test('validationMaxFile', 'Quantidade máxima de arquivos é de 5 arquivos', (file) => {
        const listFile = file as FileList;

        if (listFile?.length > 0) {
          return validateMaxFile(listFile);
        }
      })
      .test('validationTypeFile', 'Formato de arquivo não suportado', (file) => {
        const listFile = file as FileList;

        if (listFile?.length > 0) {
          return validateTypeFile(listFile);
        }
      }),
    declaration_of_request: Yup.mixed()
      .test('Required', 'Campo obrigatório', (file) => {
        const listFile = file as FileList;

        if (listFile?.length == 0) {
          return false;
        }

        return true;
      })
      .test('validationSizeFile', 'Tamanho máximo para arquivos é de 2MB', (file) => {
        const listFile = file as FileList;

        if (listFile?.length > 0) {
          return validationSizeFile(listFile);
        }

        return false;
      })
      .test('validationMaxFile', 'Quantidade máxima de arquivos é de 5 arquivos', (file) => {
        const listFile = file as FileList;

        if (listFile?.length > 0) {
          return validateMaxFile(listFile);
        }
      })
      .test('validationTypeFile', 'Formato de arquivo não suportado', (file) => {
        const listFile = file as FileList;

        if (listFile?.length > 0) {
          return validateTypeFile(listFile);
        }
      }),
    document_with_photo: Yup.mixed()
      .test('Required', 'Campo obrigatório', (file) => {
        const listFile = file as FileList;

        if (listFile?.length == 0) {
          return false;
        }

        return true;
      })
      .test('validationSizeFile', 'Tamanho máximo para arquivos é de 2MB', (file) => {
        const listFile = file as FileList;

        if (listFile?.length > 0) {
          return validationSizeFile(listFile);
        }

        return false;
      })
      .test('validationMaxFile', 'Quantidade máxima de arquivos é de 5 arquivos', (file) => {
        const listFile = file as FileList;

        if (listFile?.length > 0) {
          return validateMaxFile(listFile);
        }
      })
      .test('validationTypeFile', 'Formato de arquivo não suportado', (file) => {
        const listFile = file as FileList;

        if (listFile?.length > 0) {
          return validateTypeFile(listFile);
        }
      }),
  });

  const { register, formState, handleSubmit, reset, setValue, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const updateCandidate = (response: IResponseSocialName) => {
    setCandidate(response.candidate);
  };

  const handleSocialName = (data: FieldValues) => {
    if (!candidate?.social_name_obj?.id) {
      socialName
        .mutateAsync(data)
        .then((response) => {
          toast({
            title: 'Sucesso',
            description: 'Solicitação enviada com sucesso',
            status: 'success',
            duration: 4000,
            isClosable: true,
          });

          reset({
            declaration_of_request: '',
            document_with_photo: '',
            ordering_by_social_name: undefined,
            recent_photo: '',
            restroom_type: undefined,
            social_name: '',
          });
          if (response?.data) {
            updateCandidate(response.data);
          }
          navigate(-1);
        })
        .catch(() => {
          toast({
            title: 'Erro',
            description: 'Erro na solicitação, tente novamente mais tarde',
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        });
    } else if (candidate.social_name_obj.id) {
      updateSocialName
        .mutateAsync({ newData: data, oldData: candidate?.social_name_obj })
        .then((response) => {
          toast({
            title: 'Sucesso',
            description: 'Atualização enviada com sucesso',
            status: 'success',
            duration: 4000,
            isClosable: true,
          });
          if (response?.data) {
            updateCandidate(response.data);
          }
          navigate(-1);
        })
        .catch(() => {
          toast({
            title: 'Erro',
            description: 'Erro na solicitação, tente novamente mais tarde',
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        });
    }
  };

  useEffect(() => {
    if (candidate?.social_name) {
      setValue('social_name', candidate.social_name);
    }
    if (candidate?.social_name_obj?.ordering_by_social_name !== undefined) {
      setValue('ordering_by_social_name', `${candidate?.social_name_obj.ordering_by_social_name}`);
    }
    if (candidate?.social_name_obj?.restroom_type) {
      setValue('restroom_type', candidate?.social_name_obj?.restroom_type || '');
    }

    const files = [];
    if (candidate?.social_name_obj?.document_with_photo) {
      files.push({ url: candidate?.social_name_obj?.document_with_photo || '', file_name: 'cavalo.jpg' });
    }
    if (candidate?.social_name_obj?.declaration_of_request) {
      files.push({ url: candidate?.social_name_obj.declaration_of_request || '', file_name: 'declaracao.pdf' });
    }
    if (candidate?.social_name_obj?.recent_photo) {
      files.push({ url: candidate?.social_name_obj.recent_photo || '', file_name: 'foto_recente.jpg' });
    }
    convertUrlToFile(files).then((listFile) => {
      setValue('document_with_photo', [listFile[0]]);
      setValue('declaration_of_request', [listFile[1]]);
      setValue('recent_photo', [listFile[2]]);
    });
  }, []);

  return (
    <MainCard
      title={NavProps?.previousRoute == '/painel' ? 'ÁREA DO CANDIDATO - ' : 'ATENDIMENTO PELO NOME SOCIAL'}
      subtitle={NavProps?.previousRoute == '/painel' ? 'ATENDIMENTO PELO NOME SOCIAL' : ''}
    >
      <Flex flexDir="column" px={['20px', '20px', '40px', '50px', '50px']}>
        <TextXS mt={10} mb={10}>
          Nome Social é o nome pelo qual as pessoas travestis e transexuais se identificam e querem ser reconhecidas
          socialmente, em consonância com a sua identidade de gênero.
        </TextXS>

        <Stack as="form" onSubmit={handleSubmit(handleSocialName)}>
          <Stack>
            <FormControl
              display="flex"
              flexDir={['column', 'column', 'column', 'row', 'row']}
              alignItems={['none', 'none', 'none', 'center', 'center']}
            >
              <TextXS mr={2} fontWeight="bold" textAlign={['left', 'left', 'right', 'right', 'right']} w="250px">
                Nome Social:
              </TextXS>

              <Input {...register('social_name')} w="100%" maxW="600px" />
              <TextXS color="red">{formState.errors.social_name?.message}</TextXS>
            </FormControl>

            <FormControl
              display="flex"
              flexDir={['column', 'column', 'column', 'row', 'row']}
              alignItems={['none', 'none', 'none', 'center', 'center']}
            >
              <TextXS mr={2} fontWeight="bold" textAlign={['left', 'left', 'right', 'right', 'right']} w="250px">
                Solicito ensalamento conforme Nome Social:
              </TextXS>
              <RadioGroup value={watch('ordering_by_social_name')}>
                <Stack direction={['column', 'column', 'column', 'row', 'row']}>
                  <Radio {...register('ordering_by_social_name')} value="true">
                    Sim
                  </Radio>
                  <Radio {...register('ordering_by_social_name')} value="false">
                    Não
                  </Radio>
                </Stack>
                <TextXS color="red">{formState.errors.ordering_by_social_name?.message}</TextXS>
              </RadioGroup>
            </FormControl>

            <FormControl
              display="flex"
              flexDir={['column', 'column', 'column', 'row', 'row']}
              alignItems={['none', 'none', 'none', 'center', 'center']}
            >
              <TextXS mr={2} fontWeight="bold" textAlign={['left', 'left', 'right', 'right', 'right']} w="250px">
                Indique o ambiente sanitário a ser utilizado nos dias de prova:
              </TextXS>
              <RadioGroup value={watch('restroom_type')}>
                <Stack direction={['column', 'column', 'column', 'row', 'row']}>
                  <Radio {...register('restroom_type')} value="male">
                    Masculino
                  </Radio>
                  <Radio {...register('restroom_type')} value="female">
                    Feminino
                  </Radio>
                </Stack>
                <TextXS color="red">{formState.errors.restroom_type?.message}</TextXS>
              </RadioGroup>
            </FormControl>

            <Stack mt={10}>
              <TextXS>Insira abaixo os arquivos necessários conforme o edital.</TextXS>
              <TextXS>Somente seráo aceitos arquivos nos formatos PDF, PNG, JPG</TextXS>
            </Stack>

            <Flex align="flex-start" rounded={4} flexDir="column" borderRadius="4px" bg="lightGrey" p={5} gap={5}>
              <Stack>
                <FormControl
                  display="flex"
                  flexDir={['column', 'column', 'column', 'row', 'row']}
                  alignItems={'center'}
                >
                  <TextXS w="100%" textAlign={['left', 'left', 'left', 'right', 'right']}>
                    Foto Recente:
                  </TextXS>

                  <Stack w="100%">
                    <Input {...register('recent_photo')} alignContent="center" type="file" w="100%" bg="white" />
                    <TextXS color="red">{formState?.errors?.recent_photo?.message}</TextXS>
                  </Stack>
                  {candidate?.social_name_obj?.recent_photo && (
                    <Link
                      whiteSpace="nowrap"
                      ml={5}
                      color="colorLink"
                      target="_blank"
                      href={candidate?.social_name_obj?.recent_photo}
                    >
                      Visualizar Arquivo
                    </Link>
                  )}
                </FormControl>

                <FormControl
                  flexDir={['column', 'column', 'column', 'row', 'row']}
                  display="flex"
                  alignItems={'center'}
                >
                  <TextXS w="100%" textAlign={['left', 'left', 'left', 'right', 'right']}>
                    Documento de identificação com foto de acordo com o edital:
                  </TextXS>
                  <Stack w="100%">
                    <Input {...register('document_with_photo')} alignContent="center" type="file" w="100%" bg="white" />
                    <TextXS color="red">{formState?.errors?.document_with_photo?.message}</TextXS>
                  </Stack>
                  {candidate?.social_name_obj?.document_with_photo && (
                    <Link
                      whiteSpace="nowrap"
                      ml={5}
                      color="colorLink"
                      target="_blank"
                      href={candidate?.social_name_obj?.document_with_photo}
                    >
                      Visualizar Arquivo
                    </Link>
                  )}
                </FormControl>

                <FormControl
                  flexDir={['column', 'column', 'column', 'row', 'row']}
                  display="flex"
                  alignItems={'center'}
                >
                  <TextXS w="100%" textAlign={['left', 'left', 'left', 'right', 'right']}>
                    Declaração de solicitação de tratamento por nome social assinado:
                  </TextXS>
                  <Stack w="100%">
                    <Input
                      {...register('declaration_of_request')}
                      alignContent="center"
                      type="file"
                      w="100%"
                      bg="white"
                    />
                    <TextXS color="red">{formState?.errors?.declaration_of_request?.message}</TextXS>
                  </Stack>
                  {candidate?.social_name_obj?.declaration_of_request && (
                    <Link
                      whiteSpace="nowrap"
                      ml={5}
                      color="colorLink"
                      target="_blank"
                      href={candidate?.social_name_obj?.declaration_of_request}
                    >
                      Visualizar Arquivo
                    </Link>
                  )}
                </FormControl>
              </Stack>
            </Flex>
          </Stack>

          <Stack mb={10} flexDir="row" justify="flex-end" mt={10}>
            <Button w={'100px'} onClick={() => navigate(-1)}>
              Cancelar
            </Button>
            <Button
              isDisabled={socialName.isPending || updateSocialName.isPending}
              type="submit"
              bg="colorLink"
              color="white"
              w={'100px'}
              _hover={'none'}
            >
              {socialName.isPending || updateSocialName.isPending ? <Spinner /> : 'Enviar'}
            </Button>
          </Stack>
        </Stack>
      </Flex>
    </MainCard>
  );
};

export default SocialName;
