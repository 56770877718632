import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  useToast,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { validateMaxFile, validateTypeFile, validationSizeFile } from '../../../../../utils/validationForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, useForm, UseFormReturn, UseFormSetValue } from 'react-hook-form';
import { useNewExemption } from '../../../../../services/hooks/Exemptions/UseNewExemption';
import MessageError from '../../../../../components/MessageError';
import { IoChevronDownCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

interface IMarrowDonnorProps {
  attachmentExemption?: FileList;
}

interface IOwnProps {
  exemptionId: string;
  registrationId: string;
  onSetValue?: UseFormSetValue<FieldValues>;
  hookForms?: UseFormReturn<IMarrowDonnorProps>;
}

const MarrowDonnor: React.FC<IOwnProps> = ({ exemptionId, registrationId, onSetValue, hookForms }) => {
  const newExemption = useNewExemption();
  const toast = useToast();
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    attachmentExemption: Yup.mixed()
      .test('Required', 'Campo obrigatório', (file) => {
        const listFile = file as FileList;

        if (listFile?.length == 0) {
          return false;
        }

        return true;
      })
      .test('validationSizeFile', 'Tamanho máximo para arquivos é de 2MB', (file) => {
        const listFile = file as FileList;

        if (listFile?.length > 0) {
          return validationSizeFile(listFile);
        }

        return false;
      })
      .test('validationMaxFile', 'Quantidade máxima de arquivos é de 5 arquivos', (file) => {
        const listFile = file as FileList;

        if (listFile?.length > 0) {
          return validateMaxFile(listFile);
        }
      })
      .test('validationTypeFile', 'Formato de arquivo não suportado', (file) => {
        const listFile = file as FileList;

        if (listFile?.length > 0) {
          return validateTypeFile(listFile);
        }
      }),
  });

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const handleGoToCandidateArea = (data: FieldValues) => {
    exemptionSolicitation(data, '/painel/isencoes');
  };

  const handleCreateNewExemption = (data: FieldValues) => {
    exemptionSolicitation(data);
  };

  const exemptionSolicitation = (data: FieldValues, route?: string) => {
    newExemption
      .mutateAsync({ ...data, type: exemptionId, registration: registrationId })
      .then(() => {
        toast({
          title: 'Sucesso',
          description: 'Pedido de isenção enviado com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        setValue('attachmentExemption', '');
        if (route) {
          navigate(route);
        }
      })
      .catch(() => {
        toast({
          title: 'Erro',
          description: 'Erro na solicitação, tente novamente',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      });
  };

  return (
    <Stack w="fit-content">
      <Flex
        as="form"
        // onSubmit={handleSubmit(exemptionSolicitation)}
        rounded={4}
        flexDir="column"
        borderRadius="4px"
        bg="lightGrey"
        p={5}
        gap={5}
      >
        <Stack w="fit-content">
          <FormControl flexDir="row" display="flex">
            <FormLabel textAlign="right" minW="150px">
              Arquivo
            </FormLabel>
            <Input
              multiple
              {...(hookForms ? hookForms.register('attachmentExemption') : register('attachmentExemption'))}
              alignContent="center"
              type="file"
              w="437px"
              bg="white"
            />
          </FormControl>
          <MessageError ml={160}>
            {formState.errors.attachmentExemption?.message ||
              hookForms?.formState?.errors?.attachmentExemption?.message}
          </MessageError>
          {!hookForms && (
            <Stack align="flex-end">
              {!onSetValue && (
                <Menu>
                  <MenuButton
                    isDisabled={newExemption.isPending}
                    bg="primaryColor"
                    color="white"
                    _hover="none"
                    as={Button}
                    rightIcon={<IoChevronDownCircleOutline />}
                  >
                    {newExemption.isPending ? <Spinner /> : 'Enviar'}
                  </MenuButton>
                  <MenuList>
                    <MenuItem as="button" onClick={handleSubmit(handleCreateNewExemption)}>
                      Enviar e permanecer na pagina
                    </MenuItem>
                    <MenuItem as="button" onClick={handleSubmit(handleGoToCandidateArea)}>
                      Enviar e ir para Isenções
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}

              {/* <Button
   type="submit"
   h="fit-content"
   p={2}
   fontSize="14px"
   mt={3}
   color="white"
   bg="primaryColor"
   w={['100%', '100%', 'fit-content', 'fit-content', 'fit-content']}
   whiteSpace="break-spaces"
 >
   ENVIAR PEDIDO DE ISENÇÃO
 </Button> */}
            </Stack>
          )}
        </Stack>
      </Flex>
    </Stack>
  );
};

export default MarrowDonnor;
