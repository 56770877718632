import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';

export interface ISelectiveProcessVancancy {
  id: string;
  name: string;
  has_pcd: boolean;
  has_black_or_brown: boolean;
  type_of_competition: ISelectObject[];
}

export interface ISelectObject {
  id: string;
  name: string;
}

const RequestSelectiveProcessVacancy = async (
  selective_process_id: string,
  is_cultural_house: boolean,
  cultural_house_id: string = '',
  type_of_vacancy: string = '',
) => {
  if (is_cultural_house && (!selective_process_id || !cultural_house_id)) return [];
  const response = await configApi.get<ISelectiveProcessVancancy[]>(`candidate-area/vacancies/`, {
    params: {
      selective_process_id: selective_process_id,
      cultural_house_id: cultural_house_id || undefined,
      is_initial_semester: type_of_vacancy === 'is_initial_semester' ? true : undefined,
      is_level_test: type_of_vacancy === 'is_level_test' ? true : undefined,
    },
  });
  return response.data;
};

export const useRequestSelectiveProcessVacancy = (
  selective_process_id: string,
  is_cultural_house: boolean,
  cultural_house_id?: string,
  type_of_vacancy?: string,
) => {
  return useQuery({
    queryKey: ['selective_process_vacancy', cultural_house_id, type_of_vacancy],
    queryFn: () =>
      RequestSelectiveProcessVacancy(selective_process_id, is_cultural_house, cultural_house_id, type_of_vacancy),
    refetchOnWindowFocus: false,
  });
};
