import { Text, TextProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface IOwnProps extends TextProps {
  children?: ReactNode | ReactNode[];
}

export const TextXXL: React.FC<IOwnProps> = ({ children, ...rest }) => {
  return (
    <Text fontSize={['30px', '30px', '30px', '30px', '30px']} {...rest}>
      {children}
    </Text>
  );
};

export const TextXL: React.FC<IOwnProps> = ({ children, ...rest }) => {
  return (
    <Text fontSize={['25px', '25px', '25px', '25px', '25px']} {...rest}>
      {children}
    </Text>
  );
};

export const TextL: React.FC<IOwnProps> = ({ children, ...rest }) => {
  return (
    <Text fontSize={['22px', '22px', '22px', '22px', '22px']} {...rest}>
      {children}
    </Text>
  );
};

export const TextM: React.FC<IOwnProps> = ({ children, ...rest }) => {
  return (
    <Text fontSize={['18px', '18px', '18px', '18px', '18px']} {...rest}>
      {children}
    </Text>
  );
};

export const TextS: React.FC<IOwnProps> = ({ children, ...rest }) => {
  return (
    <Text fontSize={['16px', '16px', '16px', '16px', '16px']} {...rest}>
      {children}
    </Text>
  );
};

export const TextXS: React.FC<IOwnProps> = ({ children, ...rest }) => {
  return (
    <Text fontSize={['14px', '14px', '14px', '14px', '14px']} {...rest}>
      {children}
    </Text>
  );
};

export const TextXXS: React.FC<IOwnProps> = ({ children, ...rest }) => {
  return (
    <Text fontSize={['12px', '12px', '12px', '12px', '12px']} {...rest}>
      {children}
    </Text>
  );
};
