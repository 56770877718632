import { useMutation } from '@tanstack/react-query';
import { configApi } from '../../configApi';

const sendFile = async (files: FileList, resourceId: string) => {
  const formData = new FormData();

  if (files.length > 0) {
    for (let index = 0; index < files.length; index++) {
      formData.append('resource_files', files[index]);
    }
  }
  const response = await configApi.patch(`resources/update/${resourceId}/`, formData);
  return response;
};

export const useUpdateAttachment = (resourceId: string) => {
  return useMutation({
    mutationFn: (files: FileList) => sendFile(files, resourceId),
  });
};
