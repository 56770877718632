import { useMutation } from '@tanstack/react-query';
import { configApi } from '../../configApi';
import { FieldValues } from 'react-hook-form';

interface IMutationCreateRegistration {
  data: FieldValues;
}

// interface IExemptionPayload {
//   type?: string;
//   nis?: string;
//   document_number?: string;
//   document_issuing_body?: string;
//   document_issuing_body_uf?: string;
//   mother_name?: string;
// }

const CreateExemption = async ({ data }: IMutationCreateRegistration) => {
  const listToCreate = new FormData();
  // const exemptionObject: IExemptionPayload = {};
  const typeExemption = JSON.parse(data.type_exemption);

  if (data?.selective_process_id) {
    listToCreate.append('selective_process', data?.selective_process_id);
  }

  if (typeExemption.name.toLowerCase().includes('doador')) {
    if (data?.attachmentExemption?.length > 0) {
      for (let index = 0; index < data.attachmentExemption.length; index++) {
        listToCreate.append('files', data.attachmentExemption[index]);
      }
    }
  }

  if (data?.type_exemption) {
    listToCreate.append('type', typeExemption.id);
  }

  if (typeExemption.name.toLowerCase().includes('cadúnico')) {
    if (data?.nis) {
      listToCreate.append('nis', data.nis);
    }

    if (data?.mother_name) {
      listToCreate.append('mother_name', data.mother_name);
    }

    if (data?.document_number) {
      listToCreate.append('document_number', data.document_number);
    }

    if (data?.document_issuing_body) {
      listToCreate.append('document_issuing_body', data.document_issuing_body);
    }

    if (data?.document_issuing_body_uf) {
      // exemptionObject.document_issuing_body_uf = data.document_issuing_body_uf;
      listToCreate.append('document_issuing_body_uf', data.document_issuing_body_uf);
    }
  }

  // if (exemptionObject) {
  //   listToCreate.append('exemption', JSON.stringify(exemptionObject));
  // }

  const response = await configApi.post(`exemption/candidate/create/`, listToCreate);

  return response.data;
};

export const useCreateExemption = () => {
  return useMutation({
    mutationKey: ['create_exemption'],
    mutationFn: (payload: IMutationCreateRegistration) => CreateExemption(payload),
  });
};
