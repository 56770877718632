import { FormControl, FormLabel, Select, Stack, Switch } from '@chakra-ui/react';
import MessageError from '../../../MessageError';
import { UseFormReturn } from 'react-hook-form';
import { ICulturalHouse } from '../../../../services/hooks/CandidateArea/useGetDataToRegistration';
import { useRequestSelectiveProcessVacancy } from '../../../../services/hooks/SelectiveProcess/useRequestSelectiveProcessVacancy';

interface IInputValidation {
  vacancy?: string;
  culture_home?: string;
  initial_semester_secondary?: string;
  culture_home_secondary?: string;
  has_second_vacancy?: boolean;
}

interface IOwnProps {
  selectiveProcessId: string;
  culture_houses?: ICulturalHouse[];
  isCultureHouse: boolean;
  hookForms: UseFormReturn<IInputValidation>;
  allow_second_registration: boolean;
}

const VacancyCutureHome: React.FC<IOwnProps> = ({
  selectiveProcessId,
  culture_houses = [],
  isCultureHouse,
  hookForms,
  allow_second_registration,
}) => {
  const vacancy = useRequestSelectiveProcessVacancy(
    selectiveProcessId,
    isCultureHouse,
    hookForms.watch('culture_home'),
  );

  const secondVacancy = useRequestSelectiveProcessVacancy(
    selectiveProcessId,
    isCultureHouse,
    hookForms.watch('culture_home_secondary'),
  );

  // const [secondVacancyList, setSecondVacancyList] = useState(secondVacancy.data);

  // useEffect(() => {
  //   setSecondVacancyList(secondVacancy.data);
  //   filterSecondVacancy();
  // }, []);

  // const filterCultureHouse = (input: string = '') => {
  //   return culture_houses
  //     .filter((ch) => ch.id === input)
  //     .map((item) =>
  //       item?.types?.map((type) => (
  //         <option key={type.id} value={type.id}>
  //           {type.name}
  //         </option>
  //       )),
  //     );
  // };

  const mountCultureHouse = () => {
    return culture_houses?.map((ch) => (
      <option key={ch.id} value={ch.id}>
        {ch.name}
      </option>
    ));
  };

  // const filterSecondVacancy = () => {
  //   const primaryVacancy = hookForms.watch('vacancy');
  //   const cultureHomePrimary = hookForms.watch('culture_home');
  //   const cultureHomeSecondary = hookForms.watch('culture_home_secondary');
  //   // if (cultureHomePrimary !== cultureHomeSecondary) return;

  //   const finalList = secondVacancyList?.filter((value) => value.id !== primaryVacancy);

  //   console.log(finalList);
  //   setSecondVacancyList(finalList);
  // };

  return (
    <Stack>
      <FormControl isRequired>
        <FormLabel>Selecione a casa de cultura desejada:</FormLabel>
        <Select {...hookForms.register('culture_home')} isRequired={false}>
          <option value="" hidden>
            Selecione...
          </option>
          {mountCultureHouse()}
        </Select>
        <MessageError>{hookForms.formState?.errors?.culture_home?.message}</MessageError>
      </FormControl>
      {/* {hookForms.watch('culture_home') && (
        <FormControl isRequired>
          <FormLabel>Tipo de vaga</FormLabel>
          <Select
            {...hookForms.register('vagancy_type', {
              onChange: () => {
                hookForms.setValue('initial_semester', '');
                hookForms.setValue('nivel_test', '');
                hookForms.setValue('has_second_vacancy', undefined);
              },
            })}
            isRequired={false}
          >
            <option value="" hidden>
              Selecione...
            </option>
            {filterCultureHouse(hookForms.getValues('culture_home'))}
          </Select>
          <MessageError>{hookForms.formState?.errors?.vagancy_type?.message}</MessageError>
        </FormControl>
      )} */}

      <>
        <FormControl isRequired>
          <FormLabel>Turma</FormLabel>
          <Select
            value={hookForms.watch('vacancy')}
            isDisabled={vacancy.data?.length === 0}
            {...hookForms.register('vacancy')}
            isRequired={false}
          >
            <option value="" hidden>
              Selecione...
            </option>
            {vacancy.data?.map((semester) => (
              <option key={semester.id} value={semester.id}>
                {semester.name}
              </option>
            ))}
          </Select>

          <MessageError>{hookForms.formState?.errors?.vacancy?.message}</MessageError>
        </FormControl>
        {allow_second_registration && (
          <FormControl my={10} display="flex" alignItems="center">
            <FormLabel htmlFor="second_vacancy" mb="0">
              Você deseja informar uma segunda opção:
            </FormLabel>
            <Switch
              isChecked={hookForms.watch('has_second_vacancy')}
              size="lg"
              colorScheme="green"
              id="second_vacancy"
              {...hookForms.register('has_second_vacancy')}
            />
          </FormControl>
        )}
      </>

      {hookForms.watch('has_second_vacancy') && (
        <>
          <FormControl isRequired>
            <FormLabel>Selecione a casa de cultura:</FormLabel>
            <Select
              value={hookForms.watch('culture_home_secondary')}
              {...hookForms.register('culture_home_secondary')}
              isRequired={false}
            >
              <option value="" hidden>
                Selecione...
              </option>
              {mountCultureHouse()}
            </Select>
            <MessageError>{hookForms.formState?.errors?.culture_home_secondary?.message}</MessageError>
          </FormControl>
          {/* <FormControl isRequired>
            <FormLabel>Tipo de vaga</FormLabel>
            <Select isDisabled>
              <option selected>Semestre Inicial</option>
              {filterCultureHouse(hookForms.getValues('culture_home_secondary'))}
            </Select>
            <MessageError>{hookForms.formState?.errors?.vagancy_type?.message}</MessageError>
          </FormControl> */}
          <FormControl isRequired>
            <>
              <FormLabel>Turma</FormLabel>
              <Select
                isDisabled={secondVacancy.data?.length === 0}
                {...hookForms.register('initial_semester_secondary')}
                isRequired={false}
                value={hookForms.watch('initial_semester_secondary')}
              >
                <option value="" hidden selected>
                  Selecione...
                </option>

                {secondVacancy.data?.map((semester) => (
                  <option key={semester?.id} value={semester?.id}>
                    {semester?.name}
                  </option>
                ))}
              </Select>
            </>

            <MessageError>{hookForms.formState?.errors?.initial_semester_secondary?.message}</MessageError>
          </FormControl>
        </>
      )}
    </Stack>
  );
};

export default VacancyCutureHome;
