import { FormControl, FormLabel, Input, InputGroup, InputProps, InputRightAddon } from '@chakra-ui/react';
import { ReactNode, forwardRef, useState } from 'react';
import { IoEye } from 'react-icons/io5';

interface IOwnProps {
  label: string;
  icon?: ReactNode;
}

type TProps = InputProps & IOwnProps;

export default forwardRef<HTMLInputElement, TProps>(function InputTemplate(props, refs) {
  const [show, setShow] = useState<boolean>(false);
  if (props.type === 'password' && show) {
    props = { ...props, type: 'text', icon: <IoEye size={30} /> };
  }

  return (
    <FormControl isRequired={props.isRequired}>
      <FormLabel m="0">{props.label}</FormLabel>
      <InputGroup size="md">
        <Input
          h={['40px', '40px', '40px', '40px', '40px']}
          p={['14px', '14px', '14px', '14px', '14px']}
          ref={refs}
          {...props}
          isRequired={false}
          bg="white"
        />
        {props.icon && (
          <InputRightAddon
            h={['40px', '40px', '40px', '40px', '40px']}
            p="14px"
            cursor="pointer"
            onClick={() => setShow(!show)}
            bg="white"
          >
            {props.icon}
          </InputRightAddon>
        )}
      </InputGroup>
    </FormControl>
  );
});
