import { Button, ButtonProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface IButtonProps extends ButtonProps {
  label: string | ReactNode;
}

export const DefaultButton: React.FC<IButtonProps> = ({ label, ...rest }) => {
  return (
    <Button
      whiteSpace="break-spaces"
      bg="colorLink"
      color="white"
      w="fit-content"
      minW="100px"
      maxW={'150px'}
      _hover={'none'}
      {...rest}
    >
      {label}
    </Button>
  );
};
