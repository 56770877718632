import { useMutation } from '@tanstack/react-query';
import { configApi } from '../../configApi';
import { ISpecialCondition } from '../CandidateArea/useGetDataToRegistration';
import { FieldValues } from 'react-hook-form';

interface IMutationCreateSpecialCondition {
  data: FieldValues;
  specialCondition?: ISpecialCondition[];
  registrationId: string;
}

const requestUpdateSpecialCondition = async ({
  data,
  registrationId,
  specialCondition = [],
}: IMutationCreateSpecialCondition) => {
  try {
    const formData = new FormData();

    const specialConditions = data.selectedSpecialCondition

      .map((selected: boolean, index: number) => {
        if (selected) {
          if (specialCondition[index].has_attachment) {
            const file = data.attachmentFile[index];
            if (file && file.length > 0) {
              for (let list = 0; list < file.length; list++) {
                formData.append(`attachment_${specialCondition[index].id}`, file[list]);
              }
            }
          }
          return {
            id: specialCondition[index]?.id,
            observations: data.observation[index],
          };
        }
        return false;
      })
      .filter((selected: boolean) => selected);

    formData.append('special_conditions', JSON.stringify(specialConditions));

    await configApi.patch(`candidate-area/special-condition-update/${registrationId}/`, formData);
  } catch {
    console.error('erro');
  }
};

export const useRequestUpdateSpecialCondition = () => {
  return useMutation({
    mutationKey: ['create_specialCondition'],
    mutationFn: (payload: IMutationCreateSpecialCondition) => requestUpdateSpecialCondition(payload),
  });
};
