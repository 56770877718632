import { Button, Checkbox, Flex, Stack } from '@chakra-ui/react';
import MainCard from '../../../../components/MainCard/Index';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext } from 'react';
import { ContextAuth } from '../../../../contexts/Authentication';
import MessageError from '../../../../components/MessageError';
import { TextL, TextXS } from '../../../../components/Text/Index';
import { ETypeExemption } from '../../../../services/hooks/SelectiveProcess/Types';
import { useRequestDetailSelectiveProcess } from '../../../../services/hooks/SelectiveProcess/useRequestDetailSelectiveProcess';

// interface INavigationProps {
//   selectiveProcessType: string;
//   selectiveProcessName: string;
//   selectiveProcessPeriod: string;
//   terms: string;
//   selectiveProcessId: string;
//   isExemption?: boolean;
//   exemption_registration_creation_type?: string;
// }

const SelectiveProcessNotice: React.FC = () => {
  const [searchParams] = useSearchParams();
  const selectiveProcessId = searchParams?.get('selective_process_id');
  const isExemption: boolean = searchParams?.get('isExemption') == 'true';
  const navigate = useNavigate();
  const { isAuth } = useContext(ContextAuth);
  const { data } = useRequestDetailSelectiveProcess(selectiveProcessId || '');

  const schema = Yup.object().shape({
    terms: Yup.boolean().test('isRequired', 'É necessario aceitar os termos de uso para continuar', (accept) => {
      return accept;
    }),
  });

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const acceptTerms = () => {
    if (isAuth && isExemption) {
      if (data?.general_info?.exemption_registration_creation_type === ETypeExemption?.standby) {
        navigate('/processo-seletivo/isencao', { state: data.id });
        return;
      } else {
        navigate('/processo-seletivo/inscricao/isencao', { state: data?.id || '' });
        return;
      }
    }

    if (!isAuth && isExemption) {
      navigate('/processo-seletivo/validar-cpf', {
        state: {
          selectiveProcessId: data?.id,
          exemption: true,
          exemption_registration_creation_type: data?.general_info.exemption_registration_creation_type,
        },
      });
      return;
    }

    if (isAuth)
      navigate(`/processo-seletivo/${data?.id || ''}/inscricao`, {
        state: { selectiveProcessId: data?.id || '' },
      });
    else navigate(`/processo-seletivo/${data?.id || ''}/validar-cpf`);
  };

  // if (!selectiveProcessData) navigate(-1);

  return (
    <MainCard title="TERMOS E CONDIÇÕES">
      <Flex flexDir="column" px={['10px', '10px', '10px', '50px', '50px']}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexDir={['column', 'column', 'column', 'row', 'row']}
          overflow="hidden"
        >
          <Stack color="darkGrey">
            <TextL fontWeight="medium">{data?.selective_process_type}</TextL>
            <TextXS>
              <strong>{data?.name}</strong>
            </TextXS>
            <TextXS>
              <strong>Inscrições: </strong>
              {data?.registration_period}
            </TextXS>
          </Stack>
        </Flex>
        <Stack as="form" onSubmit={handleSubmit(acceptTerms)}>
          <Stack p={5} w="100%" h="fit-content" bg="lightGrey">
            <TextXS
              maxH="250px"
              overflow="auto"
              dangerouslySetInnerHTML={{
                __html: data?.terms_of_use || '',
              }}
            />
          </Stack>
          <MessageError>{formState?.errors.terms?.message}</MessageError>
          <Checkbox {...register('terms')}>Declaro ter lido e concordado com os termos do edital.</Checkbox>

          <Stack flexDir="row" justifyContent="flex-end">
            <Button color="white" bg="primaryColor" w="100px" type="submit" _hover={{ bg: '#298bdb' }}>
              Continuar
            </Button>
            <Button
              w="100px"
              onClick={() => {
                navigate(-1);
              }}
            >
              Voltar
            </Button>
          </Stack>
        </Stack>
      </Flex>
    </MainCard>
  );
};

export default SelectiveProcessNotice;
