import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';
import { IDateType } from '../Types';

interface IDefaultProps {
  title: string;
  candidate: string;
  registration_code: number;
  selective_process_vacancy: string;
  type_of_competition: string;
  special_conditions: ISpecialCondition[];
}
interface ISpecialCondition {
  id: string;
  name: string;
  checked: boolean;
  observations: string;
  attachments: IAttachmentsFile[];
  has_attachment: boolean;
}

export interface IAttachmentsFile {
  id: string;
  name: string;
  url: string;
  status: string;
  created_at: IDateType;
  sent_by_client: boolean;
}

const RequestGetSpecialCondition = async (registrationId: string) => {
  const response = await configApi.get<IDefaultProps>(`candidate-area/special-condition/${registrationId}/`);
  return response.data;
};

export const useRequestGetSpecialCondition = (registrationId: string) => {
  return useQuery({
    queryKey: ['getDataToSpecialCondition', registrationId],
    queryFn: () => RequestGetSpecialCondition(registrationId),
    refetchOnWindowFocus: false,
  });
};
