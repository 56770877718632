import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';

interface ISelectiveProcessResource {
  selective_process_id: string;
  selective_process_name: string;
  vacancies: IVacanciesProps[];
  is_cultural_house: boolean;
}

export interface ISimpleResource {
  resource_id: string;
  resource_type: IResourceType;
  period: string;
  period_identification: string;
  resource_is_open: boolean;
  is_against_question: boolean;
  show_resource_against_question: boolean;
}

interface IResourceType {
  id: string;
  name: string;
}

interface IVacanciesProps {
  vacancy_id: string;
  vacancy_name: string;
  resources: ISimpleResource[];
}

const RequestResources = async () => {
  const response = await configApi.get<ISelectiveProcessResource[]>('candidate-area/open-resources/');

  return response.data;
};

export const useRequestResources = () => {
  return useQuery({
    queryKey: ['simple_resources'],
    queryFn: RequestResources,
    refetchOnWindowFocus: false,
    gcTime: 0,
  });
};
