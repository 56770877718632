import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';

interface IImpugnation {
  id: string;
  protocol: string;
  candidate_id: string;
  selective_process_id: string;
  selective_process_name: string;
  status: string;
  no_reply: boolean;
}

const RequestMyImpugnation = async () => {
  const response = await configApi.get<IImpugnation[]>('candidate-area/candidate-impugnations/');
  return response.data;
};

export const useRequestMyImpugnation = () => {
  return useQuery({
    queryKey: ['my_impugnation'],
    queryFn: RequestMyImpugnation,
    refetchOnWindowFocus: false,
  });
};
