import MainCard from '../../../../components/MainCard/Index';
import ComponentSpecialCondition from './componentSpecialCondition';

const SpecialCondition: React.FC = () => {
  return (
    <MainCard title="ÁREA DO CANDIDATO - " subtitle="CONDIÇÕES ESPECIAIS PARA PROVA">
      <ComponentSpecialCondition />
    </MainCard>
  );
};

export default SpecialCondition;
