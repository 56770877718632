import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, useMediaQuery } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';

import NoAuthProfileMobile from './NoAuthProfileMobile';
import { useNavigate } from 'react-router-dom';
import { ContextAuth } from '../../contexts/Authentication';
import { TextXS } from '../Text/Index';

interface IOwnProps {
  onClose(): void;
  isOpen: boolean;
}

const MenuMobile: React.FC<IOwnProps> = ({ onClose, isOpen }) => {
  const [isMobile] = useMediaQuery('(max-width: 1024px)');
  const navigate = useNavigate();
  const { signOut, isAuth } = useContext(ContextAuth);

  useEffect(() => {
    if (isMobile === false) {
      onClose();
    }
  }, [isMobile]);
  return (
    <>
      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <NoAuthProfileMobile />
          </DrawerHeader>
          {isAuth && (
            <DrawerBody
              py={10}
              display="flex"
              flexDir="column"
              fontWeight="bold"
              color="darkGrey"
              fontSize="18px"
              gap={5}
            >
              <TextXS
                cursor="pointer"
                onClick={() => {
                  navigate('/painel');
                }}
              >
                Área do candidato
              </TextXS>
              <TextXS
                cursor="pointer"
                onClick={() => {
                  navigate('/');
                }}
              >
                Processos seletivos
              </TextXS>
              <TextXS
                cursor="pointer"
                onClick={() => {
                  navigate('/painel/isencoes');
                }}
              >
                Isenções
              </TextXS>
              <TextXS
                cursor="pointer"
                onClick={() => {
                  navigate('/painel/recursos');
                }}
              >
                Recursos
              </TextXS>
              <TextXS
                cursor="pointer"
                onClick={() => {
                  navigate('/painel/meus-dados');
                }}
              >
                Meus dados
              </TextXS>
              <TextXS
                cursor="pointer"
                onClick={() => {
                  navigate('/painel/alterar-senha');
                }}
              >
                Alterar senha
              </TextXS>

              <TextXS
                cursor="pointer"
                onClick={() => {
                  signOut();
                  navigate('/');
                }}
              >
                Sair
              </TextXS>
            </DrawerBody>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MenuMobile;
