import {
  Badge,
  Box,
  Flex,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Td,
  Tr,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import TableCustom from '../../../../components/pages/CandidateArea/Table';
import { RiSignalWifiErrorLine } from 'react-icons/ri';
import { useRequestMyExemption } from '../../../../services/hooks/CandidateArea/useRequestMyExemption';
import { TextM, TextXS, TextXXS } from '../../../../components/Text/Index';
import { badgeColor } from '../../../../utils/badgeFunction';
import { useState } from 'react';
import { ILocation } from '../../../../contexts/types';
import { ISelectObject } from '../../../../services/hooks/SelectiveProcess/useRequestSelectiveProcessVacancy';

export interface IExemption {
  id: string;
  registration_code: number;
  selective_process_id: string;
  selective_process_name: string;
  status: string;
  type: string;
  detail: string;
  attachments: Attachment[];
}

export interface IExemptionFCPC {
  id: string;
  type: ISelectObject;
  justification: string;
  justification_date: string;
  status: string;
  nis: string;
  mother_name: string;
  document_number: string;
  document_issuing_body: string;
  document_issuing_body_uf: ILocation;
}

export interface Attachment {
  id: string;
  name: string;
  type: string;
  status: string;
  file: string;
  sent_by_client: boolean;
}

const Exemptions: React.FC = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const headerExemption = ['INSCRIÇÃO', 'PROCESSOS SELETIVOS', 'SITUAÇÃO', 'TIPO DE SOLICITAÇÃO', 'AÇÕES'];
  const { data, isLoading, error } = useRequestMyExemption();
  const [selectedExemption, setSelectedExemption] = useState<IExemption>();

  // const handleDelete = async (ExemptionId: string, selectiveProcessId: string) => {
  //   const response = await configApi.delete(
  //     `exemption/candidate/delete/${ExemptionId}/?selective_process_id=${selectiveProcessId}`,
  //   );
  //   return response.data;
  // };

  const handleDetail = (reserved: IExemption) => {
    setSelectedExemption(reserved);
    onOpen();
  };

  return (
    <>
      <Stack height="100%">
        {(isLoading && (
          <Stack height="100%" justify="center" align="center">
            <Spinner size="lg" />
          </Stack>
        )) ||
          (error && (
            <Stack height="100%" color="red" justify="center" align="center">
              <RiSignalWifiErrorLine size={50} />
              <TextXS fontWeight="bold" fontSize="16px">
                Erro ao trazer os dados!
              </TextXS>
            </Stack>
          )) || (
            <>
              <Stack px={['10px', '10px', '10px', '50px', '50px']}>
                {data ? (
                  <TableCustom headers={headerExemption}>
                    {data &&
                      data?.map((exemption) => (
                        <Tr key={exemption.id}>
                          <Td textAlign="center">
                            <TextXS onClick={() => handleDetail(exemption)} color="colorLink" cursor="pointer">
                              {exemption.registration_code}
                            </TextXS>
                          </Td>
                          <Td textAlign="center">
                            <TextXS>{exemption.selective_process_name}</TextXS>
                          </Td>
                          <Td textAlign="center">
                            <Badge
                              borderRadius={4}
                              textAlign="center"
                              lineHeight="normal"
                              p={1}
                              px={2}
                              bg={badgeColor(exemption?.status)?.bgColor || 'black'}
                              color={badgeColor(exemption?.status)?.color || 'white'}
                            >
                              {exemption?.status}
                            </Badge>
                          </Td>
                          <Td textAlign="center">{exemption.type}</Td>
                          <Td textAlign="center">
                            <TextXS onClick={() => handleDetail(exemption)} color="colorLink" cursor="pointer" ml={2}>
                              Detalhes
                            </TextXS>
                          </Td>
                        </Tr>
                      ))}
                  </TableCustom>
                ) : (
                  <Stack h="100%" justify="center" align="center">
                    <TextM>Sem pedidos de isenção registrados</TextM>
                  </Stack>
                )}
              </Stack>
            </>
          )}
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent pb={10} maxW={['80%', '80%', '80%', '40%', '40%']} maxH="none">
          <ModalHeader textAlign="center">Detalhes do pedido de Isenção</ModalHeader>
          <ModalCloseButton />

          {selectedExemption && (
            <ModalBody display="flex" flexDir="column" gap={1}>
              <TextXS>
                <strong>Processo Seletivo: </strong> {selectedExemption?.selective_process_name}
              </TextXS>

              <TextXS>
                <strong>Inscrição: </strong> {selectedExemption?.registration_code}
              </TextXS>

              <TextXS>
                <strong>Tipo: </strong> {selectedExemption?.type}
              </TextXS>

              <TextXS>
                <strong>Situação do pedido: </strong> {selectedExemption?.status}
              </TextXS>

              {selectedExemption?.detail && (
                <Flex gap={1}>
                  <TextXS>
                    <strong>Resposta da solicitação: </strong>
                  </TextXS>
                  <TextXS dangerouslySetInnerHTML={{ __html: selectedExemption?.detail }} />
                </Flex>
              )}

              {selectedExemption.attachments.length > 0 && (
                <Box>
                  <TextXS fontWeight="bold">Anexos enviados: </TextXS>
                  <UnorderedList ml={'25px'} styleType={''}>
                    {selectedExemption.attachments.map((attach, index) => (
                      <ListItem key={index}>
                        <Flex gap={1}>
                          <Link color="colorLink" href={attach?.file} target="_blank">
                            <TextXXS>{attach?.name}</TextXXS>
                          </Link>
                          <TextXXS color="#4e4e4e">
                            {attach?.sent_by_client ? '(Enviado pelo Administrador)' : '(Enviado pelo candidato)'}
                          </TextXXS>
                        </Flex>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              )}
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default Exemptions;
