import { Box, Button, Stack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { TextL } from '../../Text/Index';

interface MyRegistrationsProps {
  mainTitle: string;
  buttonTitle?: string;
  iconTitle?: ReactNode;
  bgColor?: string;
  textColor?: string;
  fontWeight?:string;
}

const HeaderTable: React.FC<MyRegistrationsProps> = ({ mainTitle, buttonTitle, iconTitle, bgColor, textColor, fontWeight }) => {
  return (
    <>
      <Stack flexDir="row" align="center" p={0} m={0}>
        <Box>{iconTitle}</Box>
        <TextL fontWeight={fontWeight || 'normal'}>{mainTitle}</TextL>
      </Stack>
      {buttonTitle ? (
        <Button
          bg={bgColor || 'white'}
          whiteSpace={'break-spaces'}
          w={['30px', 'auto', 'auto', 'auto', 'auto']}
          boxShadow={'0px 2px 5px 0px rgba(0,0,0,0.2)'}
          color={textColor || 'darkGrey'}        
        >
          {buttonTitle}
        </Button>
      ) : (
        <></>
      )}
    </>
  );
};

export default HeaderTable;
