import { Badge, Stack, Td, Tr } from '@chakra-ui/react';
import HeaderTable from '../../../../components/pages/CandidateArea/TableTest';
import TableCustom from '../../../../components/pages/CandidateArea/Table';
import { useRequestMyRegistration } from '../../../../services/hooks/CandidateArea/useRequestMyRegistration';
import ActionList from './ActionList';
import { TextS, TextXS } from '../../../../components/Text/Index';
import { useNavigate } from 'react-router-dom';
import { badgeColor } from '../../../../utils/badgeFunction';

const HistoryRegistration: React.FC = () => {
  const { data } = useRequestMyRegistration();
  const headerMyRegistrationCultureHouse = ['INSCRIÇÃO', 'VAGA/CARGO', 'DATA DE INSCRIÇÃO', 'SITUAÇÃO', 'AÇÕES'];
  const headerMyRegistration = ['INSCRIÇÃO', 'TURMA', 'DATA DE INSCRIÇÃO', 'SITUAÇÃO', 'AÇÕES'];
  const navigate = useNavigate();

  if (!data) {
    return (
      <Stack pb={'20px'} px={['10px', '10px', '10px', '50px', '50px']} flexDir="column">
        <HeaderTable bgColor="primaryColor" mainTitle="LISTAGEM DE INSCRIÇÕES" fontWeight="bold" />
        <TextS>Você ainda não possui inscrição em um processo seletivo</TextS>
      </Stack>
    );
  }

  return (
    <Stack pb={'20px'} px={['10px', '10px', '10px', '50px', '50px']} flexDir="column">
      <HeaderTable bgColor="primaryColor" mainTitle="LISTAGEM DE INSCRIÇÕES" fontWeight="bold" />

      {data &&
        data?.map((constest, index) => {
          return (
            <TableCustom
              key={index}
              title={constest.selective_process_name}
              headers={constest.is_cultural_house ? headerMyRegistration : headerMyRegistrationCultureHouse}
            >
              {constest?.registrations?.map((registration) => (
                <Tr key={registration.registration_id}>
                  <Td textAlign="center">
                    <TextXS
                      cursor="pointer"
                      color="colorLink"
                      onClick={() => navigate('/painel/inscricao/', { state: registration.registration_id })}
                    >
                      {registration.registration_code}
                    </TextXS>{' '}
                  </Td>
                  {constest?.is_cultural_house ? (
                    <Td whiteSpace="break-spaces" w="100%" textAlign="left">
                      <TextXS>
                        <strong>{!registration?.second_vacancy ? 'Turma: ' : 'Turma - Primeira opção:'} </strong>
                      </TextXS>
                      <TextXS>{registration.vacancy_name}</TextXS>

                      {registration?.second_vacancy && (
                        <>
                          <TextXS mt={2}>
                            <strong>Turma - Segunda opção: </strong>
                          </TextXS>
                          <TextXS>{registration?.second_vacancy}</TextXS>
                        </>
                      )}
                    </Td>
                  ) : (
                    <Td whiteSpace="break-spaces" w="100%" textAlign="center">
                      <TextXS>{registration.vacancy_name}</TextXS>
                    </Td>
                  )}

                  <Td textAlign="center">
                    {' '}
                    <TextXS>{registration.registration_date}</TextXS>
                  </Td>
                  <Td textAlign="center">
                    {' '}
                    <Badge
                      borderRadius={4}
                      textAlign="center"
                      lineHeight="normal"
                      p={1}
                      px={2}
                      bg={badgeColor(registration?.status)?.bgColor || 'black'}
                      color={badgeColor(registration?.status)?.color || 'white'}
                    >
                      {registration?.status}
                    </Badge>
                  </Td>
                  <Td>
                    <ActionList
                      registrationId={registration.registration_id}
                      actions={registration.actions}
                      selectiveProcessId={constest.selective_process_id}
                    />
                  </Td>
                </Tr>
              ))}
            </TableCustom>
          );
        })}
    </Stack>
  );
};

export default HistoryRegistration;
