import { createContext, useEffect, useState } from 'react';
import { configApi } from '../services/configApi';
import { ICandidateData } from './types';
import { useToast } from '@chakra-ui/react';

interface IAuth {
  signIn(email: string, password: string): Promise<void>;
  signOut(): void;
  setCandidate: React.Dispatch<React.SetStateAction<ICandidateData | undefined>>;
  isAuth?: boolean;
  candidate?: ICandidateData;
  loadingCandidate: boolean;
  refetchCandidate(): void;
}

export const ContextAuth = createContext({} as IAuth);

interface IOwnProps {
  children: React.ReactNode;
}

const ProviderAuth: React.FC<IOwnProps> = ({ children }) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [candidate, setCandidate] = useState<ICandidateData>();
  const [loadingCandidate, setLoadingCandidate] = useState<boolean>(false);
  const toast = useToast();

  const getDataUser = () => {
    setLoadingCandidate(true);
    configApi
      .get('candidate/my-data/')
      .then((candidateData) => {
        setCandidate(candidateData.data);
      })
      .catch(() => {
        signOut();
      })
      .finally(() => {
        setIsAuth(true);
        setLoadingCandidate(false);
      });
  };

  useEffect(() => {
    let canUseLocalStorage = false;

    try {
      canUseLocalStorage = !!window.localStorage;
    } catch (e) {
      toast({
        title: 'Atenção',
        description: 'Permissões invalidas',
        status: 'warning',
        duration: 7000,
        isClosable: true,
      });
    }

    if (canUseLocalStorage) {
      const token = localStorage.getItem('token');

      if (token) {
        localStorage.setItem('token', token);
        configApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        getDataUser();
      } else {
        setIsAuth(false);
      }
    }
  }, []);

  const signIn = async (email: string, password: string) => {
    await configApi
      .post('auth/login/', {
        email: email.toLowerCase().trim(),
        password: password,
      })
      .then((response) => {
        let canUseLocalStorage = false;

        try {
          canUseLocalStorage = !!window.localStorage;
        } catch (e) {
          toast({
            title: 'Atenção',
            description: 'Permissões invalidas',
            status: 'warning',
            duration: 7000,
            isClosable: true,
          });
        }

        if (canUseLocalStorage && response?.data) {
          localStorage.setItem('token', response?.data?.access);
          configApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage?.getItem('token')}`;
          getDataUser();
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  const signOut = () => {
    let canUseLocalStorage = false;

    try {
      canUseLocalStorage = !!window.localStorage;
    } catch (e) {
      toast({
        title: 'Atenção',
        description: 'Permissões invalidas',
        status: 'warning',
        duration: 7000,
        isClosable: true,
      });
    }

    if (canUseLocalStorage) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      configApi.defaults.headers.common['Authorization'] = '';
      setIsAuth(false);
    }
  };

  return (
    <ContextAuth.Provider
      value={{ isAuth, signIn, signOut, setCandidate, loadingCandidate, candidate, refetchCandidate: getDataUser }}
    >
      {children}
    </ContextAuth.Provider>
  );
};

export default ProviderAuth;
