import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';

const InvoiceGenerator = async (registrationId: string) => {
  const response = await configApi.get(`/invoice/generate-invoice/${registrationId}/`);
  return response.data;
};

export const useInvoiceGenerator = (registrationId: string) => {
  return useQuery({
    queryKey: ['invoice_generator'],
    queryFn: () => InvoiceGenerator(registrationId),
    enabled: false,
    gcTime: 0,
    retry: 0,
  });
};
