import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  ListItem,
  Spinner,
  Stack,
  UnorderedList,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateMaxFile, validateTypeFile, validationSizeFile } from '../../../utils/validationForm';
import { useRequestMoreDetailResources } from '../../../services/hooks/Resources/useRequestMoreDetailResources';
import { useUpdateAttachment } from '../../../services/hooks/Resources/useUpdateAttachment';
import MainCard from '../../../components/MainCard/Index';
import MessageError from '../../../components/MessageError';
import { TextL, TextXS, TextXXS } from '../../../components/Text/Index';

const DetailResource: React.FC = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toast = useToast();

  const resourceId = useLocation().state as string;

  const { data, refetch } = useRequestMoreDetailResources(resourceId);

  const schema = Yup.object().shape({
    attachmentFile: Yup.mixed()
      .test('validationSizeFile', 'Tamanho máximo para arquivos é de 2MB', (file) => {
        const listFile = file as FileList;

        if (listFile.length > 0) {
          return validationSizeFile(listFile);
        }

        return false;
      })
      .test('validationMaxFile', 'Quantidade máxima de arquivos é de 5 arquivos', (file) => {
        const listFile = file as FileList;

        if (listFile.length > 0) {
          return validateMaxFile(listFile);
        }
      })
      .test('validationTypeFile', 'Formato de arquivo não suportado', (file) => {
        const listFile = file as FileList;

        if (listFile.length > 0) {
          return validateTypeFile(listFile);
        }
      }),
  });

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const clear = () => {
    reset({ attachmentFile: '' });
  };

  const { mutateAsync, isPending } = useUpdateAttachment(resourceId);

  const sendFile = (payload: FieldValues) => {
    if (data?.attachments.resource_file && data.attachments.resource_file.length > 5) {
      toast({
        title: 'Erro',
        description: 'Limite de anexos excedido',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    mutateAsync(payload.attachmentFile)
      .then(() => {
        refetch();
        toast({
          title: 'Sucesso',
          description: 'Anexo enviado com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        clear();
      })
      .catch(() => {
        toast({
          title: 'Erro',
          description: 'Erro ao enviar o anexo, tente novamente',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    if (!resourceId) navigate(-1);
  }, []);
  return (
    <MainCard height="100%" title="ÁREA DO CANDIDATO" subtitle={`- RECURSO ${data?.resource_code || ''}`}>
      <Stack px={['10px', '10px', '10px', '50px', '50px']} gap={5}>
        <Stack>
          <TextL fontWeight="semibold">Detalhes da solicitação de recurso</TextL>
          <TextXS>
            <strong>Número da solicitação:</strong> {data?.resource_code}
          </TextXS>
          <TextXS>
            <strong>Processo seletivo:</strong> {data?.selective_process?.name}
          </TextXS>

          <TextXS>
            <strong>Data de solicitação do recurso:</strong> {data?.created_at?.br || ''}
          </TextXS>
          {data?.question && (
            <TextXS>
              <strong>Questão:</strong> {data?.question || ''}
            </TextXS>
          )}

          <TextXS>
            <strong>Justificativa do candidato:</strong>
            <br /> {data?.observation}
          </TextXS>
          {data?.attachments?.resource_file && (
            <Stack m={0} p={0}>
              <TextXS>
                <strong> Documentos anexados pelo candidato: </strong>
              </TextXS>
              {!data?.attachments?.resource_file && <TextXS>Sem Anexos</TextXS>}
              <UnorderedList>
                {data?.attachments?.resource_file?.map((attach, index) => (
                  <ListItem key={index}>
                    <Link target="_blank" href={attach.url}>
                      <TextXS color="colorLink">
                        {attach.name}{' '}
                        <TextXXS color="black" as="span">
                          {attach.created_at?.br || ''} (enviado pelo{' '}
                          {attach.sent_by_client ? 'administrador' : 'candidato'})
                        </TextXXS>
                      </TextXS>
                    </Link>
                  </ListItem>
                ))}
              </UnorderedList>
            </Stack>
          )}
        </Stack>

        <Stack>
          {/* <Button
            color="darkGrey"
            whiteSpace="break-spaces"
            w="fit-content"
            bg="white"
            border="1px solid darkGrey"
            boxShadow={'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;'}
            mt={4}
            onClick={() => setIsOpen((e) => !e)}
            isDisabled={isPending}
          >
            Enviar Arquivo
          </Button> */}
          {isOpen && (
            <Stack as="form" onSubmit={handleSubmit(sendFile)}>
              <Stack borderRadius="4px" bg="lightGrey" justify="center" p={5}>
                <TextXXS mb={3}>Aqui virá as instruções para envio dos anexos</TextXXS>
                <FormControl isRequired display="flex" alignItems="center">
                  <FormLabel>Arquivo</FormLabel>
                  <Input multiple {...register('attachmentFile')} bg="white" type="file" alignContent="center" />
                </FormControl>
                {formState.errors.attachmentFile && (
                  <MessageError ml={20}>{formState.errors.attachmentFile?.message}</MessageError>
                )}
                <Stack flexDir="row" alignItems="center" justify="flex-start" ml={20}>
                  <Button
                    w="80px"
                    isDisabled={isPending}
                    color="darkGrey"
                    type="submit"
                    bg="white"
                    fontSize={'14px'}
                    h="30px"
                    p={2}
                  >
                    {isPending ? <Spinner /> : 'Enviar'}
                  </Button>
                  <TextXXS
                    cursor="pointer"
                    onClick={() => {
                      clear();
                      setIsOpen((e) => !e);
                    }}
                    color="colorLink"
                    ml={'0'}
                  >
                    Cancelar
                  </TextXXS>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>

        <Stack>
          <TextL fontWeight="bold" mb={2}>
            Resposta da análise do recurso
          </TextL>
          <TextXS>
            <strong>Situação: </strong>
            {data?.status.name}
          </TextXS>
          {data?.justification && (
            <Flex>
              <TextXS fontWeight="bold">Justificativa da análise: </TextXS>
              <TextXS ml={1} dangerouslySetInnerHTML={{ __html: data?.justification }} />
            </Flex>
          )}
        </Stack>

        <Stack mb={10} align={'flex-end'}>
          <Button
            color="white"
            bg="colorLink"
            border="1px solid darkGrey"
            boxShadow={'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 100, 0.08) 0px 0px 0px 1px;'}
            w="fit-content"
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </Stack>
      </Stack>
    </MainCard>
  );
};
export default DetailResource;
