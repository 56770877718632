import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';
import { IDetailSelectiveProcess } from './Types';

const RequestDetailSelectiveProcess = async (idSelectiveProcess: string) => {
  const response = await configApi.get<IDetailSelectiveProcess>(
    `candidate-area/selective-process/details/${idSelectiveProcess}/`,
  );
  return response.data;
};

export const useRequestDetailSelectiveProcess = (idSelectiveProcess: string) => {
  return useQuery({
    queryKey: ['detail_selective_process', idSelectiveProcess],
    queryFn: () => RequestDetailSelectiveProcess(idSelectiveProcess),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
