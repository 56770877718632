export const badgeColor = (situation: string) => {
  let colorScheme;

  switch (situation.toLocaleLowerCase()) {
    case 'aberto para recurso':
      colorScheme = {
        color: '#2F4D9B',
        bgColor: '#c5cfeb',
      };
      break;
    case 'em andamento':
      colorScheme = {
        color: '#686868',
        bgColor: '#e9e9e9',
      };
      break;
    case 'aberto para impugnação de edital':
      colorScheme = {
        color: '#775007',
        bgColor: '#d8d0c3',
      };
      break;
    case 'inscrições abertas':
      colorScheme = {
        color: '#2ca500',
        bgColor: '#d2ebca',
      };
      break;
    case 'período de isenção':
      colorScheme = {
        color: '#671111',
        bgColor: '#dfc7c7',
      };
      break;
    case 'homologado':
      colorScheme = {
        color: '#2E018F',
        bgColor: '#c4b8df',
      };
      break;
    case 'finalizado':
      colorScheme = {
        color: '#1D581C',
        bgColor: '#b4c0b4',
      };
      break;
    case 'suspenso':
      colorScheme = {
        color: '#000000',
        bgColor: '#a8a8a8',
      };
      break;
    case 'reprovado':
    case 'reprovada':
    case 'cancelado':
      colorScheme = {
        color: '#FF0000',
        bgColor: '#ecc0c0',
      };
      break;
    case 'aprovado':
    case 'classificado':
    case 'deferido':
      colorScheme = {
        color: '#317c11',
        bgColor: '#c5ebb3',
      };
      break;
    case 'indeferido':
      colorScheme = {
        color: '#ff0000',
        bgColor: '#ecbaba',
      };
      break;
    case 'em análise':
      colorScheme = {
        color: '#6b6b6b',
        bgColor: '#d6d6d6',
      };
      break;
    case 'inscrição confirmada':
      colorScheme = {
        color: '#317c11',
        bgColor: '#c5ebb3',
      };
      break;
    case 'aguardando pagamento':
      colorScheme = {
        color: '#6b6b6b',
        bgColor: '#d6d6d6',
      };
      break;
    case 'inscrição indeferida':
      colorScheme = {
        color: '#ff0000',
        bgColor: '#ecbaba',
      };
      break;
    case 'pedido de isenção em análise':
      colorScheme = {
        color: '#6b6b6b',
        bgColor: '#d6d6d6',
      };
      break;
  }

  return colorScheme;
};
