import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const validateCEP = async (cep?: string) => {
  const response = await axios.get(`https://viacep.com.br/ws/${cep}/json`);

  return response.data;
};

export const useValidateCEP = () => {
  return useMutation({
    mutationFn: (cep?: string) => validateCEP(cep),
    mutationKey: ['validateCEP'],
  });
};
