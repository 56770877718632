import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';
import { ISelectObject } from '../SelectiveProcess/useRequestSelectiveProcessVacancy';

interface INotes {
  name: string;
  value: string;
}

interface IPreliminaryResult {
  classification: string;
  status: ISelectObject;
  notes: INotes[];
}

const preliminaryResult = async (selective_process_id: string) => {
  const response = await configApi.get<IPreliminaryResult>(`candidate-area/result/${selective_process_id}/`);
  return response.data;
};

export const usePreliminaryResult = (selective_process_id: string) => {
  return useQuery({
    queryKey: ['preliminary_result', selective_process_id],
    queryFn: () => preliminaryResult(selective_process_id),
    refetchOnWindowFocus: false,
  });
};
