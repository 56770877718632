import { useMutation } from '@tanstack/react-query';
import { configApi } from '../../configApi';

interface IUpdatePicture {
  avatar?: File;
  candidateId?: string;
}

const UpdateProfilePicture = async (data?: IUpdatePicture) => {
  const formData = new FormData();

  if (data?.avatar) {
    formData.append('avatar', data.avatar);
  }

  const response = await configApi.patch(`candidate/update/${data?.candidateId}/`, formData);
  return response;
};
export const useUpdateProfilePicture = () => {
  return useMutation({
    mutationFn: (data?: IUpdatePicture) => UpdateProfilePicture(data),
    mutationKey: ['updatePicture'],
  });
};
