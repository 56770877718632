import { useQuery } from "@tanstack/react-query";
import { configApi } from "../../configApi"

interface IMaritalStatus{
    id: string;
    name: string;
}


const RequestGetMaritalStatus = async ()=>{
    const response = await configApi.get<IMaritalStatus[]>('general-records/marital-status/')
    return response.data
}


export const useRequestGetMaritalStatus = () =>{
    return useQuery({
        queryKey: ['get_marital_status'],
        queryFn: RequestGetMaritalStatus,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    })
}