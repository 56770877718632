import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';

interface ISelectiveProcessDetailResource {
  resource_admin_id: string;
  resource_manager_id: string;
  selective_process_name: string;
  resource_type_name: string;
  resource_period: string;
  resource_by_candidate: IResourceByCandidate[];
  period_identification: string;
  resource_type_has_question: boolean;
  is_in_resource: boolean;
}

interface IResourceByCandidate {
  resource_id: string;
  resource_code: number;
  resource_created_at: string;
  status: string;
  question?: string;
}

const RequestDetailResources = async (resourceId: string) => {
  const response = await configApi.get<ISelectiveProcessDetailResource>(`candidate-area/resource/${resourceId}/`);
  return response.data;
};

export const useRequestDetailResources = (resourceId: string) => {
  return useQuery({
    queryKey: ['detail_resource', resourceId],
    queryFn: () => RequestDetailResources(resourceId),
    refetchOnWindowFocus: false,
  });
};
