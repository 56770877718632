export enum ETypeError {
  noError = 0,
  invalidFormat = 1,
  invalidBiggerYear = 2,
  invalidMinorYear = 3,
  invalidMonth = 4,
  invalidDay = 5,
  invalidFutureDate = 6,
}
export const validateDate = (value?: string): ETypeError => {
  const date = value || '';
  const getOnlyNumber = date.replace(/\D/g, '');
  if (getOnlyNumber.length !== 8) {
    return ETypeError.invalidFormat;
  }
  const objectDate = new Date();
  const separateDate = date.split('-').reverse();
  if (separateDate[2] > objectDate.getFullYear().toString()) {
    return ETypeError.invalidBiggerYear;
  }
  if (separateDate[2] < '1900') {
    return ETypeError.invalidMinorYear;
  }
  const month = parseInt(separateDate[1]);
  if (month < 1 || month > 12) {
    return ETypeError.invalidMonth;
  }
  const day = parseInt(separateDate[0]);
  const lastDayOfDate = new Date(parseInt(separateDate[2]), parseInt(separateDate[1]), 0).getDate();
  if (day < 1 || parseInt(separateDate[0]) > lastDayOfDate) {
    return ETypeError.invalidDay;
  }
  const currentDateObject = new Date(Date.now());

  if (
    new Date(parseInt(separateDate[2]), parseInt(separateDate[1]), parseInt(separateDate[0])) >
    new Date(currentDateObject.getFullYear(), currentDateObject.getMonth() + 1, currentDateObject.getDate())
  ) {
    return ETypeError.invalidFutureDate;
  }
  return ETypeError.noError;
};

export const switchMessageError = (typeError: ETypeError): string => {
  let messageError;
  switch (typeError) {
    case ETypeError.invalidFormat:
      messageError = 'Data incompleta ou formato inválido';
      break;
    case ETypeError.invalidBiggerYear:
      messageError = 'O Ano não pode ser maior que o atual';
      break;
    case ETypeError.invalidMinorYear:
      messageError = 'O Ano não pode ser menor que o 1900';
      break;
    case ETypeError.invalidMonth:
      messageError = 'Mês inválido';
      break;
    case ETypeError.invalidDay:
      messageError = 'Dia inválido';
      break;
    case ETypeError.invalidFutureDate:
      messageError = 'A data não pode ser maior que hoje';
      break;
    default:
      messageError = '';
      break;
  }
  return messageError;
};
