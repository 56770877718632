import { FormControl, FormLabel, Select, Stack } from '@chakra-ui/react';
import MainCard from '../../../../components/MainCard/Index';
import { useGetRegistration } from '../../../../services/hooks/Registration/useGetRegistration';
import { useLocation } from 'react-router-dom';
import { TextL, TextXS } from '../../../../components/Text/Index';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useRequestGetExemptionType } from '../../../../services/hooks/Exemptions/useRequestGetExemptionType';
import MarrowDonnor from '../../Exemptions/Forms/MarrowDonnor/Index';
import NIS from '../../Exemptions/Forms/NIS/Index';
import { ReactNode } from 'react';
interface IOwnProps {
  previousRoute?: string;
  registrationId?: string;
}

const ExemptionRequest: React.FC<IOwnProps> = () => {
  const navProps = useLocation().state as IOwnProps;
  const getRegistration = useGetRegistration(navProps?.registrationId || '');
  const getExemption = useRequestGetExemptionType();

  const validateExemption = Yup.object().shape({
    type_exemption: Yup.string(),
  });

  const { register, watch, setValue } = useForm({
    resolver: yupResolver(validateExemption),
  });

  const handleShowForm = (): ReactNode => {
    const form = watch('type_exemption');

    if (form) {
      const formToShow = JSON.parse(form);

      if (formToShow?.name?.toLowerCase().includes('doador')) {
        return <MarrowDonnor exemptionId={formToShow.id} registrationId={navProps?.registrationId || ''} />;
      } else {
        return (
          <NIS onSetValue={setValue} exemptionId={formToShow.id} registrationId={navProps?.registrationId || ''} />
        );
      }
    }

    return false;
  };

  return (
    <MainCard title="ÁREA DO CANDIDATO - " subtitle="SOLICITAÇÃO DE ISENÇÃO DA TAXA DE INSCRIÇÃO">
      <Stack px={['10px', '10px', '10px', '50px', '50px']}>
        {getRegistration?.data?.can_open_another_exemption_request ? (
          <Stack>
            <TextL fontWeight="bold">SOLICITAR ISENÇÃO DA TAXA DE INSCRIÇÃO</TextL>

            <FormControl>
              <FormLabel>Tipo de Solicitação de Isenção</FormLabel>
              <Select {...register('type_exemption')} w="100%" maxW="350px">
                <option hidden value="">
                  Selecione
                </option>
                {getExemption?.data?.map((exemption, index) => (
                  <option key={index} value={JSON.stringify(exemption)}>
                    {exemption?.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            {handleShowForm()}
            {/* {(handleShowForm() && (
              <NIS
                onSetValue={setValue}
                exemptionId="afc04934-5598-42c9-86d4-2c0518dfe07d"
                registrationId={navProps?.registrationId || ''}
              />
            )) ||
              (watch('type_exemption') === '4d7debc6-28a6-43ef-8e8a-68315894b5f1' && (
                <MarrowDonnor
                  exemptionId="4d7debc6-28a6-43ef-8e8a-68315894b5f1"
                  registrationId={navProps?.registrationId || ''}
                />
              ))} */}
          </Stack>
        ) : (
          <Stack>
            <TextL fontWeight="bold">SOLICITAR ISENÇÃO DA TAXA DE INSCRIÇÃO</TextL>
            <TextXS>Não é possível enviar solicitações para este processo seletivo!</TextXS>
          </Stack>
        )}
      </Stack>
    </MainCard>
  );
};

export default ExemptionRequest;
