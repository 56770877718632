import { useQuery } from "@tanstack/react-query";
import { configApi } from "../../configApi"

interface IEducationLevel{
    id: string;
    name: string;
    order: number
}


const RequestGetEducationLevel = async ()=>{
    const response = await configApi.get<IEducationLevel[]>('general-records/education-level/')
    return response.data
}


export const useRequestGetEducationLevel = () =>{
    return useQuery({
        queryKey: ['get_education_level'],
        queryFn: RequestGetEducationLevel,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    })
}