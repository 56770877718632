import { useQuery } from "@tanstack/react-query";
import { configApi } from "../../configApi"

interface IResultState{
    id: string;
    name: string;
}


const RequestGetGender = async ()=>{
    const response = await configApi.get<IResultState[]>('general-records/gender/')
    return response.data
}


export const useRequestGetGender = () =>{
    return useQuery({
        queryKey: ['get_gender'],
        queryFn: RequestGetGender,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    })
}