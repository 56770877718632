import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  ListItem,
  Select,
  Stack,
  Textarea,
  UnorderedList,
} from '@chakra-ui/react';
import MessageError from '../../../MessageError';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useRequestSelectiveProcessVacancy } from '../../../../services/hooks/SelectiveProcess/useRequestSelectiveProcessVacancy';
import { IStatusProps } from '../../../../services/hooks/SelectiveProcess/Types';
import { ISelectionItem } from '../../../../services/hooks/CandidateArea/useGetDataToRegistration';
import { Dispatch, SetStateAction } from 'react';
import { TextXS } from '../../../Text/Index';
import { IReserved_vacancy } from '../../../../services/hooks/Registration/useGetRegistration';
import { IoLink } from 'react-icons/io5';

interface IhookForms {
  medical_report?: FieldValues;
  modality_type?: string[];
  vacancy?: string;
  deficiency?: string;
  cid?: string;
  type_of_competition?: string;
}

interface IOwnProps {
  hookForms: UseFormReturn<IhookForms>;
  selectiveProcessId: string;
  modalityList?: ISelectionItem[];
  isPCD: boolean;
  isCultureHouse: boolean;
  setIsPCD: Dispatch<SetStateAction<boolean>>;
  setModalityList: Dispatch<SetStateAction<ISelectionItem[] | undefined>>;
  detailPcd?: IReserved_vacancy;
}

export enum ETypeOfCompetition {
  FULL_VACANCY = '1',
  SPECIAL_VACANCY = '2',
}

const DefaultVacancyForm: React.FC<IOwnProps> = ({
  setIsPCD,
  setModalityList,
  hookForms,
  selectiveProcessId,
  modalityList,
  isPCD,
  isCultureHouse,
  detailPcd,
}) => {
  const vacancy = useRequestSelectiveProcessVacancy(selectiveProcessId, isCultureHouse);

  // let full_vacancy_id = '';

  // vacancy.data?.forEach((item) => {
  //   item.type_of_competition.forEach((type) => {
  //     if (type.name.toLowerCase().includes('ampla')) {
  //       full_vacancy_id = type.id;
  //       return;
  //     }
  //   });
  // });

  return (
    <Stack>
      <FormControl isRequired>
        <TextXS>Selecione a sua vaga/cargo desejado </TextXS>
        <Select
          {...hookForms.register('vacancy', {
            onChange: (e) => {
              const list = vacancy.data || [];
              const vacancyId = e.currentTarget.value;
              list.forEach((vacancy) => {
                if (vacancy.id === vacancyId) setModalityList(vacancy.type_of_competition);
              });
            },
          })}
          isRequired={false}
        >
          <option value="" hidden>
            Selecione...
          </option>
          {vacancy.data?.map((normal) => (
            <option key={normal.id} value={normal.id}>
              {normal.name}
            </option>
          ))}
        </Select>
        <MessageError>{hookForms.formState.errors?.vacancy?.message}</MessageError>
      </FormControl>
      <FormControl isRequired>
        <TextXS>Tipo de competição:</TextXS>
        <Select {...hookForms.register('type_of_competition')} isRequired={false}>
          <option value="" hidden>
            Selecione...
          </option>

          <option value={ETypeOfCompetition.FULL_VACANCY}>Ampla Concorrência</option>
          <option value={ETypeOfCompetition.SPECIAL_VACANCY}>Vagas Reservadas</option>
        </Select>
        <MessageError>{hookForms.formState.errors?.type_of_competition?.message}</MessageError>
      </FormControl>
      {modalityList && hookForms.watch('type_of_competition') == ETypeOfCompetition.SPECIAL_VACANCY && (
        <Stack>
          <FormControl isRequired>
            <FormLabel fontWeight="bold">Selecione sua modalidade de concorrência</FormLabel>
            <Stack>
              {modalityList
                .filter((modality) => !modality.name.toLowerCase().includes('ampla'))
                .map((modality, index) => {
                  const toConvertModality = hookForms.watch(`modality_type`);

                  const stringModality = toConvertModality?.find((convertModality) => {
                    if (convertModality) {
                      const converted = JSON.parse(convertModality);
                      if (converted.id === modality.id) {
                        return true;
                      }
                    }
                    return false;
                  });

                  let modalityType = stringModality ? JSON.parse(stringModality) : false;

                  if (modalityType) {
                    if (modalityType?.id === modality?.id) {
                      modalityType = true;
                    } else modalityType = false;
                  }

                  return (
                    <Checkbox
                      isChecked={modalityType}
                      {...hookForms.register(`modality_type.${index}`, {
                        onChange: (value) => {
                          const id = value.currentTarget.value ?? '';
                          const modalityType: IStatusProps = JSON.parse(id);

                          if (modalityType?.id === modality.id && modality.name?.toLowerCase().includes('pcd')) {
                            setIsPCD((value) => !value);
                          }
                        },
                      })}
                      value={JSON.stringify(modality)}
                      key={modality.id}
                      isRequired={false}
                    >
                      {modality.name}
                    </Checkbox>
                  );
                })}
            </Stack>
            <MessageError>{hookForms.formState.errors?.modality_type?.root?.message}</MessageError>
          </FormControl>
          {isPCD && (
            <>
              <Stack gap={3}>
                <Stack gap={0} mt={3}>
                  <FormControl isRequired>
                    <FormLabel fontWeight="bold">Envio de laudo - PCD - Pessoas com Deficiencia</FormLabel>
                  </FormControl>
                  {/* <TextXS>Envio de laudo - PCD - Pessoas com Deficiencia</TextXS> */}
                  <Input
                    alignContent="center"
                    mt={1}
                    w="fit-content"
                    {...hookForms.register('medical_report')}
                    type="file"
                    multiple
                  />
                  {hookForms.formState.errors?.medical_report && (
                    <MessageError>{`${hookForms.formState.errors.medical_report.message}`}</MessageError>
                  )}
                  {detailPcd && (
                    <Stack mt={2}>
                      <TextXS fontWeight="bold">Anexos Enviados</TextXS>
                      <UnorderedList styleType={"''"}>
                        {detailPcd?.attachments?.map((attach) => (
                          <ListItem key={attach?.id}>
                            <Flex gap={2} align="center" color="colorLink">
                              <IoLink />
                              <Link target="_blank" href={attach?.url}>
                                {attach?.name}
                              </Link>
                            </Flex>
                          </ListItem>
                        ))}
                      </UnorderedList>
                    </Stack>
                  )}
                </Stack>

                <Stack gap={0} mt={3}>
                  <FormControl isRequired>
                    <FormLabel fontWeight="bold">CID</FormLabel>
                  </FormControl>
                  {/* <TextXS fontWeight="bold">CID</TextXS> */}
                  <Input mt={1} {...hookForms.register('cid')} />
                  {hookForms.formState.errors?.cid && (
                    <MessageError>{`${hookForms.formState.errors.cid.message}`}</MessageError>
                  )}
                </Stack>

                <Stack gap={0} mt={3}>
                  <FormControl isRequired>
                    <FormLabel fontWeight="bold">Descrição da Deficiência</FormLabel>
                  </FormControl>
                  {/* <TextXS fontWeight="bold">Descrição da Deficiência</TextXS> */}
                  <Textarea {...hookForms.register('deficiency')} mt={1} />
                  {hookForms.formState.errors?.deficiency && (
                    <MessageError>{`${hookForms.formState.errors.deficiency.message}`}</MessageError>
                  )}
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default DefaultVacancyForm;
