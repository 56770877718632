import MainCard from '../../../components/MainCard/Index';
import Exemptions from '../CandidateArea/ExemptionComponent/Index';

const ExemptionsPage: React.FC = () => {
  return (
    <MainCard height="100%" title="ÁREA DO CANDIDATO - " subtitle="MINHAS ISENÇÕES">
      <Exemptions />
    </MainCard>
  );
};

export default ExemptionsPage;
