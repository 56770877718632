import { useMutation } from '@tanstack/react-query';
import { configApi } from '../../configApi';

export interface ICandidate {
  date_of_birth?: string;
  gender?: string;
  email?: string;
  document_type?: string;
  document_number?: string;
  document_issuing_body?: string;
  document_issuing_body_uf?: string;
  addressZipCode?: string;
  address_street?: string;
  address_number?: string;
  address_complement?: string;
  address_neighborhood?: string;
  address_state?: string;
  address_city?: string;
  cellphone?: string;
  password?: string;
  confirm_password?: string;
  full_name?: string;
  cpf?: string;
  phone?: string;
  document_date_of_issuance?: string;
  mother_name?: string;
  marital_status?: string;
  education_level?: string;
  nationality?: string;
  city_naturality?: string;
  country?: string;
  avatar?: File;
  social_name?: string;
}

const removeMask = (str: string) => {
  const input = str ?? '';
  return input.replace(/\D/g, '');
};

const createCandidate = async (data: ICandidate) => {
  const formData = new FormData();

  if (data.avatar) {
    formData.append('avatar', data.avatar);
  }

  if (data.date_of_birth) {
    formData.append('date_of_birth', data.date_of_birth);
  }
  if (data.country) {
    formData.append('other_nationality', data.country);
  }

  if (data.social_name) {
    formData.append('social_name', data.social_name);
  }

  if (data.education_level) {
    formData.append('education_level', data.education_level);
  }

  if (data.city_naturality) {
    formData.append('city_of_birth', data.city_naturality);
  }

  if (data.nationality) {
    formData.append('nationality', data.nationality);
  }

  if (data.document_date_of_issuance) {
    formData.append('document_date_of_issuance', data.document_date_of_issuance);
  }
  if (data.phone) {
    formData.append('phone', removeMask(data.phone));
  }
  if (data.cpf) {
    formData.append('cpf', removeMask(data.cpf));
  }
  if (data.full_name) {
    formData.append('full_name', data.full_name);
  }
  if (data.mother_name) {
    formData.append('mother_name', data.mother_name);
  }

  if (data.gender) {
    formData.append('gender', data.gender);
  }
  if (data.marital_status) {
    formData.append('marital_status', data.marital_status);
  }
  if (data.email) {
    formData.append('email', data.email.toLowerCase().trim());
  }
  if (data.document_type) {
    formData.append('document_type', data.document_type);
  }

  if (data.document_number) {
    formData.append('document_number', data.document_number);
  }
  if (data.document_issuing_body) {
    formData.append('document_issuing_body', data.document_issuing_body);
  }
  if (data.document_issuing_body_uf) {
    formData.append('document_issuing_body_uf', data.document_issuing_body_uf);
  }
  if (data.addressZipCode) {
    formData.append('address_zip_code', removeMask(data.addressZipCode));
  }
  if (data.address_street) {
    formData.append('address_street', data.address_street);
  }
  if (data.address_number) {
    formData.append('address_number', data.address_number);
  }
  if (data.address_complement) {
    formData.append('address_complement', data.address_complement);
  }
  if (data.address_neighborhood) {
    formData.append('address_neighborhood', data.address_neighborhood);
  }
  if (data.address_state) {
    formData.append('address_state', data.address_state);
  }
  if (data.address_city) {
    formData.append('address_city', data.address_city);
  }
  if (data.cellphone) {
    formData.append('cellphone', removeMask(data.cellphone));
  }
  if (data.password) {
    formData.append('password', data.password);
  }
  if (data.confirm_password) {
    formData.append('confirm_password', data.confirm_password);
  }

  const response = await configApi.post(`/candidate/create/`, formData);
  return response;
};

export const useCreateCandidate = () => {
  return useMutation({
    mutationFn: (data: ICandidate) => createCandidate(data),
    mutationKey: ['createCandidate'],
  });
};
