import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';
import { IDateType } from '../Types';

export interface ISelectiveProcess {
  id: string;
  name: string;
  registrations_start: IDateType;
  registrations_end: IDateType;
  exemption_start: IDateType;
  has_pcd: boolean;
  has_black_or_brown: boolean;
  exemption_end: IDateType;
  number_of_vacancies: number;
  public_notice_number: string;
  selective_process_situation: ISelectiveProcessSituation[];
  type: string;
}

export interface IDefaultResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: ISelectiveProcess[];
}

export interface ISelectiveProcessSituation {
  id: string;
  name: string;
}

const RequestSelectiveProcess = async (filter: string = '', finalSearch: string = '') => {
  const response = await configApi.get<IDefaultResponse>(
    `candidate-area/selective-process/?status=${filter}&name=${finalSearch}`,
  );
  return response.data;
};

export const useRequestSelectiveProcess = (filter?: string, finalSearch?: string) => {
  return useQuery({
    queryKey: ['selective_process', filter, finalSearch],
    queryFn: () => RequestSelectiveProcess(filter, finalSearch),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
