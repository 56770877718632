import {
  Accordion,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  Grid,
  Input,
  Link,
  Select,
  Stack,
  Td,
  Text,
  Textarea,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import MainCard from '../../../components/MainCard/Index';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { ContextAuth } from '../../../contexts/Authentication';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AccordionTemplate from '../../../components/AccordionTemplate/Index';
import {
  ISelectionItem,
  useRequestGetDataToRegistration,
} from '../../../services/hooks/CandidateArea/useGetDataToRegistration';
import { FieldValues, useForm } from 'react-hook-form';
import { validadeFileName, validateMaxFile, validateTypeFile, validationSizeFile } from '../../../utils/validationForm';
import MessageError from '../../../components/MessageError';
import { useCreateRegistration } from '../../../services/hooks/Registration/usePostRegistration';
import VacancyCutureHome from '../../../components/pages/Registration/CultureHousesVacancy';
import DefaultVacancyForm, { ETypeOfCompetition } from '../../../components/pages/Registration/DefaultVacancy';
import { TextL, TextXS, TextXXS } from '../../../components/Text/Index';
import { useRequestSelectiveProcessVacancy } from '../../../services/hooks/SelectiveProcess/useRequestSelectiveProcessVacancy';
import { IGetRegistration, useGetRegistration } from '../../../services/hooks/Registration/useGetRegistration';
import { useUpdateRegistration } from '../../../services/hooks/Registration/useUpdateRegistration';
import TableCustom from '../../../components/pages/CandidateArea/Table';
import { handleDeleteAttach } from '../../../utils/functions';
import { useVerifyExemptionStandBy } from '../../../services/hooks/CandidateArea/useVerifyExemptionStandBy';

const SelectiveProcessSubscribe: React.FC = () => {
  const selectiveProcessId = useLocation().pathname.split('/')[2];
  const VerifyExemptionStandBy = useVerifyExemptionStandBy(selectiveProcessId);
  const registrationId = useLocation().pathname.split('/')[4];
  const navigate = useNavigate();
  const { signOut, candidate } = useContext(ContextAuth);
  const [modalityList, setModalityList] = useState<ISelectionItem[]>();
  const [isPCD, setIsPCD] = useState<boolean>(false);
  const toast = useToast();
  const selectiveProcessData = useRequestGetDataToRegistration(selectiveProcessId);
  const vacancy = useRequestSelectiveProcessVacancy(
    selectiveProcessId,
    selectiveProcessData.data?.is_cultural_house || false,
  );
  const { mutateAsync } = useCreateRegistration();
  const registrationData = useGetRegistration(registrationId);
  const updateRegistration = useUpdateRegistration();
  const attachSpecialCondition = ['Arquivo', 'Data de envio', 'Ações'];

  const listChecked: (string | boolean)[] = [];
  const listObservation: (string | boolean | undefined)[] = [];

  selectiveProcessData.data?.special_condition.map((s) => {
    let isSelected = false;
    let obs = '';

    registrationData.data?.special_conditions.forEach((r) => {
      if (r.id === s.id) {
        isSelected = true;
        obs = r.observations;
      }
    });

    if (isSelected) {
      listChecked.push(s.id);
      listObservation.push(obs);
    } else {
      listChecked.push(false);
      listObservation.push('');
    }
  });

  let hasFullVacancy = (registrationData?.data && ETypeOfCompetition?.SPECIAL_VACANCY) || '';

  registrationData.data?.type_of_competition?.forEach((item) => {
    if (item.name.toLowerCase().includes('ampla')) {
      hasFullVacancy = ETypeOfCompetition.FULL_VACANCY;
    }
  });

  const schema = Yup.object().shape({
    has_second_vacancy: Yup.boolean(),
    type_of_competition: Yup.string().when({
      is: () => !selectiveProcessData.data?.is_cultural_house,
      then: (schema) => schema.required('Campo Obrigatório'),
    }),
    vacancy: Yup.string().when({
      is: () => !selectiveProcessData.data?.is_cultural_house || selectiveProcessData.data?.is_cultural_house,
      then: (schema) => schema.required('Campo Obrigatório'),
    }),
    culture_home: Yup.string().when({
      is: () => selectiveProcessData.data?.is_cultural_house,
      then: (schema) => schema.required('Campo Obrigatório'),
    }),
    // vagancy_type: Yup.string().when(['culture_home'], {
    //   is: (culture_home: string) => !!culture_home,
    //   then: (schema) => schema.required('O Campo é obrigatorio'),
    // }),
    initial_semester: Yup.string().when(['vagancy_type'], {
      is: (vagancy_type: string) => vagancy_type === 'is_initial_semester',
      then: (schema) => schema.required('O Campo é obrigatorio'),
    }),
    nivel_test: Yup.string().when(['vagancy_type'], {
      is: (vagancy_type: string) => vagancy_type === 'is_level_test',
      then: (schema) => schema.required('O Campo é obrigatorio'),
    }),
    culture_home_secondary: Yup.string().when(['has_second_vacancy'], {
      is: (hasSecondVacancy: boolean) => hasSecondVacancy,
      then: (schema) => schema.required('O Campo é obrigatorio'),
    }),
    initial_semester_secondary: Yup.string().when(['has_second_vacancy'], {
      is: (hasSecondVacancy: boolean) => hasSecondVacancy,
      then: (schema) =>
        schema
          .required('O Campo é obrigatorio')
          .test('Campos iguais', 'Atenção! A sua segunda opção de turma deve ser diferente da primeira.', () => {
            if (hookForms.watch('vacancy') === hookForms.watch('initial_semester_secondary')) {
              toast({
                title: 'Atenção',
                description: 'Atenção! A sua segunda opção de turma deve ser diferente da primeira.',
                status: 'error',
                duration: 4000,
                isClosable: true,
              });
              return false;
            }
            return true;
          }),
    }),
    juror_art_440_cpp: Yup.string().test('isRequired', 'O campo é obrigatorio', (value) => {
      if (selectiveProcessData.data?.juror_under_article_440 && value?.length == 0) return false;
      return true;
    }),
    // if (isPCD) {
    //   let validateMedicalReport = medical_report.length == 0;
    //   console.log(validateMedicalReport);

    //   if (registrationData?.data && registrationId) {
    //     registrationData.data?.reserved_vacancy.forEach((reserved) => {
    //       if (reserved?.type?.toLowerCase()?.includes('pcd') && reserved?.attachments?.length > 0) {
    //         validateMedicalReport = false;
    //       }
    //     });
    //   }
    //   return validateMedicalReport;
    // }
    weapon_carry_permit: Yup.boolean(),
    medical_report: Yup.mixed().when('type_of_competition', {
      is: (type: ETypeOfCompetition) => {
        return isPCD && type == ETypeOfCompetition.SPECIAL_VACANCY;
      },
      then: (schema) =>
        schema
          .test('isRequired', 'Envie o seu laudo para prosseguir na vaga destinada a PCD.', (file) => {
            const listFile = file as FileList;
            let validateMedicalReport = listFile.length > 0;

            if (registrationData?.data && registrationId) {
              registrationData.data?.reserved_vacancy.forEach((reserved) => {
                if (reserved?.type?.toLowerCase()?.includes('pcd') && reserved?.attachments?.length > 0) {
                  validateMedicalReport = true;
                }
              });
            }

            return validateMedicalReport;
          })
          .test('validationSizeFile', 'Tamanho máximo para arquivos é de 2MB', (file) => {
            console.log(file);
            const listFile = file as FileList;

            if (listFile?.length > 0) {
              return validationSizeFile(listFile);
            }

            return true;
          })
          .test('validationMaxFile', 'Quantidade máxima de arquivos é de 5 arquivos', (file) => {
            const listFile = file as FileList;

            if (listFile?.length > 0) {
              return validateMaxFile(listFile);
            }
            return true;
          })
          .test('validationTypeFile', 'Formato de arquivo não suportado', (file) => {
            const listFile = file as FileList;

            if (listFile?.length > 0) {
              return validateTypeFile(listFile);
            }
            return true;
          })
          .test('verifyFileName', 'Nome do arquivo invalido', (file) => {
            const listFile = file as FileList;
            if (listFile && listFile.length > 0) {
              return validadeFileName(listFile);
            }
            return true;
          }),
    }),

    cid: Yup.string().when({
      is: () => isPCD,
      then: (schema) => schema.required('Campo é obrigatorio'),
    }),
    deficiency: Yup.string().when({
      is: () => isPCD,
      then: (schema) => schema.required('Campo é obrigatorio'),
    }),
    has_special_condition: Yup.string().when({
      is: () => selectiveProcessData.data?.allows_special_condition,
      then: (schema) => schema.required('O campo é obrigatorio'),
    }),
    modality_type: Yup.array().test('isRequired', 'Selecione ao menos uma modalidade!', (listModality) => {
      if (
        !modalityList ||
        hookForms.getValues('type_of_competition') !== ETypeOfCompetition.SPECIAL_VACANCY ||
        (modalityList && modalityList.length === 0)
      )
        return true;

      let isEmpty = false;

      listModality?.forEach((modality) => {
        const objectModality = JSON.parse(modality);
        if (modality !== false && !objectModality?.name?.toLowerCase()?.includes('ampla')) isEmpty = true;
      });

      return isEmpty;
    }),
    attachmentFile: Yup.array(),
    observation: Yup.array(),
    selectedSpecialCondition: Yup.array()
      .test('isRequired', 'Selecione ao menos uma condição especial', (specialConditionList) => {
        let isEmpty = false;

        if (!(hookForms.getValues('has_special_condition') === 'yes')) return true;

        specialConditionList?.forEach((modality) => {
          if (modality !== false) {
            isEmpty = true;
            return;
          }
        });

        return isEmpty;
      })
      .test('isRequired', '', (specialConditionList) => {
        if (!(hookForms.getValues('has_special_condition') === 'yes')) return true;

        const validationError: Yup.ValidationError[] = [];

        specialConditionList?.forEach((modality, index) => {
          if (modality !== false) {
            const observation: string = hookForms.getValues(`observation.${index}`);
            if (observation?.length === 0) {
              validationError.push(
                new Yup.ValidationError('Campo Obrigatorio', specialConditionList[index], `observation.${index}`),
              );
            }
          }
        });
        if (validationError.length > 0) {
          throw new Yup.ValidationError(validationError);
        }

        return true;
      })
      // .test('isRequired', '', (specialConditionList) => {
      //   const validationError: Yup.ValidationError[] = [];
      //   specialConditionList?.forEach((modality, index) => {
      //     if (modality !== false) {
      //       const file: FileList = hookForms.getValues(`attachmentFile.${index}`);
      //       if (file.length === 0) {
      //         validationError.push(
      //           new Yup.ValidationError(
      //             'Selecione um arquivo para continuar',
      //             specialConditionList[index],
      //             `attachmentFile.${index}`,
      //           ),
      //         );
      //       }
      //     }
      //   });
      //   if (validationError.length > 0) {
      //     throw new Yup.ValidationError(validationError);
      //   }
      //   return false;
      // })
      .test('TypeFile', 'Tipo de arquivo nao suportado', (specialConditionList) => {
        if (!specialConditionList || (specialConditionList && specialConditionList.length === 0)) return true;

        const validationError: Yup.ValidationError[] = [];
        specialConditionList?.forEach((modalityListFile, index) => {
          if (modalityListFile !== false) {
            const file: FileList = hookForms.getValues(`attachmentFile.${index}`);
            if (file?.length > 0) {
              const response = validateTypeFile(file);
              if (!response) {
                validationError.push(
                  new Yup.ValidationError(
                    'Tipo de arquivo nao suportado',
                    specialConditionList[index],
                    `attachmentFile.${index}`,
                  ),
                );
              }
            }
          }
        });
        if (validationError.length > 0) {
          throw new Yup.ValidationError(validationError);
        }
        return true;
      })
      .test('MaxFileSize', '', (specialConditionList) => {
        if (!specialConditionList || (specialConditionList && specialConditionList.length === 0)) return true;

        const validationError: Yup.ValidationError[] = [];
        specialConditionList?.forEach((modalityListFile, index) => {
          if (modalityListFile !== false) {
            const file: FileList = hookForms.getValues(`attachmentFile.${index}`);
            if (file?.length > 0) {
              const response = validationSizeFile(file);
              if (!response) {
                validationError.push(
                  new Yup.ValidationError(
                    'Tamanho máximo para arquivos é de 2MB',
                    specialConditionList[index],
                    `attachmentFile.${index}`,
                  ),
                );
              }
            }
          }
        });
        if (validationError.length > 0) {
          throw new Yup.ValidationError(validationError);
        }
        return true;
      })
      .test('MaxFile', 'Quantidade maxima de arquivos atingida (5)', (specialConditionList) => {
        if (!specialConditionList || (specialConditionList && specialConditionList.length === 0)) return true;

        const validationError: Yup.ValidationError[] = [];

        specialConditionList?.forEach((modalityListFile, index) => {
          if (modalityListFile !== false) {
            const attach = hookForms.getValues(`attachmentFile.${index}`);
            if (attach && attach?.length > 0) {
              registrationData.data?.special_conditions.forEach((value) => {
                if (value.id === modalityListFile) {
                  const file = value?.attachments?.length || 0;
                  if (file > 4) {
                    validationError.push(
                      new Yup.ValidationError(
                        'Quantidade maxima de arquivos atingida (5)',
                        specialConditionList[index],
                        `attachmentFile.${index}`,
                      ),
                    );
                  }
                }
              });
            }
          }
        });
        if (validationError.length > 0) {
          throw new Yup.ValidationError(validationError);
        }
        return true;
      })
      .test('FileName', 'Nome de arquivo invalido', (specialConditionList) => {
        if (!specialConditionList || (specialConditionList && specialConditionList.length === 0)) return true;

        const validationError: Yup.ValidationError[] = [];

        specialConditionList?.forEach((modalityListFile, index) => {
          if (modalityListFile !== false) {
            const attach = hookForms.getValues(`attachmentFile.${index}`);
            if (attach?.length > 0) {
              const file = validadeFileName(attach);

              if (!file) {
                validationError.push(
                  new Yup.ValidationError(
                    'Nome do arquivo invalido',
                    specialConditionList[index],
                    `attachmentFile.${index}`,
                  ),
                );
              }
            }
          }
        });
        if (validationError.length > 0) {
          throw new Yup.ValidationError(validationError);
        }
        return true;
      }),
  });

  const pcdDetails = registrationData.data?.reserved_vacancy.find((reserved) =>
    reserved.type.toLowerCase().includes('pcd'),
  );

  const hookForms = useForm({
    resolver: yupResolver(schema),
    values: {
      vacancy: registrationData.data?.selective_process_vacancy?.id,
      type_of_competition: registrationData.data?.type_of_competition.length === 0 ? '' : hasFullVacancy,
      has_special_condition:
        (registrationData.data?.special_conditions &&
          (registrationData.data.special_conditions.length > 0 ? 'yes' : 'no')) ||
        '',
      culture_home: registrationData.data?.selective_process_vacancy?.cultural_house || '',
      has_second_vacancy: registrationData.data?.second_vacancy ? true : false,
      culture_home_secondary: registrationData.data?.second_vacancy?.cultural_house,
      weapon_carry_permit: registrationData.data?.has_gun_license,
      initial_semester_secondary: registrationData.data?.second_vacancy?.id,
      juror_art_440_cpp: registrationData.data?.juror_art_440_cpp ? 'yes' : 'no',
      selectedSpecialCondition: listChecked,
      observation: listObservation,
      cid: pcdDetails?.cid,
      deficiency: pcdDetails?.deficiency,
      modality_type: registrationData.data?.type_of_competition.map((competition) => JSON.stringify(competition)),
    },
  });

  useEffect(() => {
    if (registrationData.data?.second_vacancy) {
      hookForms.setValue('has_second_vacancy', true);
    } else {
      hookForms.setValue('has_second_vacancy', false);
    }

    if (registrationData?.data?.juror_art_440_cpp) {
      hookForms.setValue('juror_art_440_cpp', 'yes');
    } else {
      hookForms.setValue('juror_art_440_cpp', 'no');
    }
  }, [registrationData.data]);

  const patchRegistration = async (data: FieldValues, oldData?: IGetRegistration) => {
    updateRegistration
      .mutateAsync({
        data,
        selectiveProcess: selectiveProcessData.data,
        listTypeCompetition: vacancy.data,
        registrationId: registrationId,
        exemptionRegistrationId: VerifyExemptionStandBy?.data?.id,
        oldData: oldData,
      })
      .then((response) => {
        toast({
          title: 'Sucesso',
          description: registrationId ? 'Inscrição atualizada com sucesso!' : 'Inscrição realizada com sucesso!',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        if (registrationId) {
          navigate(-1);
        } else {
          navigate(
            `/processo-seletivo/${selectiveProcessId}/inscricao/${response?.id}/resumo?previousRoute=/processo-seletivo/inscricao&standbyExemption=true`,
          );
        }
      })
      .catch((error) => {
        toast({
          title: 'Erro',
          description: error.response?.data.message || 'Erro na solicitação, tente novamente mais tarde',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      });
  };

  const postRegistration = async (data: FieldValues) => {
    mutateAsync({ data, selectiveProcess: selectiveProcessData.data, listTypeCompetition: vacancy.data })
      .then((response) => {
        toast({
          title: 'Sucesso',
          description: 'Inscrição criada com sucesso!',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        navigate(
          `/processo-seletivo/${selectiveProcessId}/inscricao/${response?.id}/resumo?previousRoute=/processo-seletivo/inscricao`,
        );
      })
      .catch((error) => {
        toast({
          title: 'Erro',
          description: error.response?.data.message || 'Não foi possivel realizar a inscrição.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      });
  };

  const newRegistration = (data: FieldValues) => {
    if (data.type_of_competition === ETypeOfCompetition.FULL_VACANCY) {
      modalityList?.forEach((type) => {
        if (type.name.toLowerCase().includes('ampla')) {
          data = { ...data, type_of_competition: type.id, is_all: true };
        }
      });
    }

    if (VerifyExemptionStandBy?.data) {
      if (VerifyExemptionStandBy?.data.selective_process_vacancy && selectiveProcessData?.data && !registrationId) {
        postRegistration(data);
      } else if (selectiveProcessData.data) {
        patchRegistration(data, registrationData?.data);
      }
    } else if (registrationId && selectiveProcessData?.data) {
      patchRegistration(data, registrationData.data);
    } else if (selectiveProcessData?.data) {
      postRegistration(data);
    }
  };

  useEffect(() => {
    if (registrationData.data && vacancy?.data) {
      registrationData.data.type_of_competition.forEach((competition) => {
        if (competition.name.toLowerCase().includes('pcd')) setIsPCD(true);
      });

      const selectVacancy = vacancy?.data?.find(
        (vac) => registrationData?.data?.selective_process_vacancy?.id === vac?.id,
      );

      if (selectVacancy) {
        setModalityList(selectVacancy.type_of_competition);
      }
    }
  }, [registrationData.data, vacancy.data]);

  const renderFilename = (idx: number) => {
    const files: FileList = hookForms.watch(`attachmentFile.${idx}`);
    const listFileName: string[] = [];

    if (!files || files.length === 0) {
      return ['Envie seus arquivos'];
    } else {
      for (let index = 0; index < files.length; index++) {
        listFileName.push(files[index]?.name || 'Sem_nome');
      }
    }

    return listFileName;
  };

  return (
    <MainCard
      title="PROCESSO SELETIVO - "
      subtitle={registrationId ? 'MODIFICAR DADOS DA INSCRIÇÃO' : 'FORMULÁRIO DE INSCRIÇÃO'}
    >
      <Flex
        as="form"
        onSubmit={hookForms.handleSubmit(newRegistration)}
        flexDir="column"
        px={['10px', '10px', '10px', '50px', '50px']}
        gap={10}
      >
        <Flex
          justifyContent="space-between"
          alignItems="left"
          flexDir={['column', 'column', 'column', 'row', 'row']}
          overflow="hidden"
        >
          <Stack color="darkGrey">
            <TextXS>{selectiveProcessData.data?.solective_process_type_name || ''}</TextXS>
            <TextL>{selectiveProcessData.data?.name || ''}</TextL>
          </Stack>
        </Flex>
        <Stack>
          <Accordion display="flex" flexDir="column" gap={2} allowMultiple defaultIndex={[0, 1, 2, 3, 4]}>
            <AccordionTemplate title="DADOS PESSOAIS" border="1px solid" borderColor="primaryColor" borderRadius={6}>
              <Flex px={10} mb={5} flexDir={['column', 'column', 'row', 'row', 'row']}>
                <Stack w="100%" p={3}>
                  <TextXS>
                    <strong>Nome Completo:</strong> {candidate?.user?.full_name || '--'}
                  </TextXS>
                  {candidate?.social_name && (
                    <TextXS>
                      <strong>Nome social: </strong>
                      {candidate?.social_name || '--'}
                    </TextXS>
                  )}
                  <TextXS>
                    <strong>CPF:</strong> {candidate?.cpf || '--'} <br />
                  </TextXS>
                  <TextXXS ml="30px" as={'span'}>
                    Não é seu CPF?{' '}
                    <Link
                      onClick={() => {
                        signOut();
                        navigate('/');
                      }}
                      as={'span'}
                      color="colorLink"
                    >
                      clique aqui
                    </Link>
                  </TextXXS>
                  <TextXS>
                    <strong>Data de Nascimento:</strong> {candidate?.date_of_birth.br || '--'}
                  </TextXS>
                  <TextXS>
                    <strong>E-mail:</strong> {candidate?.user?.email || '--'}
                  </TextXS>
                  <TextXS>
                    <strong>{candidate?.document_type.name || '--'}</strong>
                    {': '}
                    {candidate?.document_number || '--'} {candidate?.document_issuing_body}{' '}
                    {candidate?.document_issuing_body_uf?.initials || ''}
                  </TextXS>
                  <TextXS>
                    <strong>Sexo:</strong> {candidate?.gender?.name || '--'}
                  </TextXS>
                </Stack>
                <Stack w="100%" p={3}>
                  <TextXS>
                    <strong>CEP:</strong> {candidate?.address_zip_code || '--'}
                  </TextXS>
                  <TextXS>
                    <strong>Endereço:</strong> {candidate?.address_street || '--'}
                  </TextXS>
                  <TextXS>
                    <strong>Número:</strong> {candidate?.address_number || '--'}
                  </TextXS>
                  <TextXS>
                    <strong>Complemento: </strong>
                    {candidate?.address_complement || '--'}
                  </TextXS>
                  <TextXS>
                    <strong>Bairro:</strong> {candidate?.address_neighborhood || '--'}
                  </TextXS>
                  <TextXS>
                    <strong>Cidade: </strong>
                    {candidate?.address_city?.name || '--'} / {candidate?.address_state?.initials || '--'}
                  </TextXS>
                  <TextXS>
                    <strong>Telefone:</strong> {candidate?.phone || '--'}
                  </TextXS>
                  <TextXS>
                    <strong>Celular:</strong> {candidate?.cellphone || '--'}
                  </TextXS>
                </Stack>
              </Flex>
              <Stack p={2}>
                <TextXS>
                  <strong>ATENÇÃO: </strong>Os dados acima estão incorretos? caso estejam,{' '}
                  <Link
                    onClick={() => {
                      navigate('/painel/meus-dados');
                    }}
                    color="colorLink"
                  >
                    clique aqui
                  </Link>{' '}
                  e atualize seu cadastro.
                </TextXS>
              </Stack>
            </AccordionTemplate>
            <AccordionTemplate
              title={selectiveProcessData.data?.is_cultural_house ? 'TURMA' : 'VAGA / CARGO DESEJADA'}
              border="1px solid"
              borderColor="primaryColor"
              borderRadius={6}
            >
              <Stack>
                <Flex
                  display={['flex', 'flex', 'flex', 'grid', 'grid']}
                  gridTemplateColumns={['', '', '', 'repeat(3, 1fr)', 'repeat(3, 1fr)']}
                  justify="center"
                  flexDir={['column', 'column', 'column', 'row', 'row']}
                  w="100%"
                  py={3}
                  gap={3}
                >
                  {(selectiveProcessData.data?.is_cultural_house && (
                    <VacancyCutureHome
                      selectiveProcessId={selectiveProcessId}
                      culture_houses={selectiveProcessData.data.cultural_houses}
                      isCultureHouse={selectiveProcessData.data.is_cultural_house}
                      hookForms={hookForms}
                      allow_second_registration={selectiveProcessData.data.allow_second_registration}
                    />
                  )) || (
                    <DefaultVacancyForm
                      selectiveProcessId={selectiveProcessId}
                      isCultureHouse={selectiveProcessData.data?.is_cultural_house || false}
                      hookForms={hookForms}
                      setModalityList={setModalityList}
                      setIsPCD={setIsPCD}
                      isPCD={isPCD}
                      modalityList={modalityList}
                      detailPcd={pcdDetails}
                    />
                  )}
                </Flex>
              </Stack>
            </AccordionTemplate>
            {selectiveProcessData.data?.firearms_act === 'yes' && (
              <AccordionTemplate
                border="1px solid"
                borderColor="primaryColor"
                borderRadius={6}
                title="ESTATUTO DO DESARMAMENTO"
              >
                <Flex flexDir={['column', 'column', 'row', 'row', 'row']}>
                  <Stack w="100%" py={3}>
                    <FormControl>
                      <Checkbox
                        isChecked={hookForms.watch('weapon_carry_permit')}
                        {...hookForms.register('weapon_carry_permit')}
                      >
                        <TextXS>
                          Declaro possuir a documentação de porte de armas e tenho a necessidade de fazer a prova
                          utilizando dessa condição.
                        </TextXS>
                      </Checkbox>
                    </FormControl>
                  </Stack>
                </Flex>
              </AccordionTemplate>
            )}

            {selectiveProcessData.data?.allows_special_condition && (
              <AccordionTemplate
                border="1px solid"
                borderColor="primaryColor"
                borderRadius={6}
                title="CONDIÇÕES ESPECIAIS PARA REALIZAÇÃO DA PROVA"
              >
                <Flex flexDir={['column', 'column', 'column', 'column', 'column']}>
                  <TextXS mb={2} textAlign="left">
                    Você deseja informar alguma condição especial para a realização da prova?
                  </TextXS>
                  <Stack align="center" w="100%">
                    <Grid gridTemplateColumns="repeat(3, 1fr)" w="100%">
                      <FormControl textAlign="center">
                        <Select {...hookForms.register('has_special_condition')}>
                          <option value="" hidden>
                            Selecione...
                          </option>
                          <option value="yes">Sim</option>
                          <option value="no">Não</option>
                        </Select>
                        {hookForms.formState.errors.has_special_condition && (
                          <MessageError>{hookForms.formState.errors.has_special_condition?.message}</MessageError>
                        )}
                      </FormControl>
                    </Grid>

                    {hookForms.watch('has_special_condition') === 'yes' && (
                      <CheckboxGroup>
                        <Stack w="100%" gap={0} direction={['column']}>
                          {(selectiveProcessData?.data?.special_condition &&
                            selectiveProcessData?.data?.special_condition.map((condition, index) => (
                              <Stack
                                key={condition.id}
                                p={hookForms.watch(`selectedSpecialCondition.${index}`) ? 4 : 1}
                                transition="0.4s"
                                bg={hookForms.watch(`selectedSpecialCondition.${index}`) ? 'lightGrey' : 'white'}
                              >
                                <label htmlFor={`${index}`}>
                                  <input
                                    id={`${index}`}
                                    style={{
                                      border: '2px solid #ff0000',
                                      width: '16px',
                                      height: '16px',
                                      marginRight: 7,
                                    }}
                                    type="checkbox"
                                    {...hookForms.register(`selectedSpecialCondition.${index}`)}
                                    value={condition.id}
                                  />
                                  {condition.name}
                                </label>

                                {hookForms.watch(`selectedSpecialCondition.${index}`) && (
                                  <Stack borderRadius={10}>
                                    <Textarea
                                      p={3}
                                      placeholder="Observações"
                                      {...hookForms.register(`observation.${index}`)}
                                      bg="white"
                                      h="100px"
                                    />
                                    {hookForms.formState.errors.observation && (
                                      <MessageError>
                                        {hookForms.formState?.errors?.observation[index]?.message?.toString()}
                                      </MessageError>
                                    )}
                                    {condition.has_attachment && (
                                      <Stack>
                                        <Flex align="center">
                                          <Text
                                            bg="primaryColor"
                                            color="white"
                                            w="fit-content"
                                            as="label"
                                            htmlFor={`file-${index}`}
                                            py={1.5}
                                            px={3}
                                            borderRadius={5}
                                            cursor="pointer"
                                          >
                                            Selecionar arquivo
                                          </Text>

                                          {renderFilename(index).map((filename, idx) => {
                                            return filename.length < 30 ? (
                                              <TextXS key={idx} ml={4}>
                                                {filename}
                                              </TextXS>
                                            ) : (
                                              <Tooltip key={idx} label={filename}>
                                                <TextXS
                                                  ml={4}
                                                >{`${filename.substring(0, 20)}...${filename.slice(-8)}`}</TextXS>
                                              </Tooltip>
                                            );
                                          })}

                                          <Input
                                            alignContent="center"
                                            {...hookForms.register(`attachmentFile.${index}`)}
                                            type="file"
                                            id={`file-${index}`}
                                            w="fit-content"
                                            pl={0}
                                            border="none"
                                            display="none"
                                            multiple
                                          />
                                          {hookForms.formState.errors.attachmentFile && (
                                            <MessageError ml={2}>
                                              {hookForms.formState?.errors?.attachmentFile[index]?.message?.toString()}
                                            </MessageError>
                                          )}
                                        </Flex>
                                        {registrationData.data?.special_conditions
                                          .filter((sp) => condition.id === sp.id)
                                          .map((attach) => (
                                            <Stack>
                                              <TableCustom headers={attachSpecialCondition}>
                                                {(attach.attachments?.length > 0 &&
                                                  attach.attachments?.map((files) => (
                                                    <Tr key={index} textAlign="center">
                                                      <Td textAlign="center">
                                                        <Link
                                                          fontSize="14px"
                                                          color="colorLink"
                                                          target="_blank"
                                                          href={files.url}
                                                        >
                                                          {files.name}
                                                        </Link>
                                                      </Td>
                                                      <Td textAlign="center">
                                                        <TextXS>{`${files.created_at}`}</TextXS>
                                                      </Td>
                                                      <Td textAlign="center">
                                                        <TextXS
                                                          cursor={'pointer'}
                                                          onClick={() =>
                                                            handleDeleteAttach(
                                                              files.id,
                                                              toast,
                                                              registrationData.refetch,
                                                            )
                                                          }
                                                          color="colorLink"
                                                        >
                                                          Excluir
                                                        </TextXS>
                                                      </Td>
                                                    </Tr>
                                                  ))) || (
                                                  <Tr>
                                                    <Td textAlign="center" colSpan={3}>
                                                      Nenhum arquivo enviado!
                                                    </Td>
                                                  </Tr>
                                                )}
                                              </TableCustom>
                                            </Stack>
                                          ))}
                                      </Stack>
                                    )}
                                  </Stack>
                                )}
                              </Stack>
                            ))) || <MessageError>Sem dados...</MessageError>}
                          <MessageError>
                            {hookForms.formState.errors.selectedSpecialCondition?.root?.message}
                          </MessageError>
                        </Stack>
                      </CheckboxGroup>
                    )}
                  </Stack>
                </Flex>
              </AccordionTemplate>
            )}
            {selectiveProcessData.data?.juror_under_article_440 === 'yes' && (
              <AccordionTemplate border="1px solid" borderColor="primaryColor" borderRadius={6} title="JURADO">
                <Flex flexDir={['column', 'column', 'row', 'row', 'row']}>
                  <Stack w="100%" py={3}>
                    <TextXS mb={2}>Já foi jurado nos termos do artigo 440 do código de processo penal</TextXS>
                    <Grid w="100%" gridTemplateColumns="repeat(3, 1fr)">
                      <FormControl textAlign="center">
                        <Select
                          value={hookForms.watch('juror_art_440_cpp')}
                          {...hookForms.register('juror_art_440_cpp')}
                        >
                          <option value="" hidden>
                            Selecione...
                          </option>
                          <option value="yes">Sim</option>
                          <option value="no">Não</option>
                        </Select>
                      </FormControl>
                    </Grid>
                    <MessageError>{hookForms.formState.errors.juror_art_440_cpp?.message}</MessageError>
                  </Stack>
                </Flex>
              </AccordionTemplate>
            )}
          </Accordion>
          <Stack flexDir="row" justify="flex-end" py={5}>
            <Button
              w={'100px'}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancelar
            </Button>
            {/* <Button
              isLoading={isPending || updateRegistration.isPending}
              type="submit"
              bg="colorLink"
              color="white"
              w={'100px'}
              _hover={'none'}
            >
              {'Continuar'}
            </Button> */}
          </Stack>
        </Stack>
      </Flex>
    </MainCard>
  );
};

export default SelectiveProcessSubscribe;
