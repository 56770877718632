import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';
import { IDateType } from '../Types';

interface IMoreDetailResource {
  id: string;
  resource_code: number;
  selective_process: ISelectiveProcess;
  created_at: IDateType;
  observation: string;
  status: IStatus;
  justification: string;
  attachments: {
    resource_file: IResourceFile[];
  };
  vacancy: IVacancyProps;
  question: string;
}

interface IStatus {
  id: string;
  name: string;
}

interface IVacancyProps {
  id: string;
  selective_process: ISelectiveProcess;
}

interface ISelectiveProcess {
  id: string;
  name: string;
  registrations_start: IDateType;
  registrations_end: IDateType;
  exemption_start: IDateType;
  exemption_end: IDateType;
  number_of_vacancies: number;
  public_notice_number: string;
  selective_process_situation: Iselective_process_situation[];
}

interface Iselective_process_situation {
  id: string;
  name: string;
}

interface IResourceFile {
  name: string;
  url: string;
  sent_by_client: boolean;
  created_at: IDateType;
}

const RequestMoreDetailResources = async (resourceId: string) => {
  const response = await configApi.get<IMoreDetailResource>(`resources/${resourceId}/`);
  return response.data;
};

export const useRequestMoreDetailResources = (resourceId: string) => {
  return useQuery({
    queryKey: ['moreDetailResource', resourceId],
    queryFn: () => RequestMoreDetailResources(resourceId),
    refetchOnWindowFocus: false,
    gcTime: 0,
  });
};
