import { Text, TextProps } from '@chakra-ui/react';

interface IOwnProps extends TextProps {
  children?: string;
}

const MessageError: React.FC<IOwnProps> = ({ children, ...rest }) => {
  return (
    <Text {...rest} mt={1} textAlign="left" color="red">
      {children}
    </Text>
  );
};

export default MessageError;
