import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Divider, Image, Stack, Input, Button, useToast, Spinner } from '@chakra-ui/react';
import { FaImage } from 'react-icons/fa';
import MainCard from '../../../components/MainCard/Index';
// import orientacoesFoto from '../../../assets/orientacoes_foto.svg';
import Cropper, { Area, Point } from 'react-easy-crop';
import getCroppedImg from './getImageCrop';
import { useLocation, useNavigate } from 'react-router-dom';
import { FieldValues } from 'react-hook-form';
import { useCreateCandidate } from '../../../services/hooks/SignUp/useCreateCandidate';
import { ContextAuth } from '../../../contexts/Authentication';
import { useUpdateProfilePicture } from '../../../services/hooks/Profile/useUpdateProfilePicture';
import { AxiosError } from 'axios';
import { TextXS } from '../../../components/Text/Index';

interface IReturnCroppedImage {
  base64: string;
  file?: File;
}

interface INavigationProps {
  route: string;
  payload: FieldValues;
  selectiveProcessId: string;
}

const ProfilePicture: React.FC = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [finalImage, setFinalImage] = useState<IReturnCroppedImage>();
  const [croppedImage, setCroppedImage] = useState<Area>();
  const [showCrop, setShowCrop] = useState<boolean>(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { signIn } = useContext(ContextAuth);
  const updatePicture = useUpdateProfilePicture();

  const navigationProps = useLocation().state as INavigationProps;
  const { mutateAsync, isPending } = useCreateCandidate();

  const { candidate, isAuth, setCandidate } = useContext(ContextAuth);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setFinalImage({
        base64: imageUrl,
        file: undefined,
      });
      setShowCrop(true);
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);

  const onCropComplete = (_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedImage(croppedAreaPixels);
  };

  const handleSaveImage = async () => {
    try {
      if (finalImage && croppedImage) {
        const croppedImg = (await getCroppedImg(finalImage.base64, croppedImage, rotation)) as IReturnCroppedImage;

        setFinalImage(croppedImg);
        setShowCrop(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (navigationProps?.payload) {
      const newObject = {
        ...navigationProps.payload,
        avatar: finalImage?.file,
      };
      mutateAsync(newObject)
        .then(() => {
          signIn(navigationProps.payload.email, navigationProps.payload.password).then(() => {
            toast({
              title: 'Sucesso',
              description: 'Cadastro realizado com sucesso',
              status: 'success',
              duration: 4000,
              isClosable: true,
            });
            if (navigationProps.selectiveProcessId) {
              navigate(navigationProps.route || '/login', { state: navigationProps.selectiveProcessId });
            } else {
              navigate(navigationProps.route || '/login');
            }
          });
        })
        .catch((e) => {
          toast({
            title: 'Erro',
            description: e.response.data.message || 'Erro na solicitação, tente novamente',
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        });
    } else if (isAuth && candidate) {
      updatePicture
        .mutateAsync({ avatar: finalImage?.file, candidateId: candidate?.id })
        .then(() => {
          toast({
            title: 'Sucesso',
            description: 'Imagem alterada com sucesso',
            status: 'success',
            duration: 4000,
            isClosable: true,
          });

          setCandidate((value) => {
            if (value) {
              return { ...value, user: { ...value.user, avatar: finalImage?.base64 } };
            }

            return value;
          });
          navigate(-1);
        })
        .catch((e: AxiosError<{ message: string }>) => {
          toast({
            title: 'Erro',
            description: e?.response?.data?.message || 'Erro na solicitação, tente novamente',
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        });
    }
  };

  useEffect(() => {
    if (!isAuth && !navigationProps?.payload) {
      navigate(-1);
    }
  }, []);

  return (
    <MainCard title="INSTRUÇÕES PARA FOTO DO CANDIDATO">
      <Stack px={['10px', '10px', '10px', '50px', '50px']} fontSize="18px">
        <TextXS>
          1. A foto deve ser nítida, preferencialmente em fundo branco e deve possuir uma luminosidade adequada para a
          visualização do seu rosto em detalhes.
        </TextXS>

        <TextXS>
          2. Certifique-se de que o seu rosto não esteja coberto com óculos escuros, máscara ou qualquer outro objeto
          que dificulte sua identificação facial.
        </TextXS>

        <Stack w={['100%', '100%', '100%', '80%', '80%']} mt={5}>
          {/* <Image src={orientacoesFoto} /> */}
        </Stack>
      </Stack>
      <Stack m={0} p={0} align="center">
        <Divider w="95%" border="1px solid darkGrey" />
      </Stack>
      <Stack as="form" onSubmit={handleSubmit} mt={10} mb={5} align="center" justify="center">
        <Stack align={'center'} w="fit-content" minW="30%">
          <Box textAlign="center" maxW="350px" mb={5}>
            <TextXS>Enquadre seu rosto no quadro abaixo.</TextXS>
          </Box>

          <Stack
            w="100%"
            maxW="350px"
            h={showCrop ? '600px' : '450px'}
            border="2px dashed gray"
            borderRadius="md"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            onClick={handleUploadClick}
            position="relative"
          >
            {(showCrop && (
              <Stack w="100%" h="100%">
                <Stack flex={1}>
                  <Box position="absolute" top={0} left={0} right={0} bottom={210}>
                    <Box position="absolute" top={0} left={0} right={0} bottom={0}>
                      <Cropper
                        crop={crop}
                        zoom={zoom}
                        rotation={rotation}
                        cropShape="round"
                        aspect={1 / 1}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                        image={finalImage?.base64}
                      />
                    </Box>
                  </Box>
                </Stack>
                <Stack bg={''} px={5} py={3}>
                  <Stack>
                    <TextXS>Zoom</TextXS>
                    <Input
                      margin={0}
                      p={0}
                      className="range"
                      type="range"
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      h="2px"
                      borderColor="colorLink"
                      value={zoom}
                      onChange={(e) => {
                        setZoom(parseFloat(e.target.value));
                      }}
                    />
                  </Stack>
                  <Stack>
                    <TextXS>Girar Imagem</TextXS>
                    <Input
                      margin={0}
                      p={0}
                      className="range"
                      type="range"
                      min={0}
                      max={360}
                      step={10}
                      h="2px"
                      borderColor="colorLink"
                      value={rotation}
                      onChange={(e) => {
                        setRotation(parseFloat(e.target.value));
                      }}
                    />
                  </Stack>
                  <Button onClick={handleSaveImage} mt={5} bg="primaryColor" color="white">
                    Salvar Imagem
                  </Button>
                  <Button
                    onClick={() => setShowCrop(false)}
                    borderColor="primaryColor"
                    border="1px solid"
                    bg="transparent"
                  >
                    Cancelar
                  </Button>
                </Stack>
              </Stack>
            )) ||
              (finalImage?.file && (
                <>
                  <Image h="100%" w="100%" objectFit="cover" src={finalImage.base64} />
                  <Input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileUpload} />
                </>
              )) || (
                <Stack align="center">
                  <FaImage size={'50px'} fill="#808080" />
                  <TextXS>Envie sua imagem</TextXS>
                  <Input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileUpload} />
                </Stack>
              )}
          </Stack>
          {!showCrop && (
            <Stack py={10} w="100%" align={'center'}>
              <Button isDisabled={isPending || updatePicture.isPending} w="100%" bg="green" color="white" type="submit">
                {isPending || updatePicture.isPending ? <Spinner /> : 'Finalizar'}
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    </MainCard>
  );
};

export default ProfilePicture;
