import {
  Accordion,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Link,
  Select,
  Spinner,
  Stack,
  Td,
  Text,
  Textarea,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import MainCard from '../../../../../components/MainCard/Index';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { ContextAuth } from '../../../../../contexts/Authentication';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AccordionTemplate from '../../../../../components/AccordionTemplate/Index';
import {
  ISelectionItem,
  useRequestGetDataToRegistration,
} from '../../../../../services/hooks/CandidateArea/useGetDataToRegistration';
import { FieldValues, useForm } from 'react-hook-form';
import { validateMaxFile, validateTypeFile, validationSizeFile } from '../../../../../utils/validationForm';
import MessageError from '../../../../../components/MessageError';
import { useCreateRegistration } from '../../../../../services/hooks/Registration/usePostRegistration';
import VacancyCutureHome from '../../../../../components/pages/Registration/CultureHousesVacancy';
import DefaultVacancyForm, { ETypeOfCompetition } from '../../../../../components/pages/Registration/DefaultVacancy';
import { TextL, TextXS, TextXXS } from '../../../../../components/Text/Index';
import { useRequestSelectiveProcessVacancy } from '../../../../../services/hooks/SelectiveProcess/useRequestSelectiveProcessVacancy';
import { useRequestGetExemptionType } from '../../../../../services/hooks/Exemptions/useRequestGetExemptionType';
import MarrowDonnor from '../../../Exemptions/Forms/MarrowDonnor/Index';
import NIS from '../../../Exemptions/Forms/NIS/Index';
import { useGetRegistration } from '../../../../../services/hooks/Registration/useGetRegistration';
import TableCustom from '../../../../../components/pages/CandidateArea/Table';
import { handleDeleteAttach } from '../../../../../utils/functions';

const SubscriptonAndExemption: React.FC = () => {
  const navigate = useNavigate();
  const { signOut, candidate } = useContext(ContextAuth);

  const [modalityList, setModalityList] = useState<ISelectionItem[]>();
  const [isPCD, setIsPCD] = useState<boolean>(false);
  const toast = useToast();
  const getExemption = useRequestGetExemptionType();
  const url = useLocation().pathname.split('/')[2];

  const registrationId = useLocation().pathname.split('/')[5];
  const registration = useGetRegistration(registrationId);

  const attachSpecialCondition = ['Arquivo', 'Data de envio', 'Ações'];

  const isValidUUID = (uuid: string) => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(uuid);
  };

  const selectiveProcessId = isValidUUID(url) ? url : '';

  const selectiveProcessData = useRequestGetDataToRegistration(selectiveProcessId);
  const vacancy = useRequestSelectiveProcessVacancy(
    selectiveProcessId,
    selectiveProcessData.data?.is_cultural_house || false,
  );

  const { mutateAsync, isPending } = useCreateRegistration();

  const schema = Yup.object().shape({
    type_of_competition: Yup.string().when({
      is: () => !selectiveProcessData.data?.is_cultural_house,
      then: (schema) => schema.required('Campo Obrigatório'),
    }),
    vacancy: Yup.string().when({
      is: () => !selectiveProcessData.data?.is_cultural_house || selectiveProcessData.data?.is_cultural_house,
      then: (schema) => schema.required('Campo Obrigatório'),
    }),
    culture_home: Yup.string().when({
      is: () => selectiveProcessData.data?.is_cultural_house,
      then: (schema) => schema.required('Campo Obrigatório'),
    }),
    // vagancy_type: Yup.string().when(['culture_home'], {
    //   is: (culture_home: string) => !!culture_home,
    //   then: (schema) => schema.required('O Campo é obrigatorio'),
    // }),
    // initial_semester: Yup.string().when(['vagancy_type'], {
    //   is: (vagancy_type: string) => vagancy_type === 'is_initial_semester',
    //   then: (schema) => schema.required('O Campo é obrigatorio'),
    // }),
    // nivel_test: Yup.string().when(['vagancy_type'], {
    //   is: (vagancy_type: string) => vagancy_type === 'is_level_test',
    //   then: (schema) => schema.required('O Campo é obrigatorio'),
    // }),
    culture_home_secondary: Yup.string().when(['has_second_vacancy'], {
      is: (hasSecondVacancy: boolean) => hasSecondVacancy,
      then: (schema) => schema.required('O Campo é obrigatorio'),
    }),
    initial_semester_secondary: Yup.string().when(['has_second_vacancy'], {
      is: (hasSecondVacancy: boolean) => hasSecondVacancy,
      then: (schema) =>
        schema
          .required('O Campo é obrigatorio')
          .test('Campos iguais', 'Atenção! A sua segunda opção de turma deve ser diferente da primeira.', () => {
            if (hookForms.watch('vacancy') === hookForms.watch('initial_semester_secondary')) {
              toast({
                title: 'Atenção',
                description: 'Atenção! A sua segunda opção de turma deve ser diferente da primeira.',
                status: 'error',
                duration: 4000,
                isClosable: true,
              });
              return false;
            }
            return true;
          }),
    }),
    juror_art_440_cpp: Yup.string().test('isRequired', 'O campo é obrigatorio', (value) => {
      if (selectiveProcessData.data?.juror_under_article_440 && value?.length == 0) return false;
      return true;
    }),
    weapon_carry_permit: Yup.boolean(),
    medical_report: Yup.mixed()
      .test('isRequired', 'Envie o seu laudo para prosseguir na vaga destinada a PCD.', (file) => {
        if (!isPCD || hookForms.getValues('type_of_competition') !== ETypeOfCompetition.SPECIAL_VACANCY) return true;
        const listFile = file as FileList;
        return listFile?.length > 0;
      })
      .test('validationSizeFile', 'Tamanho máximo para arquivos é de 2MB', (file) => {
        if (!isPCD || hookForms.getValues('type_of_competition') !== ETypeOfCompetition.SPECIAL_VACANCY) return true;
        const listFile = file as FileList;

        if (listFile?.length > 0) {
          return validationSizeFile(listFile);
        }

        return false;
      })
      .test('validationMaxFile', 'Quantidade máxima de arquivos é de 5 arquivos', (file) => {
        if (!isPCD || hookForms.getValues('type_of_competition') !== ETypeOfCompetition.SPECIAL_VACANCY) return true;
        const listFile = file as FileList;

        if (listFile?.length > 0) {
          return validateMaxFile(listFile);
        }
      })
      .test('validationTypeFile', 'Formato de arquivo não suportado', (file) => {
        if (!isPCD || hookForms.getValues('type_of_competition') !== ETypeOfCompetition.SPECIAL_VACANCY) return true;
        const listFile = file as FileList;

        if (listFile?.length > 0) {
          return validateTypeFile(listFile);
        }
      }),
    cid: Yup.string(),
    deficiency: Yup.string(),
    has_special_condition: Yup.string().when({
      is: () => selectiveProcessData.data?.allows_special_condition,
      then: (schema) => schema.required('O campo é obrigatorio'),
    }),
    modality_type: Yup.array().test('isRequired', 'Selecione ao menos uma modalidade!', (listModality) => {
      if (selectiveProcessData.data?.is_cultural_house) return true;

      if (
        !modalityList ||
        hookForms.getValues('type_of_competition') !== ETypeOfCompetition.SPECIAL_VACANCY ||
        (modalityList && modalityList.length === 0)
      )
        return true;

      let isEmpty = false;

      listModality?.forEach((modality) => {
        if (modality !== false) isEmpty = true;
      });

      return isEmpty;
    }),
    has_second_vacancy: Yup.boolean(),
    attachmentFile: Yup.array(),
    observation: Yup.array(),
    selectedSpecialCondition: Yup.array()
      .test('isRequired', 'Selecione ao menos uma condição especial', (specialConditionList) => {
        let isEmpty = false;

        if (!(hookForms.getValues('has_special_condition') === 'yes')) return true;

        specialConditionList?.forEach((modality) => {
          if (modality !== false) {
            isEmpty = true;
            return;
          }
        });

        return isEmpty;
      })
      .test('isRequired', '', (specialConditionList) => {
        if (!(hookForms.getValues('has_special_condition') === 'yes')) return true;

        const validationError: Yup.ValidationError[] = [];

        specialConditionList?.forEach((modality, index) => {
          if (modality !== false) {
            const observation: string = hookForms.getValues(`observation.${index}`);
            if (observation?.length === 0) {
              validationError.push(
                new Yup.ValidationError('Campo Obrigatorio', specialConditionList[index], `observation.${index}`),
              );
            }
          }
        });
        if (validationError.length > 0) {
          throw new Yup.ValidationError(validationError);
        }

        return true;
      }),
    type_exemption: Yup.string().test('required', 'Campo obrigatorio', (value) => !!value),
    document_number: Yup.string().test('required', 'Campo obrigatorio', (value) => !!value),
    document_issuing_body: Yup.string().test('required', 'Campo obrigatorio', (value) => !!value),
    document_issuing_body_uf: Yup.string().test('required', 'Campo obrigatorio', (value) => !!value),
    nis: Yup.string().test('required', 'Campo obrigatorio', (value) => !!value),
    mother_name: Yup.string().test('required', 'Campo obrigatorio', (value) => !!value),
  });

  let hasFullVacancy = (registration.data && ETypeOfCompetition.SPECIAL_VACANCY) || '';

  registration.data?.type_of_competition?.forEach((item) => {
    if (item.name.toLowerCase().includes('ampla')) {
      hasFullVacancy = ETypeOfCompetition.FULL_VACANCY;
    }
  });

  const listChecked: (string | boolean)[] = [];
  const listObservation: (string | boolean | undefined)[] = [];

  selectiveProcessData.data?.special_condition.map((s) => {
    let isSelected = false;
    let obs = '';

    registration.data?.special_conditions.forEach((r) => {
      if (r.id === s.id) {
        isSelected = true;
        obs = r.observations;
      }
    });

    if (isSelected) {
      listChecked.push(s.id);
      listObservation.push(obs);
    } else {
      listChecked.push(false);
      listObservation.push('');
    }
  });

  const hookForms = useForm({
    resolver: yupResolver(schema),
    values: {
      vacancy: registration.data?.selective_process_vacancy.id,
      type_of_competition: hasFullVacancy,
      has_special_condition:
        registration.data?.special_conditions && registration.data.special_conditions.length > 0 ? 'yes' : 'no',
      type_exemption: JSON.stringify(registration.data?.fcpc_exemption.type),
      document_issuing_body: registration.data?.fcpc_exemption?.document_issuing_body,
      document_issuing_body_uf: registration.data?.fcpc_exemption?.document_issuing_body_uf?.id,
      document_number: registration.data?.fcpc_exemption?.document_number,
      mother_name: registration.data?.fcpc_exemption?.mother_name,
      nis: registration.data?.fcpc_exemption?.nis,
      culture_home: registration.data?.selective_process_vacancy.cultural_house,
      has_second_vacancy: registration.data?.second_vacancy ? true : false,
      culture_home_secondary: registration.data?.second_vacancy?.cultural_house,
      weapon_carry_permit: registration.data?.has_gun_license,
      initial_semester_secondary: registration.data?.second_vacancy?.id,
      juror_art_440_cpp: registration.data?.juror_art_440_cpp ? 'yes' : 'no',
      selectedSpecialCondition: listChecked,
      observation: listObservation,
    },
  });

  const newRegistration = (data: FieldValues) => {
    if (data.type_of_competition === ETypeOfCompetition.FULL_VACANCY) {
      modalityList?.forEach((type) => {
        if (type.name.toLowerCase().includes('ampla')) {
          data = { ...data, type_of_competition: type.id, is_all: true };
        }
      });
    }
    if (selectiveProcessData.data) {
      mutateAsync({
        data,
        selectiveProcess: selectiveProcessData.data,
        listTypeCompetition: vacancy.data,
        registrationId: registrationId,
      })
        .then((response) => {
          if (registrationId) {
            toast({
              title: 'Sucesso',
              description: 'Isenção Editada com sucesso',
              status: 'success',
              duration: 4000,
              isClosable: true,
            });
            navigate(-1);
            return;
          }

          toast({
            title: 'Sucesso',
            description: 'Isenção criada com sucesso!',
            status: 'success',
            duration: 4000,
            isClosable: true,
          });
          navigate('/processo-seletivo/isencao/resumo', {
            state: { registrationId: response?.id, previousRoute: '/processo-seletivo/inscricao/isencao' },
          });
        })
        .catch((error) => {
          toast({
            title: 'Erro',
            description: error.response?.data.message || 'Não foi possivel realizar a Isenção.',
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        });
    }
  };

  const renderFilename = (idx: number) => {
    const files: FileList = hookForms.watch(`attachmentFile.${idx}`);
    const listFileName: string[] = [];

    if (!files || files.length === 0) {
      return ['Envie seus arquivos'];
    } else {
      for (let index = 0; index < files.length; index++) {
        listFileName.push(files[index]?.name || 'Sem_nome');
      }
    }

    return listFileName;
  };

  if (!selectiveProcessId) navigate(-1);

  const navProps = {
    registrationId: '',
  };

  const handleShowForm = (): ReactNode => {
    const form = hookForms.watch('type_exemption');

    if (form) {
      const formToShow = JSON.parse(form);

      if (formToShow?.name?.toLowerCase().includes('doador')) {
        return (
          <MarrowDonnor
            // onSetValue={hookForms.setValue}
            exemptionId={formToShow?.id}
            registrationId={navProps?.registrationId || ''}
          />
        );
      } else {
        return (
          <NIS
            hookForms={hookForms}
            hideButton
            // onSetValue={hookForms.setValue}
            exemptionId={formToShow.id}
            registrationId={navProps?.registrationId || ''}
          />
        );
      }
    }

    return false;
  };

  useEffect(() => {
    if (registrationId && registration.data) {
      const list = vacancy.data || [];
      const vacancyId = registration.data.selective_process_vacancy.id;
      list.forEach((vacancy) => {
        if (vacancy.id === vacancyId) {
          setModalityList(vacancy.type_of_competition);
        }
      });
    }
  }, [registration.data]);

  const isLoading = registration.isLoading;

  return (
    <MainCard title="PROCESSO SELETIVO - " subtitle="FORMULÁRIO DE SOLICITAÇÃO DE ISENÇÃO">
      {(isLoading && (
        <Stack flex={1} justify="center" align="center">
          <Spinner />
        </Stack>
      )) || (
        <Flex
          as="form"
          onSubmit={hookForms.handleSubmit(newRegistration)}
          flexDir="column"
          px={['10px', '10px', '10px', '50px', '50px']}
          gap={10}
        >
          <Flex
            justifyContent="space-between"
            alignItems="left"
            flexDir={['column', 'column', 'column', 'row', 'row']}
            overflow="hidden"
          >
            <Stack color="darkGrey">
              <TextXS>{selectiveProcessData.data?.solective_process_type_name || ''}</TextXS>
              <TextL>{selectiveProcessData.data?.name || ''}</TextL>
            </Stack>
          </Flex>
          <Stack>
            <Accordion display="flex" flexDir="column" gap={2} allowMultiple defaultIndex={[0, 1, 2, 3, 4]}>
              <AccordionTemplate title="DADOS PESSOAIS" border="1px solid" borderColor="primaryColor" borderRadius={6}>
                <Flex px={10} mb={5} flexDir={['column', 'column', 'row', 'row', 'row']}>
                  <Stack w="100%" p={3}>
                    <TextXS>
                      <strong>Nome Completo:</strong> {candidate?.user?.full_name || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>CPF:</strong> {candidate?.cpf || '--'} <br />
                    </TextXS>
                    <TextXXS ml="30px" as={'span'}>
                      Não é seu CPF?{' '}
                      <Link
                        onClick={() => {
                          signOut();
                          navigate('/');
                        }}
                        as={'span'}
                        color="colorLink"
                      >
                        clique aqui
                      </Link>
                    </TextXXS>
                    <TextXS>
                      <strong>Data de Nascimento:</strong> {candidate?.date_of_birth.br || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>E-mail:</strong> {candidate?.user?.email || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>{candidate?.document_type.name || '--'}</strong>
                      {': '}
                      {candidate?.document_number || '--'} {candidate?.document_issuing_body}{' '}
                      {candidate?.document_issuing_body_uf?.initials || ''}
                    </TextXS>
                    <TextXS>
                      <strong>Sexo:</strong> {candidate?.gender?.name || '--'}
                    </TextXS>
                  </Stack>
                  <Stack w="100%" p={3}>
                    <TextXS>
                      <strong>CEP:</strong> {candidate?.address_zip_code || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>Endereço:</strong> {candidate?.address_street || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>Número:</strong> {candidate?.address_number || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>Complemento: </strong>
                      {candidate?.address_complement || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>Bairro:</strong> {candidate?.address_neighborhood || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>Cidade: </strong>
                      {candidate?.address_city?.name || '--'} / {candidate?.address_state?.initials || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>Telefone:</strong> {candidate?.phone || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>Celular:</strong> {candidate?.cellphone || '--'}
                    </TextXS>
                  </Stack>
                </Flex>
                <Stack p={2}>
                  <TextXS>
                    <strong>ATENÇÃO: </strong>Os dados acima estão incorretos? caso estejam,{' '}
                    <Link
                      onClick={() => {
                        navigate('/painel/meus-dados');
                      }}
                      color="colorLink"
                    >
                      clique aqui
                    </Link>{' '}
                    e atualize seu cadastro.
                  </TextXS>
                </Stack>
              </AccordionTemplate>
              <AccordionTemplate
                title={selectiveProcessData.data?.is_cultural_house ? 'TURMA' : 'VAGA / CARGO DESEJADA'}
                border="1px solid"
                borderColor="primaryColor"
                borderRadius={6}
              >
                <Stack>
                  <Flex
                    display={['flex', 'flex', 'flex', 'grid', 'grid']}
                    gridTemplateColumns={['', '', '', 'repeat(3, 1fr)', 'repeat(3, 1fr)']}
                    justify="center"
                    flexDir={['column', 'column', 'column', 'row', 'row']}
                    w="100%"
                    py={3}
                    gap={3}
                  >
                    {(selectiveProcessData.data?.is_cultural_house && (
                      <VacancyCutureHome
                        selectiveProcessId={selectiveProcessId}
                        culture_houses={selectiveProcessData.data.cultural_houses}
                        isCultureHouse={selectiveProcessData.data.is_cultural_house}
                        hookForms={hookForms}
                        allow_second_registration={selectiveProcessData.data.allow_second_registration}
                      />
                    )) || (
                      <DefaultVacancyForm
                        selectiveProcessId={selectiveProcessId}
                        isCultureHouse={selectiveProcessData.data?.is_cultural_house || false}
                        hookForms={hookForms}
                        setModalityList={setModalityList}
                        setIsPCD={setIsPCD}
                        isPCD={isPCD}
                        modalityList={modalityList}
                      />
                    )}
                  </Flex>
                </Stack>
              </AccordionTemplate>
              {selectiveProcessData.data?.firearms_act === 'yes' && (
                <AccordionTemplate
                  border="1px solid"
                  borderColor="primaryColor"
                  borderRadius={6}
                  title="ESTATUTO DO DESARMAMENTO"
                >
                  <Flex flexDir={['column', 'column', 'row', 'row', 'row']}>
                    <Stack w="100%" py={3}>
                      <FormControl>
                        <Checkbox {...hookForms.register('weapon_carry_permit')}>
                          <TextXS>
                            Declaro possuir a documentação de porte de armas e tenho a necessidade de fazer a prova
                            utilizando dessa condição.
                          </TextXS>
                        </Checkbox>
                      </FormControl>
                    </Stack>
                  </Flex>
                </AccordionTemplate>
              )}
              {selectiveProcessData.data?.allows_special_condition && (
                <AccordionTemplate
                  border="1px solid"
                  borderColor="primaryColor"
                  borderRadius={6}
                  title="CONDIÇÕES ESPECIAIS PARA REALIZAÇÃO DA PROVA"
                >
                  <Flex flexDir={['column', 'column', 'column', 'column', 'column']}>
                    <TextXS mb={2} textAlign="left">
                      Você deseja informar alguma condição especial para a realização da prova?
                    </TextXS>
                    <Stack align="center" w="100%">
                      <Grid gridTemplateColumns="repeat(3, 1fr)" w="100%">
                        <FormControl textAlign="center">
                          <Select {...hookForms.register('has_special_condition')}>
                            <option value="" hidden>
                              Selecione...
                            </option>
                            <option value="yes">Sim</option>
                            <option value="no">Não</option>
                          </Select>
                          {hookForms.formState.errors.has_special_condition && (
                            <MessageError>{hookForms.formState.errors.has_special_condition?.message}</MessageError>
                          )}
                        </FormControl>
                      </Grid>

                      {hookForms.watch('has_special_condition') === 'yes' && (
                        <CheckboxGroup>
                          <Stack w="100%" gap={0} direction={['column']}>
                            {(selectiveProcessData?.data?.special_condition &&
                              selectiveProcessData?.data?.special_condition.map((condition, index) => (
                                <Stack
                                  key={condition.id}
                                  p={hookForms.watch(`selectedSpecialCondition.${index}`) ? 4 : 1}
                                  transition="0.4s"
                                  bg={hookForms.watch(`selectedSpecialCondition.${index}`) ? 'lightGrey' : 'white'}
                                >
                                  <label htmlFor={`${index}`}>
                                    <input
                                      id={`${index}`}
                                      style={{
                                        border: '2px solid #ff0000',
                                        width: '16px',
                                        height: '16px',
                                        marginRight: 7,
                                      }}
                                      type="checkbox"
                                      {...hookForms.register(`selectedSpecialCondition.${index}`)}
                                      value={condition.id}
                                    />
                                    {condition.name}
                                  </label>

                                  {hookForms.watch(`selectedSpecialCondition.${index}`) && (
                                    <Stack borderRadius={10}>
                                      <Textarea
                                        p={3}
                                        placeholder="Observações"
                                        {...hookForms.register(`observation.${index}`)}
                                        bg="white"
                                        h="100px"
                                      />
                                      {hookForms.formState.errors.observation && (
                                        <MessageError>
                                          {hookForms.formState?.errors?.observation[index]?.message?.toString()}
                                        </MessageError>
                                      )}
                                      {condition.has_attachment && (
                                        <Stack>
                                          <Flex align="center">
                                            <Text
                                              bg="primaryColor"
                                              color="white"
                                              w="fit-content"
                                              as="label"
                                              htmlFor={`file-${index}`}
                                              py={1.5}
                                              px={3}
                                              borderRadius={5}
                                              cursor="pointer"
                                            >
                                              Selecionar arquivo
                                            </Text>

                                            {renderFilename(index).map((filename, idx) => {
                                              return filename.length < 30 ? (
                                                <TextXS key={idx} ml={4}>
                                                  {filename}
                                                </TextXS>
                                              ) : (
                                                <Tooltip key={idx} label={filename}>
                                                  <TextXS
                                                    ml={4}
                                                  >{`${filename.substring(0, 20)}...${filename.slice(-8)}`}</TextXS>
                                                </Tooltip>
                                              );
                                            })}

                                            <Input
                                              alignContent="center"
                                              {...hookForms.register(`attachmentFile.${index}`)}
                                              type="file"
                                              id={`file-${index}`}
                                              w="fit-content"
                                              pl={0}
                                              border="none"
                                              display="none"
                                            />
                                            {hookForms.formState.errors.attachmentFile && (
                                              <MessageError>
                                                {hookForms.formState?.errors?.attachmentFile[
                                                  index
                                                ]?.message?.toString()}
                                              </MessageError>
                                            )}
                                          </Flex>
                                          {registration.data?.special_conditions
                                            .filter((sp) => condition.id === sp.id)
                                            .map((attach) => (
                                              <Stack>
                                                <TableCustom headers={attachSpecialCondition}>
                                                  {(attach.attachments?.length > 0 &&
                                                    attach.attachments?.map((files) => (
                                                      <Tr key={index} textAlign="center">
                                                        <Td textAlign="center">
                                                          <Link
                                                            fontSize="14px"
                                                            color="colorLink"
                                                            target="_blank"
                                                            href={files.url}
                                                          >
                                                            {files.name}
                                                          </Link>
                                                        </Td>
                                                        <Td textAlign="center">
                                                          <TextXS>{`${files.created_at}`}</TextXS>
                                                        </Td>
                                                        <Td textAlign="center">
                                                          <TextXS
                                                            cursor={'pointer'}
                                                            onClick={() =>
                                                              handleDeleteAttach(files.id, toast, registration.refetch)
                                                            }
                                                            color="colorLink"
                                                          >
                                                            Excluir
                                                          </TextXS>
                                                        </Td>
                                                      </Tr>
                                                    ))) || (
                                                    <Tr>
                                                      <Td textAlign="center" colSpan={3}>
                                                        Nenhum arquivo enviado!
                                                      </Td>
                                                    </Tr>
                                                  )}
                                                </TableCustom>
                                              </Stack>
                                            ))}
                                        </Stack>
                                      )}
                                    </Stack>
                                  )}
                                </Stack>
                              ))) || <MessageError>Sem dados...</MessageError>}
                            <MessageError>
                              {hookForms.formState.errors.selectedSpecialCondition?.root?.message}
                            </MessageError>
                          </Stack>
                        </CheckboxGroup>
                      )}
                    </Stack>
                  </Flex>
                </AccordionTemplate>
              )}
              {selectiveProcessData.data?.juror_under_article_440 === 'yes' && (
                <AccordionTemplate border="1px solid" borderColor="primaryColor" borderRadius={6} title="JURADO">
                  <Flex flexDir={['column', 'column', 'row', 'row', 'row']}>
                    <Stack w="100%" py={3}>
                      <TextXS mb={2}>Já foi jurado nos termos do artigo 440 do código de processo penal</TextXS>
                      <Grid w="100%" gridTemplateColumns="repeat(3, 1fr)">
                        <FormControl textAlign="center">
                          <Select {...hookForms.register('juror_art_440_cpp')}>
                            <option value="" hidden>
                              Selecione...
                            </option>
                            <option value="yes">Sim</option>
                            <option value="no">Não</option>
                          </Select>
                        </FormControl>
                      </Grid>
                      <MessageError>{hookForms.formState.errors.juror_art_440_cpp?.message}</MessageError>
                    </Stack>
                  </Flex>
                </AccordionTemplate>
              )}

              <AccordionTemplate
                border="1px solid"
                borderColor="primaryColor"
                borderRadius={6}
                title="DADOS DA SOLICITAÇÃO DE ISENÇÃO"
              >
                <Stack py={10}>
                  <FormControl>
                    <FormLabel>Tipo de Solicitação de Isenção</FormLabel>
                    <Select {...hookForms.register('type_exemption')} w="100%" maxW="350px">
                      <option hidden value="">
                        Selecione
                      </option>
                      {getExemption?.data?.map((exemption, index) => (
                        <option key={index} value={JSON.stringify(exemption)}>
                          {exemption?.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <MessageError>{hookForms.formState.errors.type_exemption?.message}</MessageError>
                  {handleShowForm()}
                </Stack>
              </AccordionTemplate>
            </Accordion>
            <Stack flexDir="row" justify="flex-end" py={5}>
              <Button
                w={'100px'}
                onClick={() => {
                  navigate('/');
                }}
              >
                Cancelar
              </Button>
              <Button isDisabled={isPending} type="submit" bg="colorLink" color="white" w={'100px'} _hover={'none'}>
                {(isPending && <Spinner />) || (registrationId && 'Salvar') || 'Continuar'}
              </Button>
            </Stack>
          </Stack>
        </Flex>
      )}
    </MainCard>
  );
};

export default SubscriptonAndExemption;
