import { Button, Flex, FormControl, Spinner, Stack, Textarea, useToast } from '@chakra-ui/react';
import MainCard from '../../../components/MainCard/Index';
import * as Yup from 'yup';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { usecreateImpugnation } from '../../../services/hooks/Impugnation/useCreateImpugnation';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { ContextAuth } from '../../../contexts/Authentication';
import { TextXS } from '../../../components/Text/Index';
import { useRequestDetailSelectiveProcess } from '../../../services/hooks/SelectiveProcess/useRequestDetailSelectiveProcess';

const ImpugnationPage: React.FC = () => {
  const selectiveProcessId = useLocation().pathname.split('/')[2];
  const { data } = useRequestDetailSelectiveProcess(selectiveProcessId || '');
  const impugnationField = usecreateImpugnation(data?.id || '');
  const { candidate } = useContext(ContextAuth);
  const toast = useToast();
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    objection: Yup.string().required('Campo obrigatório'),
  });

  const { register, handleSubmit, formState } = useForm({ resolver: yupResolver(schema) });

  const handleImpugnation = (data: FieldValues) => {
    impugnationField
      .mutateAsync(data)
      .then(() => {
        toast({
          title: 'Sucesso',
          description: 'Impugnação enviada com sucesso!',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        navigate('/painel');
      })
      .catch((error) => {
        toast({
          title: 'Erro',
          description: error?.response?.data?.message || 'Erro na solicitação, tente novamente.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    if (!data) navigate(-1);
  }, []);

  return (
    <MainCard title="ÁREA DO CANDIDATO - " subtitle="FORMULÁRIO DE ENVIO DE IMPUGNAÇÃO CONTRA EDITAL">
      <Stack px={['10px', '10px', '10px', '50px', '50px']} gap={5}>
        <Flex align="center" gap={2}>
          <TextXS fontWeight="bold">Processo seletivo:</TextXS>
          <TextXS>
            {data?.selective_process_type} - {data?.name}
          </TextXS>
        </Flex>
        <Flex align="center" gap={1}>
          <TextXS fontWeight="bold">Nome completo:</TextXS>
          <TextXS> {candidate?.user.full_name}</TextXS>
        </Flex>
        <Flex align="center" gap={1}>
          <TextXS fontWeight="bold">CPF:</TextXS>
          <TextXS>{candidate?.cpf}</TextXS>
        </Flex>

        <Stack as="form" onSubmit={handleSubmit(handleImpugnation)}>
          <FormControl>
            <TextXS mb={2} fontWeight="bold">
              Informe seus argumentos para a solicitação de impugnação deste edital:{' '}
            </TextXS>
            <Textarea {...register('objection')} border="1px solid black" maxH="400px" h="300px"></Textarea>
            <TextXS color="red">{formState.errors.objection?.message}</TextXS>
          </FormControl>
          <Flex gap={3} mb={10}>
            <Button onClick={() => navigate(-1)} w={'100px'}>
              Cancelar
            </Button>
            <Button
              isDisabled={impugnationField.isPending}
              type="submit"
              bg="colorLink"
              color="white"
              w={'100px'}
              _hover={'none'}
            >
              {impugnationField.isPending ? <Spinner /> : 'Enviar'}
            </Button>
          </Flex>
        </Stack>
      </Stack>
    </MainCard>
  );
};

export default ImpugnationPage;
