import { Badge, Link, Stack, Td, Tr } from '@chakra-ui/react';
import TableCustom from '../../../components/pages/CandidateArea/Table';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRequestDetailResources } from '../../../services/hooks/Resources/useRequestDetailResources';
import MainCard from '../../../components/MainCard/Index';
import { useEffect } from 'react';
import { TextL, TextXS } from '../../../components/Text/Index';
import { badgeColor } from '../../../utils/badgeFunction';

const ResourceSolicitation: React.FC = () => {
  const headerResources = ['RECURSO', 'QUESTÃO', 'DATA DO RECURSO', 'SITUAÇÃO', 'AÇÕES'];
  const headerResourcesNoQuestion = ['RECURSO', 'DATA DO RECURSO', 'SITUAÇÃO', 'AÇÕES'];
  const navigate = useNavigate();

  const resourceId = useLocation().state as string;
  const { data } = useRequestDetailResources(resourceId);

  useEffect(() => {
    if (!resourceId) navigate(-1);
  }, []);

  return (
    <MainCard height="100%" title="ÁREA DO CANDIDATO" subtitle="- MEUS RECURSOS">
      <Stack px={['10px', '10px', '10px', '50px', '50px']} gap={10}>
        <Stack>
          <TextL>{data?.selective_process_name}</TextL>
          <TextXS>
            <strong>Recurso: </strong> {data?.period_identification}
          </TextXS>
          <TextXS>
            <strong>Período de recurso: </strong>
            {data?.resource_period}
          </TextXS>
        </Stack>
        {data?.resource_by_candidate && data?.resource_by_candidate.length > 0 && (
          <TableCustom
            headers={data?.resource_type_has_question ? headerResources : headerResourcesNoQuestion}
            maxH={500}
            overflowY="auto"
          >
            {data?.resource_by_candidate.map((resources, index) => (
              <Tr key={index}>
                <Td textAlign="center">
                  <TextXS
                    cursor="pointer"
                    onClick={() => {
                      navigate('/painel/recursos/mostrar-mais/detalhes', { state: resources.resource_id });
                    }}
                    color="colorLink"
                  >
                    {resources.resource_code}
                  </TextXS>
                </Td>
                {data?.resource_type_has_question && (
                  <Td textAlign="center">
                    <TextXS>{resources.question || '--'}</TextXS>
                  </Td>
                )}

                <Td textAlign="center">
                  {' '}
                  <TextXS>{resources.resource_created_at || '--'}</TextXS>
                </Td>
                <Td textAlign="center">
                  <Badge
                    borderRadius={4}
                    textAlign="center"
                    lineHeight="normal"
                    p={1}
                    px={2}
                    bg={badgeColor(resources.status)?.bgColor || 'black'}
                    color={badgeColor(resources.status)?.color || 'white'}
                  >
                    {resources.status}
                  </Badge>
                </Td>
                <Td textAlign="center">
                  <TextXS
                    cursor="pointer"
                    ml={1}
                    as="span"
                    onClick={() => {
                      navigate('/painel/recursos/mostrar-mais/detalhes', { state: resources.resource_id });
                    }}
                    color="colorLink"
                  >
                    Detalhes
                  </TextXS>
                </Td>
              </Tr>
            ))}
          </TableCustom>
        )}

        {data?.is_in_resource && (
          <Stack>
            <TextL>Novo recurso</TextL>
            <TextXS>
              Para criar um novo recurso,{' '}
              <Link
                onClick={() => {
                  navigate('/painel/recursos/novo-recurso', { state: resourceId });
                }}
                color="colorLink"
              >
                Clique aqui
              </Link>
            </TextXS>
          </Stack>
        )}
      </Stack>
    </MainCard>
  );
};
export default ResourceSolicitation;
