import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';

interface INewResourceProps {
  selective_process_name: string;
  selective_process_id: string;
  resource: IResourceProps;
  resource_period: string;
  vacancy: IVacancyProps;
  registration: IRegistrationProps;
  period_identification: string;
  resource_period_id: string;
  size_attached: number;
  max_attached: number;
  exemptions: IResourceExemptions[];
}

export interface IResourceExemptions {
  id: string;
  type: string;
  show_nis_field: boolean;
  justification: string;
  attachements: IAttach[];
  show_redome_field: boolean;
}

interface IAttach {
  id: string;
  name: string;
  url: string;
  status: string;
  created_at: string;
  sent_by_client: boolean;
}

interface IVacancyProps {
  id: string;
  name: string;
}

interface IRegistrationProps {
  id: number;
  candidate_name: string;
  registration_date: string;
  registration_code: string;
}

interface IResourceProps {
  id: string;
  name: string;
  has_question: boolean;
  has_attachment: boolean;
  has_observation: boolean;
  show_nis_field: boolean;
}

const RequestNewResource = async (resource_admin_id: string) => {
  const response = await configApi.get<INewResourceProps>(
    `candidate-area/resource-admin-details/${resource_admin_id}/`,
  );
  return response.data;
};

export const useRequestNewResource = (resource_admin_id: string) => {
  return useQuery({
    queryKey: ['new_resources', resource_admin_id],
    queryFn: () => RequestNewResource(resource_admin_id),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    gcTime: 0,
  });
};
