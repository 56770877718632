import { Box, Flex, FlexProps, Stack } from '@chakra-ui/react';
import Header from '../Header/Header';
import { ReactNode } from 'react';
import { TextXL } from '../Text/Index';

interface IOwnProps extends FlexProps {
  children: ReactNode;
  title: string;
  subtitle?: string;
}

const MainCard: React.FC<IOwnProps> = ({ children, title, subtitle, ...rest }) => {
  return (
    <Flex {...rest} height="100%" w="100%" flexDir="column" overflow="auto">
      <Header />
      <Box
        overflowY={'auto'}
        bg="white"
        w={['95%', '95%', '95%', '95%', '90%', '75%']}
        right={['0', '0', '0', '8%', '8%']}
        h="100%"
        mt={['2.5%', '2.5%', '2.5%', '40px', '40px']}
        mx={['2.5%', '2.5%', '2.5%', 'auto', 'auto']}
        borderTop="6px solid "
        borderTopColor="secondaryColor"
        boxShadow="2px 2px 10px rgba(0,0,0,0.7)"
      >
        <Flex flexDir="column" height="inherit" gap={3}>
          <Stack mb={5} borderBottom="1px solid darkGrey " position="sticky" top={0} bg="white" zIndex={5}>
            <TextXL
              boxShadow={'0px 2px 5px 0px rgba(0,0,0,0.3)'}
              py={['30px', '30px', '30px', '30px', '30px', '40px']}
              px="30px"
            >
              {title} <strong>{subtitle}</strong>
            </TextXL>
          </Stack>
          {children}
          <Stack />
        </Flex>
      </Box>
    </Flex>
  );
};

export default MainCard;
