import {
  Accordion,
  Box,
  Button,
  CheckboxGroup,
  Flex,
  FormControl,
  Grid,
  Input,
  Select,
  Spinner,
  Stack,
  Td,
  Text,
  Textarea,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, useForm } from 'react-hook-form';
import { TextL, TextS, TextXS } from '../../../../components/Text/Index';
import AccordionTemplate from '../../../../components/AccordionTemplate/Index';
import MessageError from '../../../../components/MessageError';
import { useRequestGetSpecialCondition } from '../../../../services/hooks/SpecialCondition/useGetSpecialCondition';
import { useEffect } from 'react';

import { useRequestUpdateSpecialCondition } from '../../../../services/hooks/SpecialCondition/usePatchSpecialCondition';
import TableCustom from '../../../../components/pages/CandidateArea/Table';
import { configApi } from '../../../../services/configApi';

interface INavigationProps {
  previousRoute?: string;
  registrationId?: string;
  selectiveProcessId?: string;
}

const ComponentSpecialCondition: React.FC = () => {
  const navigate = useNavigate();
  const navProps = useLocation().state as INavigationProps;
  const specialConditionData = useRequestGetSpecialCondition(navProps?.registrationId || '');
  const toast = useToast();
  const attachSpecialCondition = ['Arquivo', 'Data de envio', 'Ações'];

  const patchSpecialCondition = useRequestUpdateSpecialCondition();

  const schema = Yup.object().shape({
    has_special_condition: Yup.string().required('Campo obrigatorio'),
    attachmentFile: Yup.array(),
    observation: Yup.array(),
    selectedSpecialCondition: Yup.array()
      .test('isRequired', 'Selecione ao menos uma condição especial', (specialConditionList) => {
        let isEmpty = false;

        if (!(hookForms.getValues('has_special_condition') === 'yes')) return true;

        specialConditionList?.forEach((modality) => {
          if (modality !== false) {
            isEmpty = true;
            return;
          }
        });

        return isEmpty;
      })
      .test('isRequired', '', (specialConditionList) => {
        if (!(hookForms.getValues('has_special_condition') === 'yes')) return true;

        const validationError: Yup.ValidationError[] = [];

        specialConditionList?.forEach((modality, index) => {
          if (modality !== false) {
            const observation: string = hookForms.getValues(`observation.${index}`);
            if (observation?.length === 0) {
              validationError.push(
                new Yup.ValidationError('Campo Obrigatorio', specialConditionList[index], `observation.${index}`),
              );
            }
          }
        });
        if (validationError.length > 0) {
          throw new Yup.ValidationError(validationError);
        }
        return true;
      }),
  });

  const hookForms = useForm({
    resolver: yupResolver(schema),
  });

  const newSpecialCondition = async (data: FieldValues) => {
    patchSpecialCondition
      .mutateAsync({
        data: data,
        registrationId: navProps.registrationId || '',
        specialCondition: specialConditionData?.data?.special_conditions,
      })
      .then(() => {
        toast({
          title: 'Sucesso',
          description: 'Condições especiais alteradas com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        navigate('/painel');
      })
      .catch(() => {
        toast({
          title: 'Erro',
          description: 'Erro na solicitação, tente novamente mais tarde',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      });
  };

  const renderFilename = (idx: number) => {
    const files: FileList = hookForms.watch(`attachmentFile.${idx}`);
    const listFileName: string[] = [];

    if (!files || files.length === 0) {
      return ['Envie seus arquivos'];
    } else {
      for (let index = 0; index < files.length; index++) {
        listFileName.push(files[index]?.name || 'Sem_nome');
      }
    }

    return listFileName;
  };

  useEffect(() => {
    hookForms.setValue('has_special_condition', 'no');
    specialConditionData.data?.special_conditions.map((condition, index) => {
      if (condition.checked) hookForms.setValue('has_special_condition', 'yes');
      hookForms.setValue(`selectedSpecialCondition.${index}`, condition?.checked),
        hookForms.setValue(`observation.${index}`, condition?.observations || '');
    });
  }, [specialConditionData.data]);

  const handleDeleteAttach = (attachmentId: string) => {
    configApi
      .delete(`/attachment/delete/${attachmentId}/`)
      .then(() => {
        toast({
          title: 'Sucesso',
          description: 'Anexo excluido com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        specialConditionData.refetch();
      })
      .catch(() => {
        toast({
          title: 'Erro',
          description: 'Erro na solicitação, tente novamente mais tarde',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex
      as="form"
      onSubmit={hookForms.handleSubmit(newSpecialCondition)}
      flexDir="column"
      px={['10px', '10px', '10px', '50px', '50px']}
      gap={10}
    >
      <Stack>
        <TextL>{specialConditionData?.data?.title}</TextL>
        <TextXS>
          <strong>Nome: </strong>
          {specialConditionData?.data?.candidate}
        </TextXS>
        <TextXS>
          <strong>Inscrição: </strong>
          {specialConditionData?.data?.registration_code}
        </TextXS>
        <TextXS>
          <strong>Vaga: </strong>
          {specialConditionData?.data?.selective_process_vacancy}
        </TextXS>
        <TextXS>
          <strong>Modalidade: </strong>
          {specialConditionData?.data?.type_of_competition}
        </TextXS>
      </Stack>
      <Stack>
        <Accordion display="flex" flexDir="column" gap={2} allowMultiple defaultIndex={[0, 1, 2, 3, 4]}>
          <AccordionTemplate
            border="1px solid"
            borderColor="primaryColor"
            borderRadius={6}
            title="CONDIÇÕES ESPECIAIS PARA REALIZAÇÃO DA PROVA"
          >
            <Flex flexDir={['column', 'column', 'column', 'column', 'column']}>
              <TextXS mb={2} textAlign="left">
                Você deseja informar alguma condição especial para a realização da prova?
              </TextXS>
              <Stack align="center" w="100%">
                <Grid gridTemplateColumns="repeat(3, 1fr)" w="100%">
                  <FormControl textAlign="center">
                    <Select {...hookForms.register('has_special_condition')}>
                      <option value="" hidden>
                        Selecione...
                      </option>
                      <option value="yes">Sim</option>
                      <option value="no">Não</option>
                    </Select>
                    {hookForms.formState.errors.has_special_condition && (
                      <MessageError>{hookForms.formState.errors.has_special_condition?.message}</MessageError>
                    )}
                  </FormControl>
                </Grid>
                {hookForms.watch('has_special_condition') === 'yes' && (
                  <CheckboxGroup>
                    <Stack w="100%" gap={0} direction={['column']}>
                      {(specialConditionData?.data?.special_conditions &&
                        specialConditionData?.data?.special_conditions.map((condition, index) => (
                          <Stack
                            key={condition.id}
                            p={hookForms.watch(`selectedSpecialCondition.${index}`) ? 4 : 1}
                            transition="0.4s"
                            bg={hookForms.watch(`selectedSpecialCondition.${index}`) ? 'lightGrey' : 'white'}
                          >
                            <Box display="flex" gap={1}>
                              <input {...hookForms.register(`selectedSpecialCondition.${index}`)} type="checkbox" />
                              <TextS>{condition.name}</TextS>
                            </Box>
                            {hookForms.watch(`selectedSpecialCondition.${index}`) && (
                              <Stack borderRadius={10}>
                                <Textarea
                                  p={3}
                                  placeholder="Observações"
                                  {...hookForms.register(`observation.${index}`)}
                                  bg="white"
                                  h="100px"
                                />
                                {hookForms.formState.errors.observation && (
                                  <MessageError>
                                    {hookForms.formState?.errors?.observation[index]?.message?.toString()}
                                  </MessageError>
                                )}
                                {condition.has_attachment && (
                                  <Stack>
                                    <Flex align="center">
                                      <Text
                                        bg="primaryColor"
                                        color="white"
                                        w="fit-content"
                                        as="label"
                                        htmlFor={`file-${index}`}
                                        py={1.5}
                                        px={3}
                                        borderRadius={5}
                                        cursor="pointer"
                                      >
                                        Selecionar arquivo
                                      </Text>

                                      {renderFilename(index).map((filename, idx) => {
                                        return filename.length < 30 ? (
                                          <TextXS key={idx} ml={4}>
                                            {filename}
                                          </TextXS>
                                        ) : (
                                          <Tooltip key={idx} label={filename}>
                                            <TextXS
                                              ml={4}
                                            >{`${filename.substring(0, 20)}...${filename.slice(-8)}`}</TextXS>
                                          </Tooltip>
                                        );
                                      })}

                                      <Input
                                        alignContent="center"
                                        {...hookForms.register(`attachmentFile.${index}`)}
                                        type="file"
                                        id={`file-${index}`}
                                        w="fit-content"
                                        pl={0}
                                        border="none"
                                        display="none"
                                      />
                                      {hookForms.formState.errors.attachmentFile && (
                                        <MessageError>
                                          {hookForms.formState?.errors?.attachmentFile[index]?.message?.toString()}
                                        </MessageError>
                                      )}
                                    </Flex>
                                    <Stack>
                                      <TableCustom headers={attachSpecialCondition}>
                                        {condition?.attachments?.map((attach) => (
                                          <Tr key={index} textAlign="center">
                                            <Td textAlign="center">
                                              <TextXS>{attach.name}</TextXS>
                                            </Td>
                                            <Td textAlign="center">
                                              <TextXS>{attach.created_at.br}</TextXS>
                                            </Td>
                                            <Td textAlign="center">
                                              <TextXS
                                                cursor={'pointer'}
                                                onClick={() => handleDeleteAttach(attach.id)}
                                                color="colorLink"
                                              >
                                                Excluir
                                              </TextXS>
                                            </Td>
                                          </Tr>
                                        ))}
                                      </TableCustom>
                                    </Stack>
                                  </Stack>
                                )}
                              </Stack>
                            )}
                          </Stack>
                        ))) || <MessageError>Sem dados...</MessageError>}
                      <MessageError>{hookForms.formState.errors.selectedSpecialCondition?.root?.message}</MessageError>
                    </Stack>
                  </CheckboxGroup>
                )}
              </Stack>
            </Flex>
          </AccordionTemplate>
        </Accordion>

        <Stack flexDir="row" justify="flex-end" py={5}>
          <Button
            w={'100px'}
            onClick={() => {
              navigate('/');
            }}
          >
            Cancelar
          </Button>
          <Button
            isDisabled={patchSpecialCondition?.isPending}
            onClick={() => hookForms.handleSubmit(newSpecialCondition)}
            bg="colorLink"
            color="white"
            w={'100px'}
            _hover={'none'}
          >
            {patchSpecialCondition?.isPending ? <Spinner /> : 'Continuar'}
          </Button>
        </Stack>
      </Stack>
    </Flex>
  );
};

export default ComponentSpecialCondition;
