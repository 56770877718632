import { Button, Flex, FormControl, Input, Stack, useToast } from '@chakra-ui/react';
import MainCard from '../../../../components/MainCard/Index';
import * as Yup from 'yup';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ICPFValidate, useCpfValidation } from '../../../../services/hooks/Registration/useCpfValidation';
import { validateCPF } from '../../../../utils/validateCPF';
import inputMask from 'react-input-mask';
import { AxiosError } from 'axios';
import MessageError from '../../../../components/MessageError';
import { TextS } from '../../../../components/Text/Index';
import { ETypeExemption } from '../../../../services/hooks/SelectiveProcess/Types';

const SelectiveProcessValidationCPF: React.FC = () => {
  const selectiveProcessId = useLocation().pathname.split('/')[2];
  const [searchParams] = useSearchParams();
  const isExemption: boolean = searchParams?.get('isExemption') == 'true';
  const impugnation: boolean = searchParams?.get('impugnation') == 'true';
  const exemption_registration_creation_type = searchParams?.get('exemption_registration_creation_type');
  const navigate = useNavigate();
  const { mutateAsync } = useCpfValidation();
  const toast = useToast();
  const schema = Yup.object().shape({
    cpf: Yup.string()
      .required('Campo obrigatório')
      .test('invalidCPF', 'CPF incompleto', (value) => {
        const cpf = value ?? '';
        const digits = cpf.replace(/\D/g, '');
        return digits.length >= 11;
      })
      .test('invalidCPF', 'Informe um CPF válido', (value) => {
        const cpf = value ?? '';
        const numbers = cpf.replace(/\D/g, '');
        if (numbers.length === 11) return validateCPF(numbers);
        else if (numbers.length > 11) return false;
        return true;
      }),
  });

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const cpfVerify = (payload: FieldValues) => {
    mutateAsync(payload.cpf)
      .then(() => {
        toast({
          title: 'ATENÇÃO',
          description:
            'Verificamos que você já possui cadastro em nosso sistema. Realize seu login através do seu e-mail e senha.',
          status: 'info',
          duration: 10000,
          isClosable: true,
        });

        if (isExemption) {
          if (exemption_registration_creation_type === ETypeExemption.standby) {
            navigate(
              `/login?selective_process_id=${selectiveProcessId}&route=/processo-seletivo/${selectiveProcessId}/isencao`,
            );
            return;
          } else {
            navigate(
              `/login?selective_process_id=${selectiveProcessId}&route=/processo-seletivo/${selectiveProcessId}/inscricao/isencao`,
            );
            return;
          }
        }

        if (impugnation) {
          navigate(`/login?selective_process_id=${selectiveProcessId}&route=/painel/${selectiveProcessId}/impugnacao`);
        } else {
          navigate(
            `/login?selective_process_id=${selectiveProcessId}&route=/processo-seletivo/${selectiveProcessId}/inscricao`,
          );
        }
      })
      .catch((e: AxiosError<ICPFValidate>) => {
        toast({
          title: 'Realize seu cadastro',
          description: e.response?.data?.message || 'Tente novamente mais tarde',
          status: 'info',
          duration: 4000,
          isClosable: true,
        });

        if (isExemption) {
          if (exemption_registration_creation_type === ETypeExemption.standby) {
            sessionStorage.setItem('cpf', payload?.cpf);
            navigate(
              `/cadastro?selective_process_id=${selectiveProcessId}&route=/processo-seletivo/${selectiveProcessId}/isencao`,
            );
            return;
          } else {
            sessionStorage.setItem('cpf', payload?.cpf);
            navigate(
              `/cadastro?selective_process_id=${selectiveProcessId}&route=/processo-seletivo/${selectiveProcessId}/inscricao/isencao`,
            );
            return;
          }
        }

        if (impugnation) {
          sessionStorage.setItem('cpf', payload?.cpf);
          navigate(
            `/cadastro?selective_process_id=${selectiveProcessId}&route=/painel/${selectiveProcessId}/impugnacao`,
          );
        } else {
          sessionStorage.setItem('cpf', payload?.cpf);
          navigate(
            `/cadastro?selective_process_id=${selectiveProcessId}&route=/processo-seletivo/${selectiveProcessId}/inscricao`,
          );
        }
      });
  };

  return (
    <MainCard title="FORMULÁRIO DE INSCRIÇÃO">
      <Flex flexDir="column" p={3} px={['10px', '10px', '10px', '50px', '50px']}>
        <Flex
          py={10}
          justifyContent="space-between"
          alignItems="center"
          flexDir={['column', 'column', 'column', 'row', 'row']}
          overflow="hidden"
        >
          <Stack color="darkGrey" as="form" onSubmit={handleSubmit(cpfVerify)}>
            <TextS fontWeight="bold">Informe o seu CPF no campo abaixo:</TextS>
            <FormControl display="flex" flexDir="column">
              <Flex gap={2} mt={3} flexDir={['column', 'column', 'row', 'row', 'row']}>
                <Input as={inputMask} mask="999.999.999-99" {...register('cpf')} />
                <Button
                  fontSize="13px"
                  type="submit"
                  bg="primaryColor"
                  color="white"
                  w={['100%', '100%', 'fit-content', 'fit-content', 'fit-content']}
                  _hover={{ bg: '#298bdb' }}
                >
                  Continuar
                </Button>
              </Flex>
              <MessageError>{formState.errors.cpf?.message}</MessageError>
            </FormControl>
          </Stack>
        </Flex>
      </Flex>
    </MainCard>
  );
};

export default SelectiveProcessValidationCPF;
