import { Divider, Flex, Spinner, Stack } from '@chakra-ui/react';
import MainCard from '../../../../components/MainCard/Index';
import { useGetRegistration } from '../../../../services/hooks/Registration/useGetRegistration';
import { useLocation } from 'react-router-dom';
import { TextL, TextM, TextS, TextXS, TextXXL } from '../../../../components/Text/Index';
import { badgeColor } from '../../../../utils/badgeFunction';
import { usePreliminaryResult } from '../../../../services/hooks/CandidateArea/useGetPreliminaryResult';

const PreliminaryResult: React.FC = () => {
  const registration_id = useLocation().state;
  const getRegistration = useGetRegistration(registration_id || '');
  const getPreliminaryResult = usePreliminaryResult(registration_id);
  const colors = badgeColor(getPreliminaryResult.data?.status?.name || '');

  const isLoading = getPreliminaryResult.isLoading || getRegistration.isLoading;
  return (
    <MainCard title="PROCESSO SELETIVO - " subtitle="RESULTADO">
      {(isLoading && (
        <Stack>
          <Spinner />
        </Stack>
      )) || (
        <Stack mx={[5, 5, 5, 20, 20, 20]}>
          <Flex
            justify={['flex-start', 'flex-start', 'flex-start', 'space-between', 'space-between']}
            flexDir={['column-reverse', 'column-reverse', 'column-reverse', 'row', 'row', 'row']}
          >
            <Stack marginTop={['60px', '60px', '60px', 0, 0]}>
              <TextXS>
                <strong>Nome completo: </strong>
                {getRegistration.data?.candidate?.user?.full_name || '--'}
              </TextXS>

              {getRegistration.data?.candidate?.social_name && (
                <TextXS>
                  <strong>Nome social: </strong>
                  {getRegistration.data?.candidate?.social_name || '--'}
                </TextXS>
              )}

              <TextXS>
                <strong>CPF: </strong>
                {getRegistration.data?.candidate?.cpf || '--'}
              </TextXS>
              <TextXS>
                <strong>Número de Inscrição:</strong> {getRegistration.data?.registration_code || '--'}
              </TextXS>
              <TextXS>
                <strong>Processo seletivo: </strong>
                {getRegistration.data?.selective_process_vacancy?.selective_process?.name || '--'}
              </TextXS>
              <TextXS>
                <strong>
                  {getRegistration.data?.selective_process_vacancy?.cultural_house
                    ? getRegistration.data?.selective_process_vacancy.class_level
                      ? 'Turma: '
                      : 'Turma - Primeira opção: '
                    : 'Vaga: '}
                </strong>
                {getRegistration.data?.selective_process_vacancy?.name || '--'}
                <TextXS>{getRegistration.data?.selective_process_vacancy?.details || ''}</TextXS>
              </TextXS>
              {getRegistration.data?.second_vacancy?.name && (
                <TextXS>
                  <strong>Turma - Segunda opção: </strong>
                  {getRegistration.data?.second_vacancy?.name || '--'}
                  <TextXS>{getRegistration.data?.second_vacancy?.details || ''}</TextXS>
                </TextXS>
              )}
            </Stack>
            <Flex justifyContent="center">
              <Stack
                w="120px"
                h="fit-content"
                bg={colors?.bgColor || 'secondaryColor'}
                borderTopLeftRadius={3}
                borderTopRightRadius={3}
                position="relative"
                color={colors?.color || 'white'}
                fontWeight="bold"
                _after={{
                  content: `''`,
                  borderLeft: '0px solid',
                  borderRight: '60px solid transparent',
                  borderTop: '60px solid',
                  borderLeftColor: colors?.bgColor || 'secondaryColor',
                  borderTopColor: colors?.bgColor || 'secondaryColor',
                  position: 'absolute',
                  bottom: '-59px',
                }}
                _before={{
                  content: `''`,
                  borderLeft: '60px solid transparent',
                  borderRight: '0px solid',
                  borderTop: '60px solid',
                  borderRightColor: colors?.bgColor || 'secondaryColor',
                  borderTopColor: colors?.bgColor || 'secondaryColor',
                  position: 'absolute',
                  bottom: '-59px',
                  right: 0,
                }}
                gap={0}
              >
                <TextM fontSize={14} py={1} textAlign="center">
                  CLASSIFICAÇÃO
                </TextM>
                <Flex justifyContent="center" alignItems="center" flex={1}>
                  <TextL>
                    {getPreliminaryResult.data?.classification ? `${getPreliminaryResult.data?.classification}º` : '--'}
                  </TextL>
                </Flex>
                <TextXS textTransform="uppercase" mt="2px" pb={2} fontSize={11} textAlign="center">
                  {getPreliminaryResult.data?.status?.name || '--'}
                </TextXS>
              </Stack>
            </Flex>
          </Flex>
          <Stack p={5} my={4}>
            <TextM py={3} textAlign="center" fontWeight="bold" textTransform="uppercase">
              Nota das provas
            </TextM>
            <Divider borderColor="secondaryColor" />
            <Flex
              alignItems="center"
              flexWrap="wrap"
              justify={['center', 'center', 'center', 'flex-start', 'flex-start', 'flex-start']}
              gap={3}
              px={5}
              py={5}
            >
              {getPreliminaryResult.data?.notes?.map((note) => (
                <Stack
                  border="1px solid"
                  w="100%"
                  maxW={['45%', '45%', '45%', 'fit-content']}
                  minW={150}
                  borderColor="primaryColor"
                  alignItems="center"
                  justifyContent="center"
                  px={2}
                  backgroundColor="primaryColor"
                  color="white"
                  borderRadius={4}
                  gap={0}
                >
                  <TextXXL py={2} pt={4} textAlign="center" fontWeight="bold">
                    {note.value || '--'}
                    <TextXS ml="2px">Pontos</TextXS>
                  </TextXXL>
                  <Divider borderColor="white" />
                  <TextS px={2} py={1}>
                    {note.name || '--'}
                  </TextS>
                </Stack>
              ))}
            </Flex>
          </Stack>
        </Stack>
      )}
    </MainCard>
  );
};

export default PreliminaryResult;
