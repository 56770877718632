import { Table, TableContainer, TableContainerProps, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { TextS, TextXS } from '../../Text/Index';

interface MyRegistrationsProps extends TableContainerProps {
  title?: string;
  headers: string[];
  children: ReactNode[] | ReactNode;
}

const TableCustom: React.FC<MyRegistrationsProps> = ({ title, headers, children, ...rest }) => {
  return (
    <>
      <TableContainer {...rest} mt="10px" justifyContent={'center'} overflowX="auto" w="100%" bg={'#E7E7E7'}>
        <Table variant="simple">
          <Thead bg={'#abb1ba'}>
            {title && (
              <Tr>
                <Th colSpan={5} textAlign="center" fontWeight="bold">
                  <TextS color="black">{title}</TextS>
                </Th>
              </Tr>
            )}
            <Tr>
              {headers.map((header, index) => (
                <Th key={index} w="10%" color="black" textAlign="center">
                  <TextXS>{header}</TextXS>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>{children}</Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableCustom;
