export const currencyFormatter = (value: string) => {
  const onlyNumber = value.replace(/\D/g, '');

  switch (onlyNumber.length) {
    case 0:
      return '_,__';
    case 1:
      return `_,_${onlyNumber}`;
    case 2:
      return `_,${onlyNumber}`;
    case 3:
      return `${onlyNumber.substring(0, 1)},${onlyNumber.slice(-2)}`;
    case 4:
      return `${onlyNumber.substring(0, 2)},${onlyNumber.slice(-2)}`;
    case 5:
      return `${onlyNumber.substring(0, 3)},${onlyNumber.slice(-2)}`;
    default:
      return value;
  }
};
