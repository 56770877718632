import { Badge, Button, Flex, Image, Stack } from '@chakra-ui/react';
import { ISelectiveProcess } from '../../../services/hooks/SelectiveProcess/useRequestSelectiveProcess';
import { useNavigate } from 'react-router-dom';
import { badgeColor } from '../../../utils/badgeFunction';
import pcdLogo from '../../../assets/pcd.svg';
import { FaUserGroup } from 'react-icons/fa6';
import { TextM, TextXS, TextXXL } from '../../Text/Index';

interface IOwnProps {
  data: ISelectiveProcess;
}

const SelectiveProcess: React.FC<IOwnProps> = (data) => {
  const navigate = useNavigate();
  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        flexDir={['column-reverse', 'column-reverse', 'row', 'row', 'row']}
      >
        <Stack color="darkGrey">
          <TextXS fontWeight="medium">{data.data.type}</TextXS>
          <TextM>
            <strong>{data.data.name}</strong>
          </TextM>
          <TextXS>
            <strong>Edital:</strong> {data.data.public_notice_number}
          </TextXS>
          {data?.data?.registrations_start && data?.data?.registrations_end && (
            <TextXS>
              <strong>Período de inscrição: </strong> {data?.data?.registrations_start?.br || '--'} até{' '}
              {data?.data?.registrations_end?.br || '--'}
            </TextXS>
          )}
          {data?.data?.exemption_start && data?.data?.exemption_end && (
            <TextXS>
              <strong>Período de isenção: </strong>
              {data?.data?.exemption_start?.br || '--'} até {data.data.exemption_end.br || '--'}
            </TextXS>
          )}
          {data.data.has_pcd && (
            <Flex align="center">
              <Image src={pcdLogo} w="24px" h="24px" />
              <TextXS ml={2} fontWeight="bold">
                Pessoas com deficiência - PCDs
              </TextXS>
            </Flex>
          )}
          {data.data.has_black_or_brown && (
            <Flex align="center">
              <FaUserGroup size={24} />
              <TextXS ml={2} fontWeight="bold">
                Cotas raciais
              </TextXS>
            </Flex>
          )}
          <Flex mt={2} align="center" gap={2} flexDir={['column', 'column', 'row', 'row', 'row']}>
            {data.data.selective_process_situation.map((situation, index) => (
              <Badge
                borderRadius={4}
                textAlign="center"
                lineHeight="normal"
                p={1}
                px={2}
                key={index}
                bg={badgeColor(situation.name)?.bgColor || 'black'}
                color={badgeColor(situation.name)?.color || 'white'}
                fontSize={['11px', '11px', '11px', '11px', '11px']}
              >
                {situation.name}
              </Badge>
            ))}
          </Flex>
        </Stack>
        <Stack mb={['20px', '20px', '20px', '0px', '0px']} lineHeight="30px" color="darkGrey">
          {data.data.number_of_vacancies > 0 && (
            <Stack>
              <TextXXL textAlign="center" fontSize={['35px', '35px', '35px', '35px', '35px', '50px']} fontWeight="bold">
                {data.data.number_of_vacancies}
              </TextXXL>
              <TextXS textAlign="center" lineHeight="18px">
                QUANTIDADE DE VAGAS
              </TextXS>
            </Stack>
          )}

          <Button
            h="33"
            onClick={() => {
              navigate(`/processo-seletivo/informacoes/${data.data.id}`);
            }}
            fontSize={['12px', '12px', '12px', '12px', '12px']}
            bgColor="secondaryColor"
            color="white"
          >
            MAIS INFORMAÇÕES
          </Button>
        </Stack>
      </Flex>
    </>
  );
};

export default SelectiveProcess;
