import { Button, ButtonProps } from '@chakra-ui/react';

interface IOwnProps extends ButtonProps {
  label: string;
  selectButton: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedButton: string | undefined;
}

const MenuItem: React.FC<IOwnProps> = ({ label, selectButton, selectedButton, ...props }) => {
  return (
    <Button
      fontSize={['13px', '13px', '13px', '13px']}
      height="35px"
      borderRadius="2px"
      _after={{
        content: "''",
        position: 'absolute',
        w: '0px',
        h: '0px',
        borderTop: selectedButton === label ? '18px solid transparent' : 'none',
        borderBottom: selectedButton === label ? '18px solid transparent' : 'none',
        borderLeft: selectedButton === label ? '18px solid #4C4C4C' : 'none',
        right: -2,
      }}
      transition="0.1s"
      {...props}
      onClick={() =>
        selectButton((value) => {
          if (value === label) {
            return undefined;
          }
          return label;
        })
      }
      bg={label === selectedButton ? '#4C4C4C' : 'white'}
      color={label === selectedButton ? 'white' : '#4C4C4C'}
      _hover={{ bg: '#4C4C4C', color: 'white' }}
      justifyContent={'start'}
    >
      {label}
    </Button>
  );
};
export default MenuItem;
