

export const validationSizeFile = (listFile: FileList, size: number = 2): boolean => {
    let notError = true;
    const mbToBytes =  size * 1024 * 1024;

    for (let index = 0; index < listFile.length; index++) {
        if (listFile[index] && listFile[index].size > mbToBytes) {
          
            notError = false;
        }        
    }

    return notError;
};

export const validateMaxFile = (
    listFile: FileList,
    maxFile: number = 5,
): boolean => {
    
    if (listFile.length > maxFile) return false;

    return true;
};

export const validateTypeFile = (listFile: FileList): boolean => {
    let hasError = true;
    if (listFile.length > 0) {

        for (let index = 0; index < listFile.length; index++) {
            
            const type =
                listFile[index].type === ''
                    ? ''
                    : listFile[index].type.split('/')[1].toUpperCase();
            if (
                type !== 'PDF' &&
                type !== 'JPG' &&
                type !== 'JPEG' &&
                type !== 'PNG'
            ) {
                hasError = false;
            }
        }

    }

    return hasError;
};

export const validadeFileName = (listFile: FileList): boolean =>{
    if(listFile.length === 0 ) return false
    let hasError = true;

    for (let index = 0; index < listFile.length; index++) {
      const hasDot = listFile[index].name.split('.');
      if (hasDot.length > 2) hasError = false;
    }

    return hasError;
}
