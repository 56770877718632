import { Button, ButtonProps } from '@chakra-ui/react';

interface IOwnProps extends ButtonProps {
  label: string;
  bgColor?: string;
  textColor?: string;
}

const CustomButton: React.FC<IOwnProps> = ({ bgColor = 'primaryColor', label, textColor = 'white', ...props }) => {
  return (
    <Button
      {...props}
      _hover={{ bg: 'gray.500', color: 'white' }}
      borderWidth="1px"
      p={['14px', '14px', '14px', '14px', '14px']}
      w={['100%', '100%', 'auto', 'auto', 'auto']}
      h={['40px', '40px', '40px', '40px', '40px']}
      color={textColor}
      bg={bgColor}
      fontSize={['0.73rem', '0.73rem', '0.73rem', '0.73rem', '0.73rem']}
    >
      {label}
    </Button>
  );
};

export default CustomButton;
