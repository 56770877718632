import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';
import { ICandidateData } from '../../../contexts/types';
import { IDateType } from '../Types';
import { IExemptionFCPC } from '../../../pages/private/CandidateArea/ExemptionComponent/Index';

export interface IObjectFile {
  id: string;
  name: string;
  url: string;
  status: string;
  created_at: IDateType;
  sent_by_client: boolean;
}

export interface IAttachments {
  medical_report: IObjectFile[];
}

export interface ISpecialConditionList {
  attachments: IObjectFile[];
  justification: string;
  file: string;
  id: string;
  name: string;
  observations: string;
  status: string;
}

export interface IInvoice {
  billing_contract_number: string;
  client_code: string;
  codeBar_numeric: string;
  created_at: IDateType;
  digitable_line: string;
  due_date: string;
  emv: string;
  id: string;
  our_number: string;
  status_invoice: string;
  txId: string;
  url: string;
  value: string;
}

export interface IGetRegistration {
  id: string;
  registration_code: string;
  candidate: ICandidateData;
  exemptions: [];
  fcpc_exemption: IExemptionFCPC;
  attachments: IAttachments;
  special_conditions: ISpecialConditionList[];
  is_exemption: boolean;
  request_exemption: boolean;
  according_to_the_terms: boolean;
  has_gun_license: boolean;
  juror_art_440_cpp: boolean;
  is_special_condition: boolean;
  registration_status: IRegistrationStatus;
  selective_process_vacancy: ISelectiveProcessVacancy;
  second_vacancy: ISelectiveProcessVacancy;
  created_at: IDateType;
  invoice?: IInvoice;
  type_of_competition: IDefaultObject[];
  social_name: boolean;
  can_open_another_exemption_request: boolean;
  reserved_vacancy: IReserved_vacancy[];
  observations: string;
  show_view_registration_button: boolean;
  show_print_registration_button: boolean;
  show_download_registration_button: boolean;
}

export interface IReserved_vacancy {
  attachments: IObjectFile[];
  id: string;
  deficiency: string;
  cid: string;
  status: string;
  justification: string;
  type: string;
}

interface ISelectiveProcessRegistration {
  id: string;
  name: string;
  registrations_start: IDateType;
  registrations_end: IDateType;
  exemption_start: IDateType;
  exemption_end: IDateType;
  number_of_vacancies: string;
  public_notice_number: string;
  selective_process_situation: IDefaultObject[];
  type: string;
}

interface IRegistrationStatus {
  id: string;
  name: string;
  code: number;
}

interface ISelectiveProcessVacancy {
  id: string;
  code: string;
  name: string;
  value: string;
  online_registration: boolean;
  workload: string;
  details: string;
  has_cr: boolean;
  has_pcd: boolean;
  has_black_or_brown: boolean;
  has_special_vacancies: boolean;
  number_of_vacancies: number;
  number_of_cr_vacancies: number;
  number_of_pcd_vacancies: number;
  number_of_black_or_brown_vacancies: number;
  class_level: string;
  is_initial_semester: boolean;
  is_active: boolean;
  created_at: IDateType;
  selective_process: ISelectiveProcessRegistration;
  level: string;
  cultural_house: string;
}

export interface IDefaultObject {
  id: string;
  name: string;
}

const getRegistration = async (registrationId: string) => {
  if (!registrationId) return;
  const response = await configApi.get<IGetRegistration>(`registration/${registrationId}/`);
  return response.data;
};

export const useGetRegistration = (registrationId: string) => {
  return useQuery({
    queryKey: ['registratio_data'],
    queryFn: () => getRegistration(registrationId),
    refetchOnWindowFocus: false,
    gcTime: 0,
  });
};
