import { useMutation } from '@tanstack/react-query';
import { configApi } from '../../configApi';

export interface ICPFValidate {
  cpf?: boolean;
  message: string;
}

const cpfValidation = async (cpf: string) => {
  const formData = new FormData();

  if (cpf) {
    const digits = cpf.replace(/\D/g, '');
    formData.append('cpf', digits);
  }

  const response = await configApi.post<ICPFValidate>('candidate/validate/', formData);
  return response;
};

export const useCpfValidation = () => {
  return useMutation({
    mutationFn: (cpf: string) => cpfValidation(cpf),
    mutationKey: ['validationCPF'],
  });
};
