import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';

interface IResultState {
  id: string;
  name: string;
}

const RequestGetCity = async (stateId?: string) => {
  if (!stateId) {
    return [];
  }
  const response = await configApi.get<IResultState[]>(`general-records/city/?state_id=${stateId}`);
  return response.data;
};

export const useRequestGetCity = (stateId?: string) => {
  return useQuery({
    queryKey: ['get_city', stateId],
    queryFn: () => RequestGetCity(stateId),
    refetchOnWindowFocus: false,
  });
};
