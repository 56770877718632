import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';

interface INationality {
  id: string;
  name: string;
}

const RequestGetNationality = async () => {
  const response = await configApi.get<INationality[]>('general-records/country/');
  return response.data;
};

export const useRequestGetNationality = () => {
  return useQuery({
    queryKey: ['get_nationality'],
    queryFn: RequestGetNationality,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
