import { Button, ChakraProvider, Container, extendTheme } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Routers from './services/routes/Router';
import AuthProvider from './contexts/Authentication';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Font } from '@react-pdf/renderer';
import FontRoboto from './assets/Fonts/Roboto/Roboto-Regular.ttf';
import FontRobotoBold from './assets/Fonts/Roboto/Roboto-Bold.ttf';
import FontRobotoItalic from './assets/Fonts/Roboto/Roboto-Italic.ttf';
import { VscDebugConsole } from 'react-icons/vsc';
import eruda from 'eruda';
import { useState } from 'react';

function App() {
  const [showDebug, setShowDebug] = useState<boolean>(false);
  Font.register({
    family: 'Roboto',
    fonts: [
      { src: FontRoboto },
      { src: FontRobotoBold, fontWeight: 'bold' },
      { src: FontRobotoItalic, fontStyle: 'italic' },
    ],
  });

  const queryClient = new QueryClient();

  const theme = extendTheme({
    components: {
      Text: {
        baseStyle: {
          fontSize: ['13px', '13px', '13px', '13px'],
        },
      },
    },
    semanticTokens: {
      colors: {
        primaryColor: '#002E5B',
        secondaryColor: '#E57200',
        lightGrey: '#DEDEDE',
        darkGrey: '#413e3d',
        colorLink: '#0064de',
      },
    },
    breakpoints: {
      base: '300px',
      sm: '650px',
      md: '1024px',
      lg: '1180px',
      xl: '1920px',
    },
    styles: {
      global: {
        body: {
          margin: 0,
          padding: 0,
          overflow: 'hidden',
          backgroundColor: '#e9e9e9',
        },
        html: {},
      },
    },
  });

  return (
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Container px={0} h="100dvh" minW="100dvw">
            {/* <GoogleReCaptchaProvider
                container={{
                  element: 'recaptch-box',
                  parameters: {
                    badge: 'bottomleft',
                    theme: 'dark',
                  },
                }}
                language="pt-BR"
                reCaptchaKey="6Lf06fwpAAAAAFXLC-hhIeQG-l28DEiiEa2Sb9R-"
              >
              </GoogleReCaptchaProvider> */}
            <Routers />
            {import.meta.env.VITE_DEBUG_DEV && !showDebug && (
              <Button
                w="50px"
                h="50px"
                position="absolute"
                background="#000000"
                borderRadius="50%"
                bottom="65px"
                right="10px"
                zIndex={9}
                padding={3}
                display={['flex', 'flex', 'none', 'none', 'none']}
                onClick={() => {
                  setShowDebug(true);
                  eruda.init();
                }}
              >
                <VscDebugConsole color="green" size="100%" />
              </Button>
            )}
          </Container>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ChakraProvider>
    </AuthProvider>
  );
}

export default App;
