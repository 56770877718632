import FormSignIn from '../../../components/pages/signIn/FormSignIn';
import DefaultPage from '../defatulPage';

const SignIn: React.FC = () => {
  return (
    <DefaultPage>
      <FormSignIn />
    </DefaultPage>
  );
};

export default SignIn;
