interface IFile {
    url: string;
    file_name: string;
}

export const convertUrlToFile = async (
    listFile: IFile[],
): Promise<File[]> => {
    const files = await Promise.all(
        listFile.map(async (file) => {
            const response: Response = await fetch(file.url);
            const image = await response.blob();
            const newFile = new File([image], file.file_name, {
                type: image.type,
            })
            return newFile
        }),
    );

    return files;
};
