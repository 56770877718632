import { Button, Link, Spinner, Stack } from '@chakra-ui/react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ProofRegistration from '../proofRegistration';
import { IGetRegistration } from '../../../services/hooks/Registration/useGetRegistration';
import { memo } from 'react';
import { IoMdDownload } from 'react-icons/io';
import { FaRegFileAlt } from 'react-icons/fa';
import { TextXXS } from '../../Text/Index';

interface IOwnProps {
  data?: IGetRegistration;
  isModeViewer?: boolean;
}

const RegistrationPDFButton: React.FC<IOwnProps> = ({ data, isModeViewer }) => {
  return (
    <Stack>
      <PDFDownloadLink document={<ProofRegistration data={data} />} fileName="comprovante_de_inscrição.pdf">
        {({ loading, blob }) => {
          if (isModeViewer) {
            if (!blob) return;
            return (
              <Link href={URL.createObjectURL(blob)} target="_blank">
                <Button
                  p={2}
                  w="100%"
                  color="white"
                  bg="primaryColor"
                  whiteSpace="break-spaces"
                  justifyContent="left"
                  h="fit-content"
                  pl={2.5}
                  leftIcon={<FaRegFileAlt color="white" size={20} />}
                >
                  {(loading && <Spinner />) || (
                    <TextXXS>
                      {data?.exemptions && data?.exemptions?.length > 0
                        ? 'VISUALIZAR COMPROVANTE DE ISENÇÃO'
                        : 'VISUALIZAR COMPROVANTE DE INSCRIÇÃO'}
                    </TextXXS>
                  )}
                </Button>
              </Link>
            );
          }

          return (
            <Button
              p={2}
              color="white"
              w="100%"
              bg="primaryColor"
              whiteSpace="break-spaces"
              h="fit-content"
              justifyContent="left"
              leftIcon={<IoMdDownload size={20} />}
            >
              {(loading && <Spinner />) || (
                <TextXXS>
                  {data?.exemptions && data?.exemptions?.length > 0
                    ? 'BAIXAR O COMPROVANTE DE ISENÇÃO'
                    : 'BAIXAR O COMPROVANTE DE INSCRIÇÃO'}
                </TextXXS>
              )}
            </Button>
          );
        }}
      </PDFDownloadLink>
    </Stack>
  );
};

export default memo(RegistrationPDFButton);
