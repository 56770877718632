import {
  Accordion,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  Stack,
  useToast,
} from '@chakra-ui/react';
import MainCard from '../../../components/MainCard/Index';
import AccordionTemplate from '../../../components/AccordionTemplate/Index';
import InputTemplate from '../../../components/InputTemplate/Index';
import { IoIosEyeOff } from 'react-icons/io';
import inputMask from 'react-input-mask';
import * as Yup from 'yup';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useValidateCEP } from '../../../services/hooks/SignUp/useCepValidation';
import { IoSearchSharp } from 'react-icons/io5';
import { validateCPF } from '../../../utils/validateCPF';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useRequestGetState } from '../../../services/hooks/SignUp/useRequestGetState';
import { useRequestGetCity } from '../../../services/hooks/SignUp/useRequestGetCity';
import { useRequestGetDocumentType } from '../../../services/hooks/SignUp/useRequestGetDocumentType';
import { useRequestGetGender } from '../../../services/hooks/SignUp/useRequestGetGender';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRequestGetMaritalStatus } from '../../../services/hooks/SignUp/useRequestGetMaritalStatus';
import { useRequestGetNationality } from '../../../services/hooks/SignUp/useRequestGetNationality';
import { useRequestGetEducationLevel } from '../../../services/hooks/SignUp/useRequestGetEducationLevel';
import { EFormatDate, formatDate } from '../../../utils/formatData';
import { ETypeError, switchMessageError, validateDate } from '../../../utils/validateDate';
import MessageError from '../../../components/MessageError';
import { TextXS } from '../../../components/Text/Index';
import { useCreateCandidate } from '../../../services/hooks/SignUp/useCreateCandidate';
import { ContextAuth } from '../../../contexts/Authentication';
import { DefaultButton } from '../../../components/ButtonDefault/Index';

const SignUp: React.FC = () => {
  const [searchParams] = useSearchParams();
  const selectiveProcessId = searchParams?.get('selective_process_id');
  const route = searchParams?.get('route');

  const validateCEP = useValidateCEP();
  const toast = useToast();
  const [hasError, setHasError] = useState<number[]>([0, 1, 2]);
  const [openTab, setOpenTab] = useState(0);
  const [maskPhone, setMaskPhone] = useState<boolean>(false);
  const getStates = useRequestGetState();
  const getDocumentType = useRequestGetDocumentType();
  const getGender = useRequestGetGender();
  const getMaritalStatus = useRequestGetMaritalStatus();
  const getNationality = useRequestGetNationality();
  const getEducationLevel = useRequestGetEducationLevel();
  const [city, setCity] = useState<string>();
  const navigate = useNavigate();
  const maxDate = formatDate(new Date(Date.now()).toLocaleString().substring(0, 10), EFormatDate.US);
  const { mutateAsync, isPending } = useCreateCandidate();
  const { signIn } = useContext(ContextAuth);

  const PersonalData = {
    social_name: Yup.string(),
    full_name: Yup.string().required('Campo Obrigatorio'),
    mother_name: Yup.string().required('Campo Obrigatorio'),
    cpf: Yup.string()
      .test('validateCpf', 'Campo obrigatorio', (value) => {
        const cpf = value ?? '';
        const digits = cpf.replace(/\D/g, '');

        return digits.length !== 0;
      })
      .test('invalidCPF', 'CPF incompleto', (value) => {
        const cpf = value ?? '';
        const digits = cpf.replace(/\D/g, '');
        return digits.length >= 11;
      })
      .test('invalidCPF', 'Informe um CPF válido', (value) => {
        const cpf = value ?? '';
        const numbers = cpf.replace(/\D/g, '');
        if (numbers.length === 11) return validateCPF(numbers);
        else if (numbers.length > 11) return false;
        return true;
      }),
    date_of_birth: Yup.string().test('dataInvalid', (value, { createError, path }) => {
      const typeError = validateDate(value);

      if (typeError === ETypeError.noError) return true;

      return createError({
        message: switchMessageError(typeError),
        path: path,
      });
    }),
    education_level: Yup.string().required('Campo Obrigatorio'),
    gender: Yup.string().required('Campo Obrigatorio'),
    marital_status: Yup.string().required('Campo Obrigatorio'),
    email: Yup.string().required('Campo Obrigatorio').email('Preencha um e-mail válido').lowercase(),
    document_type: Yup.string().required('Campo Obrigatorio'),
    document_number: Yup.string().required('Campo Obrigatorio'),
    document_issuing_body: Yup.string().required('Campo Obrigatorio'), //Orgão emissor
    document_issuing_body_uf: Yup.string().required('Campo Obrigatorio'),
    document_date_of_issuance: Yup.string().test('insuanceInvalid', (value, { createError, path }) => {
      const typeError = validateDate(value);

      if (typeError === ETypeError.noError) return true;

      return createError({
        message: switchMessageError(typeError),
        path: path,
      });
    }),
    nationality: Yup.string().required('Campo Obrigatorio'),

    state_naturality: Yup.string().when('nationality', {
      is: (nationality: string) => {
        const apiNationality = getNationality?.data?.find((get) => get.id === nationality);
        if (apiNationality && !apiNationality.name.toLowerCase().includes('outro')) return true;
      },
      then: (schema) => schema.required('O campo é Obrigatorio'),
    }),

    city_naturality: Yup.string().when('nationality', {
      is: (nationality: string) => {
        const apiNationality = getNationality?.data?.find((get) => get.id === nationality);
        if (apiNationality && !apiNationality.name.toLowerCase().includes('outro')) return true;
      },
      then: (schema) => schema.required('Campo Obrigatorio'),
    }),
  };

  const AddresAndContact = {
    addressZipCode: Yup.string().test('validateZip', 'Cep inválido', (cep) => {
      const zipCode = cep ?? '';
      const numbers = zipCode.replace(/\D/g, '');
      return !(numbers.length < 8);
    }),
    address_street: Yup.string().required('Campo Obrigatorio'),
    address_number: Yup.string().required('Campo Obrigatorio'),
    address_complement: Yup.string(),
    address_neighborhood: Yup.string().required('Campo Obrigatorio'),
    address_state: Yup.string().required('Campo Obrigatorio'),
    address_city: Yup.string().required('Campo Obrigatorio'),
    phone: Yup.string(),
    cellphone: Yup.string(),
  };

  const Password = {
    password: Yup.string()
      .required('Campo Obrigatorio')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d).{8,}$/,
        'A senha deve ter no mínimo 8 caracteres e conter pelo menos uma letra e um número',
      ),
    confirm_password: Yup.string()
      .required('Campo Obrigatorio')
      .oneOf([Yup.ref('password')], 'As senhas devem coincidir'),
  };
  const schema = Yup.object().shape({
    ...PersonalData,
    ...Password,
    ...AddresAndContact,
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: { cpf: sessionStorage.getItem('cpf') || '' } });

  // const RegisterAccount = (data: FieldValues) => {
  //   navigate('/cadastro-foto', {
  //     state: {
  //       route: navigationProps.route,
  //       selectiveProcessId: navigationProps.selectiveProcessId,
  //       payload: data,
  //     },
  //   });
  // };

  const RegisterAccount = (data: FieldValues) => {
    if (data) {
      mutateAsync(data)
        .then(() => {
          sessionStorage.removeItem('cpf');
          signIn(data?.email, data?.password).then(() => {
            toast({
              title: 'Sucesso',
              description: 'Cadastro realizado com sucesso',
              status: 'success',
              duration: 4000,
              isClosable: true,
            });
            if (route === '/processo-seletivo/inscricao') {
              navigate(route || '/painel', {
                state: { selectiveProcessId: selectiveProcessId },
              });
              return;
            }
            if (selectiveProcessId) {
              navigate(route || '/login', { state: selectiveProcessId });
            } else {
              navigate(route || '/login');
            }
          });
        })
        .catch((e) => {
          toast({
            title: 'Erro',
            description: e?.response?.data?.message || 'Erro na solicitação, tente novamente',
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        });
    } else {
      toast({
        title: 'Erro',
        description: 'Dados invalidos, tente novamente.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const getCity = useRequestGetCity(watch('address_state'));

  const getNaturality = useRequestGetCity(watch('state_naturality'));

  useEffect(() => {
    if (getCity.data && city) {
      getCity?.data?.forEach((localCity) => {
        const normalizedCity = localCity.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');

        const normalizedLocalCity = city
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        if (localCity?.id && normalizedCity == normalizedLocalCity) {
          setValue('address_city', localCity.id);
        }
      });
    }
  }, [getCity.data, city]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const error: number[] = [];
      Object.keys(errors).map((inputName) => {
        const pd = Object.keys(PersonalData).includes(inputName);
        if (pd) {
          error.push(0);
        }
        const pa = Object.keys(Password).includes(inputName);
        if (pa) {
          error.push(2);
        }
        const ac = Object.keys(AddresAndContact).includes(inputName);
        if (ac) {
          error.push(1);
        }
      });
      setHasError(error);
      setOpenTab((value) => value + 1);
    }
  }, [errors]);

  const checkCep = async () => {
    // const validationZipCode = await trigger('addressZipCode');
    // eslint-disable-next-line no-constant-condition
    if (true) {
      const cep = getValues('addressZipCode');
      const zipCode = cep ?? '';
      const numbers = zipCode.replace(/\D/g, '');
      if (numbers?.length === 8) {
        validateCEP.mutateAsync(cep).then((data) => {
          if (data?.erro) {
            toast({
              title: 'Erro',
              description: 'CEP não encontrado',
              status: 'error',
              duration: 4000,
              isClosable: true,
            });
          } else {
            getStates?.data?.forEach((state) => {
              if (state.initials == data?.uf) {
                setValue('address_state', state.id || '');
              }
            });

            setValue('address_street', data?.logradouro || '');

            setValue('address_neighborhood', data?.bairro || '');
            if (data?.localidade) setCity(data?.localidade);
          }
        });
      } else {
        toast({
          title: 'Atenção',
          description: 'Por favor, preencha o CEP',
          status: 'warning',
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const getIdNationality = (): boolean => {
    const nationality_id = watch('nationality');
    let response = false;
    getNationality.data?.forEach((nationality) => {
      if (nationality.id == nationality_id) {
        response = nationality.name.toLowerCase().includes('brasil');
        return;
      }
    });

    return response;
  };

  const backButton = () => {
    sessionStorage.removeItem('cpf');
    navigate(-1);
  };

  return (
    <>
      {validateCEP.isPending && (
        <Flex position={'absolute'} h="100dvh" w="100dvw" zIndex={999} justify="center" align="center">
          <Box position="absolute" bg="black" opacity={0.6} h="100%" w="100%"></Box>
          <Stack zIndex={999} color="white" align="center" gap={5}>
            <Spinner size="xl" />
            <TextXS>Carregando Endereço</TextXS>
          </Stack>
        </Flex>
      )}

      <MainCard title="FORMULÁRIO DE CADASTRO">
        <Accordion
          key={openTab}
          as="form"
          onSubmit={handleSubmit(RegisterAccount)}
          p={10}
          defaultIndex={hasError}
          allowMultiple
          display="flex"
          flexDir="column"
        >
          <AccordionTemplate gapPanel={5} title="DADOS PESSOAIS">
            <Flex mt={5} gap={5} flexDir={['column', 'column', 'column', 'row', 'row']}>
              <FormControl w={['100%', '100%', '100%', '25%', '25%']} isRequired>
                <FormLabel>CPF:</FormLabel>
                <Input isRequired={false} {...register('cpf')} as={inputMask} mask={'999.999.999-99'} />
                <MessageError>{errors.cpf?.message}</MessageError>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Nome Completo:</FormLabel>
                <Input isRequired={false} {...register('full_name')} />
                <MessageError>{errors.full_name?.message}</MessageError>
              </FormControl>
              <FormControl isRequired w={['100%', '100%', '100%', '25%', '25%']}>
                <FormLabel>Data de Nascimento:</FormLabel>
                <Input max={maxDate} type="date" isRequired={false} {...register('date_of_birth')} />
                <MessageError>{errors.date_of_birth?.message}</MessageError>
              </FormControl>
            </Flex>

            <Flex gap={5} flexDir={['column', 'column', 'column', 'row', 'row']}>
              <FormControl>
                <FormLabel>Nome Social:</FormLabel>
                <Input isRequired={false} {...register('social_name')} />
                <MessageError>{errors.social_name?.message}</MessageError>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Nome da Mãe:</FormLabel>
                <Input isRequired={false} {...register('mother_name')} />
                <MessageError>{errors.mother_name?.message}</MessageError>
              </FormControl>
            </Flex>

            <Flex gap={5} flexDir={['column', 'column', 'column', 'column', 'row']}>
              <FormControl isRequired>
                <FormLabel>Tipo de Documento:</FormLabel>
                <Select isRequired={false} {...register('document_type')}>
                  <option value="" hidden>
                    Selecione
                  </option>
                  {getDocumentType.data?.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </Select>
                <MessageError>{errors.document_type?.message}</MessageError>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Número do Documento:</FormLabel>
                <Input isRequired={false} {...register('document_number')} />
                <MessageError>{errors.document_number?.message}</MessageError>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Data de expedição:</FormLabel>
                <Input type="date" isRequired={false} {...register('document_date_of_issuance')} />
                <MessageError>{errors.document_date_of_issuance?.message}</MessageError>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Órgão:</FormLabel>
                <Input isRequired={false} {...register('document_issuing_body')} />
                <MessageError>{errors.document_issuing_body?.message}</MessageError>
              </FormControl>

              <FormControl w={['100%', '100%', '100%', '50%', '50%']} isRequired>
                <FormLabel>UF:</FormLabel>
                <Select isRequired={false} {...register('document_issuing_body_uf')}>
                  <option value={''} hidden>
                    Selecione
                  </option>
                  {getStates?.data?.map((state) => (
                    <option value={state.id} key={state.id}>
                      {state.initials}
                    </option>
                  ))}
                </Select>
                <MessageError>{errors.document_issuing_body_uf?.message}</MessageError>
              </FormControl>
            </Flex>

            <Flex gap={5} flexDir={['column', 'column', 'column', 'row', 'row']}>
              <FormControl isRequired>
                <FormLabel>Escolaridade:</FormLabel>
                <Select isRequired={false} {...register('education_level')}>
                  <option value="" hidden>
                    Selecione
                  </option>
                  {getEducationLevel?.data?.map((educationLevel) => (
                    <option key={educationLevel.id} value={educationLevel.id}>
                      {educationLevel.name}
                    </option>
                  ))}
                </Select>
                <MessageError>{errors.education_level?.message}</MessageError>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Gênero:</FormLabel>
                <Select isRequired={false} {...register('gender')}>
                  <option value="" hidden>
                    Selecione
                  </option>
                  {getGender?.data?.map((gender) => (
                    <option key={gender.id} value={gender.id}>
                      {gender.name}
                    </option>
                  ))}
                </Select>
                <MessageError>{errors.gender?.message}</MessageError>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Estado Civil:</FormLabel>
                <Select isRequired={false} {...register('marital_status')}>
                  <option value="" hidden>
                    Selecione
                  </option>
                  {getMaritalStatus?.data?.map((maritalStatus) => (
                    <option key={maritalStatus.id} value={maritalStatus.id}>
                      {maritalStatus.name}
                    </option>
                  ))}
                </Select>
                <MessageError>{errors.marital_status?.message}</MessageError>
              </FormControl>
            </Flex>

            <Flex gap={5} flexDir={['column', 'column', 'column', 'row', 'row']}>
              <FormControl isRequired>
                <FormLabel>Nacionalidade:</FormLabel>
                <Select
                  isRequired={false}
                  {...register('nationality', {
                    onChange: () => {
                      if (!getIdNationality()) {
                        setValue('city_naturality', undefined);
                        setValue('state_naturality', undefined);
                      }
                    },
                  })}
                >
                  <option value="" hidden>
                    Selecione
                  </option>
                  {getNationality.data?.map((nationality) => (
                    <option key={nationality.id} value={nationality.id}>
                      {nationality.name}
                    </option>
                  ))}
                </Select>
                <MessageError>{errors.nationality?.message}</MessageError>
              </FormControl>
              {getIdNationality() && (
                <>
                  <FormControl isRequired>
                    <FormLabel>Naturalidade (Estado):</FormLabel>
                    <Select isRequired={false} {...register('state_naturality')}>
                      <option value="" hidden>
                        Selecione
                      </option>
                      {getStates.data?.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.initials}
                        </option>
                      ))}
                    </Select>
                    <MessageError>{errors.state_naturality?.message}</MessageError>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>Naturalidade (Cidade):</FormLabel>
                    <Select isDisabled={!watch('state_naturality')} isRequired={false} {...register('city_naturality')}>
                      <option value="" hidden>
                        Selecione
                      </option>
                      {getNaturality.data?.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                    </Select>
                    <MessageError>{errors.city_naturality?.message}</MessageError>
                  </FormControl>
                </>
              )}
            </Flex>
          </AccordionTemplate>

          {/* <AccordionTemplate display="flex" flexDir="column" gap={5} title="DADOS ADICIONAIS"></AccordionTemplate> */}

          <AccordionTemplate gapPanel={5} display="flex" flexDir="column" title="ENDEREÇO E CONTATO">
            <Flex mt={5} gap={5} flexDir={['column', 'column', 'column', 'row', 'row']}>
              <FormControl w={['100%', '100%', '100%', '30%', '30%']} isRequired>
                <FormLabel>CEP:</FormLabel>
                <Flex>
                  <Input
                    isRequired={false}
                    borderTopRightRadius={0}
                    borderBottomRightRadius={0}
                    {...register('addressZipCode')}
                    as={inputMask}
                    mask="99999-999"
                    borderRight="none"
                  />

                  <Button onClick={checkCep} bg="gray.200" borderTopLeftRadius={0} borderBottomLeftRadius={0}>
                    <IoSearchSharp size={30} />
                  </Button>
                </Flex>
                <MessageError>{errors.addressZipCode?.message}</MessageError>
              </FormControl>
              <FormControl w={['100%', '100%', '100%', '70%', '70%']} isRequired>
                <FormLabel>Endereço:</FormLabel>
                <Input isRequired={false} {...register('address_street')} />
                <MessageError>{errors.address_street?.message}</MessageError>
              </FormControl>
            </Flex>

            <Flex gap={5} flexDir={['column', 'column', 'column', 'row', 'row']}>
              <FormControl w={['100%', '100%', '100%', '30%', '30%']} isRequired>
                <FormLabel>Número:</FormLabel>
                <Input isRequired={false} {...register('address_number')} />
                <MessageError>{errors.address_number?.message}</MessageError>
              </FormControl>

              <FormControl w={['100%', '100%', '100%', '30%', '30%']}>
                <FormLabel>Complemento:</FormLabel>
                <Input {...register('address_complement')} />
                <MessageError>{errors.address_complement?.message}</MessageError>
              </FormControl>

              <FormControl w={['100%', '100%', '100%', '30%', '30%']} isRequired>
                <FormLabel>Bairro:</FormLabel>
                <Input isRequired={false} {...register('address_neighborhood')} />
                <MessageError>{errors.address_neighborhood?.message}</MessageError>
              </FormControl>
            </Flex>

            <Flex gap={5} flexDir={['column', 'column', 'column', 'row', 'row']}>
              <FormControl w={['100%', '100%', '100%', '50%', '50%']} isRequired>
                <FormLabel>UF:</FormLabel>
                <Select isRequired={false} {...register('address_state')}>
                  <option value={''} hidden>
                    Selecione
                  </option>
                  {getStates?.data?.map((state) => (
                    <option value={state.id} key={state.id}>
                      {state.initials}
                    </option>
                  ))}
                </Select>
                <MessageError>{errors.address_state?.message}</MessageError>
              </FormControl>
              <FormControl w={['100%', '100%', '100%', '50%', '50%']} isRequired>
                <FormLabel>Cidade:</FormLabel>
                <Select isDisabled={!watch('address_state')} isRequired={false} {...register('address_city')}>
                  <option value={''} hidden>
                    Selecione
                  </option>
                  {getCity?.data?.map((city) => (
                    <option value={city.id} key={city.id}>
                      {city.name}
                    </option>
                  ))}
                </Select>
                <MessageError>{errors.address_city?.message}</MessageError>
              </FormControl>
            </Flex>
            <Flex gap={5} flexDir={['column', 'column', 'column', 'row', 'row']}>
              <FormControl w={['100%', '100%', '100%', '50%', '50%']}>
                <FormLabel>Celular:</FormLabel>
                <Input
                  as={inputMask}
                  maskChar={''}
                  mask={maskPhone ? '(99) 99999-9999' : '(99) 9999-99999'}
                  {...register('cellphone', {
                    onChange: (ev: ChangeEvent<HTMLInputElement>) => {
                      if (ev.currentTarget.value && ev.currentTarget.value.length >= 15) {
                        setMaskPhone(true);
                      }
                    },
                  })}
                />
              </FormControl>
              <FormControl w={['100%', '100%', '100%', '50%', '50%']}>
                <FormLabel>Telefone:</FormLabel>
                <Input as={inputMask} mask={'(99) 9999-9999'} {...register('phone')} />
              </FormControl>
            </Flex>
          </AccordionTemplate>

          <AccordionTemplate display="flex" flexDir="column" gap={5} title="DADOS DE ACESSO">
            <Flex mt={5} gap={5} flexDir={['column', 'column', 'column', 'row', 'row']}>
              <Stack w={['100%', '100%', '100%', '50%', '50%']}>
                <InputTemplate label="E-mail:" isRequired={true} {...register('email')} />
                <MessageError>{errors.email?.message}</MessageError>
              </Stack>
              <Stack w={['100%', '100%', '100%', '50%', '50%']}>
                <InputTemplate
                  isRequired={true}
                  {...register('password')}
                  label="Senha:"
                  icon={<IoIosEyeOff size={30} />}
                  type="password"
                />
                <MessageError>{errors.password?.message}</MessageError>
              </Stack>
              <Stack w={['100%', '100%', '100%', '50%', '50%']}>
                <InputTemplate
                  onPasteCapture={(e) => e.preventDefault()}
                  isRequired={true}
                  {...register('confirm_password')}
                  label="Repita sua Senha:"
                  icon={<IoIosEyeOff size={30} />}
                  type="password"
                />
                <MessageError>{errors.confirm_password?.message}</MessageError>
              </Stack>
            </Flex>
          </AccordionTemplate>

          <Stack flexDir="row" justify="flex-end" mt={10}>
            <DefaultButton bg="lightGrey" color="black" onClick={backButton} label="Cancelar" />

            <DefaultButton
              isLoading={isPending}
              bg="colorLink"
              color="white"
              type="submit"
              label="Finalizar"
              maxW="fit-content"
            />
          </Stack>
        </Accordion>
      </MainCard>
    </>
  );
};

export default SignUp;
