import {
  Accordion,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Link,
  Select,
  Spinner,
  Stack,
  useToast,
} from '@chakra-ui/react';
import MainCard from '../../../../../components/MainCard/Index';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactNode, useContext } from 'react';
import { ContextAuth } from '../../../../../contexts/Authentication';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AccordionTemplate from '../../../../../components/AccordionTemplate/Index';
import { useRequestGetDataToRegistration } from '../../../../../services/hooks/CandidateArea/useGetDataToRegistration';
import { FieldValues, useForm } from 'react-hook-form';
import MessageError from '../../../../../components/MessageError';
import { TextL, TextXS, TextXXS } from '../../../../../components/Text/Index';
import { useRequestGetExemptionType } from '../../../../../services/hooks/Exemptions/useRequestGetExemptionType';
import MarrowDonnor from '../MarrowDonnor/Index';
import NIS from '../NIS/Index';
import { useGetRegistration } from '../../../../../services/hooks/Registration/useGetRegistration';
import { validateMaxFile, validateTypeFile, validationSizeFile } from '../../../../../utils/validationForm';
import { useCreateExemption } from '../../../../../services/hooks/Exemptions/useCreateExemption';

const OnlyExemption: React.FC = () => {
  const navigate = useNavigate();
  const { signOut, candidate } = useContext(ContextAuth);

  const toast = useToast();
  const getExemption = useRequestGetExemptionType();
  const url = useLocation().pathname.split('/')[2];

  const registrationId = useLocation().pathname.split('/')[5];
  const registration = useGetRegistration(registrationId);

  const isValidUUID = (uuid: string) => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(uuid);
  };

  const selectiveProcessId = isValidUUID(url) && url;

  const selectiveProcessData = useRequestGetDataToRegistration(selectiveProcessId || '');

  const { mutateAsync, isPending } = useCreateExemption();

  const schema = Yup.object().shape({
    selective_process_id: Yup.string(),
    type_exemption: Yup.string().test('required', 'Campo obrigatorio', (value) => !!value),
    document_number: Yup.string().when('type_exemption', {
      is: (exemption: string) => exemption.toLowerCase().includes('cadúnico'),
      then: (schema) => schema.test('required', 'Campo obrigatorio', (value) => !!value),
    }),
    document_issuing_body: Yup.string().when('type_exemption', {
      is: (exemption: string) => exemption.toLowerCase().includes('cadúnico'),
      then: (schema) => schema.test('required', 'Campo obrigatorio', (value) => !!value),
    }),

    document_issuing_body_uf: Yup.string().when('type_exemption', {
      is: (exemption: string) => exemption.toLowerCase().includes('cadúnico'),
      then: (schema) => schema.test('required', 'Campo obrigatorio', (value) => !!value),
    }),

    nis: Yup.string().when('type_exemption', {
      is: (exemption: string) => exemption.toLowerCase().includes('cadúnico'),
      then: (schema) =>
        schema.test('required', 'Campo obrigatorio', (value) => !!value).length(11, 'O campo NIS deve ter 11 digitos'),
    }),

    mother_name: Yup.string().when('type_exemption', {
      is: (exemption: string) => exemption.toLowerCase().includes('cadúnico'),
      then: (schema) => schema.test('required', 'Campo obrigatorio', (value) => !!value),
    }),

    attachmentExemption: Yup.mixed<FileList>().when('type_exemption', {
      is: (exemption: string) => exemption.toLowerCase().includes('doador'),
      then: (schema) =>
        schema
          .test('Required', 'Campo obrigatório', (file) => {
            const listFile = file as FileList;

            if (listFile?.length == 0) {
              return false;
            }

            return true;
          })
          .test('validationSizeFile', 'Tamanho máximo para arquivos é de 2MB', (file) => {
            const listFile = file as FileList;

            if (listFile?.length > 0) {
              return validationSizeFile(listFile);
            }

            return false;
          })
          .test('validationMaxFile', 'Quantidade máxima de arquivos é de 5 arquivos', (file) => {
            const listFile = file as FileList;

            if (listFile?.length > 0) {
              return validateMaxFile(listFile);
            }
          })
          .test('validationTypeFile', 'Formato de arquivo não suportado', (file) => {
            const listFile = file as FileList;

            if (listFile?.length > 0) {
              return validateTypeFile(listFile);
            }
          }),
    }),
  });

  const listChecked: (string | boolean)[] = [];
  const listObservation: (string | boolean | undefined)[] = [];

  selectiveProcessData.data?.special_condition.map((s) => {
    let isSelected = false;
    let obs = '';

    registration.data?.special_conditions.forEach((r) => {
      if (r.id === s.id) {
        isSelected = true;
        obs = r.observations;
      }
    });

    if (isSelected) {
      listChecked.push(s.id);
      listObservation.push(obs);
    } else {
      listChecked.push(false);
      listObservation.push('');
    }
  });

  const hookForms = useForm({
    resolver: yupResolver(schema),
    values: {
      type_exemption: JSON.stringify(registration.data?.fcpc_exemption.type),
      document_issuing_body: registration.data?.fcpc_exemption?.document_issuing_body,
      document_issuing_body_uf: registration.data?.fcpc_exemption?.document_issuing_body_uf?.id,
      document_number: registration.data?.fcpc_exemption?.document_number,
      mother_name: registration.data?.fcpc_exemption?.mother_name,
      nis: registration.data?.fcpc_exemption?.nis,
      selective_process_id: selectiveProcessId || '',
    },
  });
  const newRegistration = (data: FieldValues) => {
    mutateAsync({
      data,
    })
      .then(() => {
        if (registrationId) {
          toast({
            title: 'Sucesso',
            description: 'Isenção Editada com sucesso',
            status: 'success',
            duration: 4000,
            isClosable: true,
          });
          navigate(-1);
          return;
        }

        toast({
          title: 'Sucesso',
          description: 'Isenção criada com sucesso!',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        navigate('/painel/isencoes');
      })
      .catch((error) => {
        toast({
          title: 'Erro',
          description: error.response?.data.message || 'Não foi possivel realizar a Isenção.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      });
  };

  if (!selectiveProcessId) navigate(-1);

  const navProps = {
    registrationId: '',
  };

  const handleShowForm = (): ReactNode => {
    const form = hookForms.watch('type_exemption');

    if (form) {
      const formToShow = JSON.parse(form);

      if (formToShow?.name?.toLowerCase().includes('doador')) {
        return (
          <MarrowDonnor
            hookForms={hookForms}
            // onSetValue={hookForms.setValue}
            exemptionId={formToShow?.id}
            registrationId={navProps?.registrationId || ''}
          />
        );
      } else {
        return (
          <NIS
            hookForms={hookForms}
            hideButton
            // onSetValue={hookForms.setValue}
            exemptionId={formToShow.id}
            registrationId={navProps?.registrationId || ''}
          />
        );
      }
    }

    return false;
  };

  const isLoading = registration.isLoading;

  return (
    <MainCard title="PROCESSO SELETIVO - " subtitle="FORMULÁRIO DE SOLICITAÇÃO DE ISENÇÃO">
      {(isLoading && (
        <Stack flex={1} justify="center" align="center">
          <Spinner />
        </Stack>
      )) || (
        <Flex
          as="form"
          onSubmit={hookForms.handleSubmit(newRegistration)}
          flexDir="column"
          px={['10px', '10px', '10px', '50px', '50px']}
          gap={10}
        >
          <Flex
            justifyContent="space-between"
            alignItems="left"
            flexDir={['column', 'column', 'column', 'row', 'row']}
            overflow="hidden"
          >
            <Stack color="darkGrey">
              <TextXS>{selectiveProcessData.data?.solective_process_type_name || ''}</TextXS>
              <TextL>{selectiveProcessData.data?.name || ''}</TextL>
            </Stack>
          </Flex>
          <Stack>
            <Accordion display="flex" flexDir="column" gap={2} allowMultiple defaultIndex={[0, 1, 2, 3, 4]}>
              <AccordionTemplate title="DADOS PESSOAIS" border="1px solid" borderColor="primaryColor" borderRadius={6}>
                <Flex px={10} mb={5} flexDir={['column', 'column', 'row', 'row', 'row']}>
                  <Stack w="100%" p={3}>
                    <TextXS>
                      <strong>Nome Completo:</strong> {candidate?.user?.full_name || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>CPF:</strong> {candidate?.cpf || '--'} <br />
                    </TextXS>
                    <TextXXS ml="30px" as={'span'}>
                      Não é seu CPF?{' '}
                      <Link
                        onClick={() => {
                          signOut();
                          navigate('/');
                        }}
                        as={'span'}
                        color="colorLink"
                      >
                        clique aqui
                      </Link>
                    </TextXXS>
                    <TextXS>
                      <strong>Data de Nascimento:</strong> {candidate?.date_of_birth.br || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>E-mail:</strong> {candidate?.user?.email || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>{candidate?.document_type.name || '--'}</strong>
                      {': '}
                      {candidate?.document_number || '--'} {candidate?.document_issuing_body}{' '}
                      {candidate?.document_issuing_body_uf?.initials || ''}
                    </TextXS>
                    <TextXS>
                      <strong>Sexo:</strong> {candidate?.gender?.name || '--'}
                    </TextXS>
                  </Stack>
                  <Stack w="100%" p={3}>
                    <TextXS>
                      <strong>CEP:</strong> {candidate?.address_zip_code || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>Endereço:</strong> {candidate?.address_street || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>Número:</strong> {candidate?.address_number || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>Complemento: </strong>
                      {candidate?.address_complement || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>Bairro:</strong> {candidate?.address_neighborhood || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>Cidade: </strong>
                      {candidate?.address_city?.name || '--'} / {candidate?.address_state?.initials || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>Telefone:</strong> {candidate?.phone || '--'}
                    </TextXS>
                    <TextXS>
                      <strong>Celular:</strong> {candidate?.cellphone || '--'}
                    </TextXS>
                  </Stack>
                </Flex>
                <Stack p={2}>
                  <TextXS>
                    <strong>ATENÇÃO: </strong>Os dados acima estão incorretos? caso estejam,{' '}
                    <Link
                      onClick={() => {
                        navigate('/painel/meus-dados');
                      }}
                      color="colorLink"
                    >
                      clique aqui
                    </Link>{' '}
                    e atualize seu cadastro.
                  </TextXS>
                </Stack>
              </AccordionTemplate>

              <AccordionTemplate
                border="1px solid"
                borderColor="primaryColor"
                borderRadius={6}
                title="DADOS DA SOLICITAÇÃO DE ISENÇÃO"
              >
                <Stack py={10}>
                  <FormControl>
                    <FormLabel>Tipo de Solicitação de Isenção</FormLabel>
                    <Select {...hookForms.register('type_exemption')} w="100%" maxW="350px">
                      <option hidden value="">
                        Selecione
                      </option>
                      {getExemption?.data?.map((exemption, index) => (
                        <option key={index} value={JSON.stringify(exemption)}>
                          {exemption?.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <MessageError>{hookForms.formState.errors.type_exemption?.message}</MessageError>
                  {handleShowForm()}
                </Stack>
              </AccordionTemplate>
            </Accordion>
            <Stack flexDir="row" justify="flex-end" py={5}>
              <Button
                w={'100px'}
                onClick={() => {
                  navigate('/');
                }}
              >
                Cancelar
              </Button>
              <Button isDisabled={isPending} type="submit" bg="colorLink" color="white" w={'100px'} _hover={'none'}>
                {(isPending && <Spinner />) || (registrationId && 'Salvar') || 'Continuar'}
              </Button>
            </Stack>
          </Stack>
        </Flex>
      )}
    </MainCard>
  );
};

export default OnlyExemption;
