import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import SignIn from '../../pages/public/SignIn/Index';
import SignUp from '../../pages/public/SignUp/Index';
import CandidateArea from '../../pages/private/CandidateArea/Index';
import { ProtectedRoute } from '../ProtectedRoute';
import RecoveryPassword from '../../pages/public/ResetPassword/RecoveryPassword';
import ResetPassword from '../../pages/public/ResetPassword/ResetPassword';
import HomePage from '../../pages/public/Homepage/Index';
import Resources from '../../pages/private/Resources/Index';
import Profile from '../../pages/private/Profile/Index';
import ChangePassword from '../../pages/private/ChangePassword/Index';
import ResourceSolicitation from '../../pages/private/Resources/ResourceSolicitation';
import DetailResource from '../../pages/private/Resources/DetailResource';
import NewResource from '../../pages/private/Resources/NewResource';
import SelectiveProcessInformation from '../../pages/public/SelectiveProcess/Information/Index';
import SelectiveProcessNotice from '../../pages/public/SelectiveProcess/Notice/Index';
import SelectiveProcessValidationCPF from '../../pages/public/SelectiveProcess/ValidationCPF/Index';
import Exemptions from '../../pages/private/Exemptions/Index';
import ProfilePicture from '../../pages/public/SignUp/ProfilePicture';
import SelectiveProcessSubscribe from '../../pages/private/Registration/Index';
import SummaryRegistration from '../../pages/private/Registration/SummaryRegistration';
import SocialName from '../../pages/private/Registration/SocialName';
import ImpugnationPage from '../../pages/private/Impugnation/Index';
import ImpugnationDetail from '../../pages/private/Impugnation/ImpugnationDetail';
import PrintRegistration from '../../components/Print/registration';
import MoreDetail from '../../pages/private/CandidateArea/HistoryRegistration/MoreDetail/Index';
import PaymentReissue from '../../pages/private/CandidateArea/PaymentReissue/Index';
import ExemptionRequest from '../../pages/private/CandidateArea/ExemptionComponent/ExemptionRequest';
import SpecialCondition from '../../pages/private/CandidateArea/SpecialCondition/Index';
import SubscriptonAndExemption from '../../pages/private/Exemptions/Forms/FormExemption/SubscriptonAndExemption';
import ProofLocationCandidateArea from '../../pages/private/CandidateArea/ProofLocation/ProofLocation';
import PreliminaryResult from '../../pages/private/CandidateArea/PreliminaryResult';
import OnlyExemption from '../../pages/private/Exemptions/Forms/FormExemption/OnlyExemption';

const Routers: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Public Routes */}

          <Route path="*" element={<Navigate to={'/'} />}></Route>

          <Route path="/" element={<HomePage />}></Route>

          {/* Selective Process */}
          <Route path="/processo-seletivo/informacoes/:selectiveProcessId" element={<SelectiveProcessInformation />} />
          <Route path="/processo-seletivo/edital" element={<SelectiveProcessNotice />} />
          <Route
            path="/processo-seletivo/:selectiveProcessId/validar-cpf"
            element={<SelectiveProcessValidationCPF />}
          />
          <Route
            path="/processo-seletivo/:selectiveProcessId/inscricao"
            element={
              <ProtectedRoute
                authenticatedRoute={<SelectiveProcessSubscribe />}
                noAuthRoute={<Navigate to={'/login'} />}
              />
            }
          />

          <Route
            path="/processo-seletivo/:selectiveProcessId/editar-inscricao/:registrationId"
            element={
              <ProtectedRoute
                authenticatedRoute={<SelectiveProcessSubscribe />}
                noAuthRoute={<Navigate to={'/login'} />}
              />
            }
          />

          <Route
            path="/processo-seletivo/:selective_process_id/inscricao/isencao"
            element={
              <ProtectedRoute
                authenticatedRoute={<SubscriptonAndExemption />}
                noAuthRoute={<Navigate to={'/login'} />}
              />
            }
          />
          <Route
            path="/processo-seletivo/:selective-process-id/inscricao/isencao/:id/atualizar"
            element={
              <ProtectedRoute
                authenticatedRoute={<SubscriptonAndExemption />}
                noAuthRoute={<Navigate to={'/login'} />}
              />
            }
          />
          <Route
            path="/processo-seletivo/:selectiveProcessId/inscricao/:registrationId/resumo"
            element={
              <ProtectedRoute authenticatedRoute={<SummaryRegistration />} noAuthRoute={<Navigate to={'/login'} />} />
            }
          />
          <Route
            path="/processo-seletivo/isencao/resumo"
            element={
              <ProtectedRoute authenticatedRoute={<SummaryRegistration />} noAuthRoute={<Navigate to={'/login'} />} />
            }
          />

          <Route
            path="/processo-seletivo/:selective_process_id/isencao"
            element={<ProtectedRoute authenticatedRoute={<OnlyExemption />} noAuthRoute={<Navigate to={'/login'} />} />}
          />
          <Route
            path="/processo-seletivo/resultado"
            element={
              <ProtectedRoute authenticatedRoute={<PreliminaryResult />} noAuthRoute={<Navigate to={'/login'} />} />
            }
          />
          <Route
            path="/imprimir-registration"
            element={
              <ProtectedRoute authenticatedRoute={<PrintRegistration />} noAuthRoute={<Navigate to={'/login'} />} />
            }
          />

          <Route
            path="/processo-seletivo/inscricao/nome-social"
            element={<ProtectedRoute authenticatedRoute={<SocialName />} noAuthRoute={<Navigate to={'/login'} />} />}
          />

          {/* Exemptions */}

          <Route
            path="/painel/isencoes"
            element={<ProtectedRoute authenticatedRoute={<Exemptions />} noAuthRoute={<Navigate to={'/login'} />} />}
          />

          <Route
            path="/painel/isencoes/solicitacao"
            element={
              <ProtectedRoute authenticatedRoute={<ExemptionRequest />} noAuthRoute={<Navigate to={'/login'} />} />
            }
          />

          <Route
            path="/painel/condicao-especial"
            element={
              <ProtectedRoute authenticatedRoute={<SpecialCondition />} noAuthRoute={<Navigate to={'/login'} />} />
            }
          />

          <Route
            path="/login"
            element={<ProtectedRoute authenticatedRoute={<Navigate to={'/'} />} noAuthRoute={<SignIn />} />}
          ></Route>

          <Route
            path="/painel"
            element={<ProtectedRoute authenticatedRoute={<CandidateArea />} noAuthRoute={<Navigate to={'/'} />} />}
          ></Route>

          <Route
            path="/painel/recursos"
            element={<ProtectedRoute authenticatedRoute={<Resources />} noAuthRoute={<Navigate to={'/'} />} />}
          ></Route>

          <Route
            path="/painel/local-de-prova"
            element={
              <ProtectedRoute authenticatedRoute={<ProofLocationCandidateArea />} noAuthRoute={<Navigate to={'/'} />} />
            }
          ></Route>

          <Route
            path="/painel/recursos/mostrar-mais"
            element={
              <ProtectedRoute authenticatedRoute={<ResourceSolicitation />} noAuthRoute={<Navigate to={'/'} />} />
            }
          ></Route>

          <Route
            path="/painel/recursos/mostrar-mais/detalhes"
            element={<ProtectedRoute authenticatedRoute={<DetailResource />} noAuthRoute={<Navigate to={'/'} />} />}
          ></Route>

          <Route
            path="/painel/recursos/novo-recurso"
            element={<ProtectedRoute authenticatedRoute={<NewResource />} noAuthRoute={<Navigate to={'/'} />} />}
          ></Route>

          <Route
            path="/painel/meus-dados"
            element={<ProtectedRoute authenticatedRoute={<Profile />} noAuthRoute={<Navigate to={'/'} />} />}
          ></Route>

          <Route
            path="/painel/alterar-senha"
            element={<ProtectedRoute authenticatedRoute={<ChangePassword />} noAuthRoute={<Navigate to={'/'} />} />}
          ></Route>

          <Route
            path="/painel/:selective_process_id/impugnacao"
            element={<ProtectedRoute authenticatedRoute={<ImpugnationPage />} noAuthRoute={<Navigate to={'/'} />} />}
          ></Route>
          <Route
            path="/painel/impugnacao/detalhes"
            element={<ProtectedRoute authenticatedRoute={<ImpugnationDetail />} noAuthRoute={<Navigate to={'/'} />} />}
          ></Route>

          <Route
            path="/painel/inscricao/"
            element={<ProtectedRoute authenticatedRoute={<MoreDetail />} noAuthRoute={<Navigate to={'/'} />} />}
          ></Route>

          <Route
            path="/painel/segunda-via-cobranca/"
            element={<ProtectedRoute authenticatedRoute={<PaymentReissue />} noAuthRoute={<Navigate to={'/'} />} />}
          ></Route>

          {/* Cadastro */}
          <Route
            path="/cadastro"
            element={<ProtectedRoute authenticatedRoute={<HomePage />} noAuthRoute={<SignUp />} />}
          ></Route>
          <Route
            path="/cadastro-foto"
            element={<ProtectedRoute authenticatedRoute={<ProfilePicture />} noAuthRoute={<ProfilePicture />} />}
          ></Route>

          <Route
            path="/recuperar-senha"
            element={<ProtectedRoute authenticatedRoute={<HomePage />} noAuthRoute={<RecoveryPassword />} />}
          ></Route>
          <Route
            path="/redefinir-senha/:token/"
            element={<ProtectedRoute authenticatedRoute={<HomePage />} noAuthRoute={<ResetPassword />} />}
          ></Route>
          {/* Public Routes */}

          {/* Area do candidato*/}

          {/* Area do candidato*/}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routers;
