export enum EInvoiceStatus {
  open = 'ABERTO',
  nearingDueDate = 'PERTO_D0_VENCIMENTO',
  pastDue = 'VENCIDO',
  depreciation = 'BAIXA',
  canceled = 'CANCELADO',
  paid = 'PAGO',
}

export interface IInvoiceData {
  barcode: string;
  beneficiary: IBeneficiary;
  detailTicket: IDetailTicket;
  digitable_line: string;
  our_number: string;
  payer: IPayer;
  status: string;
  ticket_due_date: string;
  ticket_number: string;
  ticket_value: number;
}

export interface IPayer {
  address_city: string;
  address_neighborhood: string;
  address_number: string;
  address_state: string;
  address_street: string;
  address_zip_code: string;
  cpf_cnpj: string;
  name: string;
}

export interface IBeneficiary {
  address_city: string;
  address_neighborhood: string;
  address_state: string;
  address_street: string;
  address_type: number;
  address_zip_code: string;
  agency: string;
  cnpj: string;
  code: string;
  name: string;
  wallet: string;
}

export interface IDetailTicket {
  accept: string;
  additions: string;
  amount_charged: string;
  bank_Usage: string;
  date_document: string;
  demonstrative: string;
  discounts_rebates: string;
  instructions: string[];
  kind: string;
  number_document: string;
  other_deductions: string;
  payment_place: string;
  processing_date: string;
  traffic_ticket: string;
  type_document: string;
}
