import FormRecoveryPassword from '../../../components/pages/resetPassword/FormRecoveryPassword';
import DefaultPage from '../defatulPage';

const RecoveryPassword: React.FC = () => {
  return (
    <DefaultPage justifyContent="start" mt="30%">
      <FormRecoveryPassword />
    </DefaultPage>
  );
};

export default RecoveryPassword;
