import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';

export interface IRegistration {
  selective_process_id: string;
  selective_process_name: 'Resource finish present success.';
  registrations: IRegistrationsProps[];
  is_cultural_house: boolean;
}

export interface IRegistrationsProps {
  registration_id: string;
  registration_code: number; //ALTERADO
  vacancy_name: string;
  registration_date: string;
  status: string;
  actions: IActions;
  second_vacancy: string;
}

export interface IActions {
  second_billing_statement: boolean;
  speciall_condition: boolean;
  change_type: boolean;
  has_result: boolean;
  social_name: boolean;
  show_test_location?: boolean;
  result_test: string;
  can_update_registration: boolean;
}

const RequestMyRegistrations = async () => {
  const response = await configApi.get<IRegistration[]>('candidate-area/candidate/');
  return response.data;
};

export const useRequestMyRegistration = () => {
  return useQuery({
    queryKey: ['my_registration'],
    queryFn: RequestMyRegistrations,
    refetchOnWindowFocus: false,
    gcTime: 0,
  });
};
