export enum EFormatDate {
    BR = 1,
    US = 2,
}

export const formatDate = (date: string, format?: EFormatDate): string => {
    const separator = format === EFormatDate.US ? '-' : '/';
    const splitDate = date
        .split(format === EFormatDate.US ? '/' : '-')
        .reverse();
        
    return splitDate[0] + separator + splitDate[1] + separator + splitDate[2];
};

export const formatDateTimeBr = (date: string = "", Hour: boolean): string => {
    const splitDate = date.split('T');
    const formatedDate = formatDate(splitDate[0]);
    

    if (Hour && date) {
        return `${formatedDate} - ${splitDate[1].substring(0, 5)}`;
    } else {
        return `${formatedDate}`;
    }
};
