import React, { useState } from 'react';
import { Box, Flex, FormControl, Stack, useDisclosure } from '@chakra-ui/react';
import InputTemplate from '../../InputTemplate/Index';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import CustomButton from '../../Button/Index';
import { configApi } from '../../../services/configApi';

import CustomModal from '../../Modal/ModalTemplate';
import { useNavigate } from 'react-router-dom';
import correto from '../../../assets/correto.png';
import { TextXS } from '../../Text/Index';

const schema = object({
  email: string().required('O campo é obrigatório').email('Formato de e-mail inválido!'),
});

const FormRecoveryPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const recoveryRequest = (data: FieldValues) => {
    setEmail(data.email);
    configApi
      .post('auth/request-password-reset/', { email: data.email.toLowerCase().trim() })
      .then(() => {
        onOpen();
      })
      .catch(() => {
        onOpen();
      });
  };

  return (
    <>
      <Stack as="form" w="100%" onSubmit={handleSubmit(recoveryRequest)}>
        <FormControl display="flex" flexDirection="column" gap="40px" w="100%">
          <Box>
            <InputTemplate label="E-mail" type="email" {...register('email')} />
            <TextXS color={'red'}>{errors.email?.message}</TextXS>
          </Box>
          <Flex flexDirection="column">
            <CustomButton label="ENVIAR" type="submit" />
          </Flex>
        </FormControl>
      </Stack>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        imageSrc={correto}
        modalTitle="E-MAIL ENVIADO COM SUCESSO"
        primaryButtonLabel="IR PARA TELA DE LOGIN"
        primaryButtonOnClick={() => {
          navigate('/login');
        }}
        secondaryButtonLabel="ENVIAR NOVAMENTE"
        secondaryButtonOnClick={onClose}
      >
        <TextXS>
          Recebemos sua solicitação para redefinir sua senha. Um link de redefinição de senha foi enviado para o
          endereço de e-mail associado à sua conta: <strong>{email}</strong>
        </TextXS>
        <TextXS>
          Por favor, verifique sua caixa de entrada e siga as instruções no e-mail para redefinir sua senha. Se você não
          encontrar o e-mail na caixa de entrada, não se esqueça de verificar sua pasta de spam.
        </TextXS>
      </CustomModal>
    </>
  );
};

export default FormRecoveryPassword;
