import { Badge, Stack, Td, Tr } from '@chakra-ui/react';
import HeaderTable from '../../../../components/pages/CandidateArea/TableTest';
import TableCustom from '../../../../components/pages/CandidateArea/Table';
import { useNavigate } from 'react-router-dom';
import { useRequestMyImpugnation } from '../../../../services/hooks/CandidateArea/useRequestMyImpugnation';
import { TextXS } from '../../../../components/Text/Index';
import { badgeColor } from '../../../../utils/badgeFunction';

const Impugnation: React.FC = () => {
  const headerImpugnation = ['PROTOCOLO', 'PROCESSO SELETIVO', 'SITUAÇÃO', 'AÇÕES'];
  const { data } = useRequestMyImpugnation();
  const navigate = useNavigate();
  return (
    <Stack mt={10} px={['10px', '10px', '10px', '50px', '50px']} flexDir="column" justifyContent="space-between">
      {data && (
        <>
          <HeaderTable mainTitle="LISTAGEM DE SOLICITAÇÕES DE IMPUGNAÇÃO DE EDITAL" fontWeight="bold" />
          <TableCustom headers={headerImpugnation}>
            {data &&
              data?.map((impugnation) => (
                <Tr key={impugnation.id} textAlign="center">
                  <Td textAlign="center">
                    <TextXS> {impugnation?.protocol || 'Sem dados'}</TextXS>
                  </Td>
                  <Td textAlign="center">
                    <TextXS>{impugnation?.selective_process_name || 'Sem dados'}</TextXS>
                  </Td>
                  <Td textAlign="center">
                    <Badge
                      borderRadius={4}
                      textAlign="center"
                      lineHeight="normal"
                      p={1}
                      px={2}
                      bg={badgeColor(impugnation?.status)?.bgColor || 'black'}
                      color={badgeColor(impugnation?.status)?.color || 'white'}
                    >
                      {impugnation?.no_reply ? impugnation?.status : '--'}
                    </Badge>
                  </Td>
                  <Td
                    onClick={() => {
                      navigate('/painel/impugnacao/detalhes', { state: impugnation?.id });
                    }}
                    cursor="pointer"
                    color="colorLink"
                    textAlign="center"
                  >
                    <TextXS>Detalhes</TextXS>
                  </Td>
                </Tr>
              ))}
          </TableCustom>
        </>
      )}
    </Stack>
  );
};

export default Impugnation;
