import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';
import { IDateType } from '../Types';

interface IImpugnationDetail {
  id: string;
  candidate: ICandidateProps;
  response_objection: string;
  status: IStatusProps;
  objection: string;
  date_of_response_objection: string;
  created_at: IDateType;
  is_active: boolean;
  protocol: string;
  selective_process: ISelectiveProcessData;
}

interface ISelectiveProcessData {
  id: string;
  name: string;
  registrations_start: IDateType;
  registrations_end: IDateType;
  exemption_start: IDateType;
  exemption_end: IDateType;
  number_of_vacancies: string;
  public_notice_number: string;
  selective_process_situation: ISelectiveProcessSituation[];
  type: string;
  no_reply: string;
}

interface ISelectiveProcessSituation {
  id: string;
  name: string;
}

interface ICandidateProps {
  cpf: string;
  avatar: string;
  phone: string;
  cellphone: string;
  name: string;
  email: string;
}

interface IStatusProps {
  id: string;
  name: string;
}

const GetImpugnationDetail = async (impugnation_id: string) => {
  const response = await configApi.get<IImpugnationDetail>(
    `/selective-process/selective-process-impugnation/${impugnation_id}/`,
  );
  return response.data;
};

export const useGetImpugnationDetail = (impugnation_id: string) => {
  return useQuery({
    queryKey: ['getImpugnationDetail', impugnation_id],
    queryFn: () => GetImpugnationDetail(impugnation_id),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
