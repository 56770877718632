import { Avatar, Flex, Stack } from '@chakra-ui/react';
import { useContext } from 'react';
import { FaRegUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ContextAuth } from '../../contexts/Authentication';
import { TextM, TextXS } from '../Text/Index';

const NoAuthProfileMobile: React.FC = () => {
  const navigate = useNavigate();
  const { isAuth, candidate } = useContext(ContextAuth);

  if (isAuth) {
    return (
      <Flex align="center" gap={5} display={['flex', 'flex', 'flex', 'none', 'none']}>
        <Stack>
          <Avatar w="60px" h="60px" name={candidate?.user.full_name} />
        </Stack>
        <Stack>
          <TextM lineHeight="24px" maxW="150px" justifyContent="flex-end" textAlign="right">
            Olá, <strong>{candidate?.user?.full_name?.split(' ')[0] || 'Não informado'}</strong>
          </TextM>
        </Stack>
      </Flex>
    );
  }

  return (
    <Stack
      display={['flex', 'flex', 'flex', 'none', 'none']}
      flexDir="row"
      gap="10px"
      bg="primaryColor"
      color="white"
      p="10px"
      borderRadius="40px"
      cursor="pointer"
      onClick={() => {
        navigate('/login');
      }}
      alignItems="center"
    >
      <FaRegUserCircle size={30} />
      <TextXS p={2}>Área do Candidato</TextXS>
    </Stack>
  );
};

export default NoAuthProfileMobile;
