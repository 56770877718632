import { Box, Button, Spinner, Stack, useToast } from '@chakra-ui/react';

import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as Yup from 'yup';

import InputTemplate from '../../../components/InputTemplate/Index';
import { IoIosEyeOff } from 'react-icons/io';
import { configApi } from '../../../services/configApi';
import { useNavigate } from 'react-router-dom';
import MainCard from '../../../components/MainCard/Index';
import { useState } from 'react';
import { TextXS } from '../../../components/Text/Index';

const schema = Yup.object().shape({
  old_password: Yup.string(),
  new_password: Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d).{8,}$/,
      'A senha deve ter no mínimo 8 caracteres e conter pelo menos uma letra e um número',
    )
    .required('Senha é obrigatória'),
  confirm_new_password: Yup.string()
    .oneOf([Yup.ref('new_password')], 'As senhas devem coincidir')
    .required('Confirmação de senha é obrigatória'),
});

const ChangePassword: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isSubmiting, setIsSubmiting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const changePasswordRequest = (data: FieldValues) => {
    setIsSubmiting(true);
    configApi
      .post('auth/change-password/', {
        old_password: data.old_password,
        new_password: data.new_password,
        confirm_new_password: data.confirm_new_password,
      })
      .then(() => {
        toast({
          title: 'Sucesso',
          description: 'Senha alterada com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        navigate('/painel');
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;
        if (error.response && error.response.status === 400) {
          toast({
            title: 'Erro',
            description: errorMessage,
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        } else {
          toast({
            title: 'Erro',
            description: 'Erro na solicitação, tente novamente mais tarde',
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        }
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  };

  return (
    <MainCard height="100%" title="ÁREA DO CANDIDATO" subtitle="- ALTERAR SENHA">
      <Stack as="form" w="400px" onSubmit={handleSubmit(changePasswordRequest)} p="30px">
        <InputTemplate
          w={'300px'}
          icon={<IoIosEyeOff size={30} />}
          label="Senha Antiga"
          type="password"
          {...register('old_password')}
        />
        <TextXS color={'red'}>{errors.old_password?.message}</TextXS>
        <InputTemplate
          display={'flex'}
          flexDir={'row'}
          w={'300px'}
          icon={<IoIosEyeOff size={30} />}
          label="Nova Senha"
          type="password"
          {...register('new_password')}
        />
        <TextXS color={'red'}>{errors.new_password?.message}</TextXS>
        <InputTemplate
          display={'flex'}
          flexDir={'row'}
          w={'300px'}
          icon={<IoIosEyeOff size={30} />}
          label="Repita Nova Senha"
          type="password"
          {...register('confirm_new_password')}
        />
        <TextXS color={'red'}>{errors.confirm_new_password?.message}</TextXS>
        <Box display="flex" alignItems={'center'} gap={5} justifyContent={'flex-end'}>
          <TextXS
            color="blue"
            cursor="pointer"
            onClick={() => {
              navigate('/painel');
            }}
          >
            Cancelar
          </TextXS>{' '}
          <Button isDisabled={isSubmiting} type="submit">
            {' '}
            {isSubmiting ? <Spinner /> : 'Enviar'}
          </Button>
        </Box>
      </Stack>
    </MainCard>
  );
};

export default ChangePassword;
