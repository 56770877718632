import { useMutation } from '@tanstack/react-query';
import { configApi } from '../../configApi';
import { ISocialNameObj } from '../../../contexts/types';
import { FieldValues } from 'react-hook-form';

export interface ISocialName {
  recent_photo?: FileList;
  declaration_of_request?: FileList;
  document_with_photo?: FileList;
  social_name?: string;
  ordering_by_social_name?: string;
  restroom_type?: string;
}

interface ISocialNameData {
  oldData: ISocialNameObj;
  newData: FieldValues;
}

const UpdateSocialName = async ({ newData, oldData }: ISocialNameData) => {
  const formData = new FormData();

  if (newData?.recent_photo[0] && newData?.recent_photo !== null) {
    formData.append('recent_photo', newData.recent_photo[0]);
  }

  if (newData?.declaration_of_request[0] && newData?.declaration_of_request !== null) {
    formData.append('declaration_of_request', newData?.declaration_of_request[0]);
  }

  if (newData?.document_with_photo[0] && newData?.document_with_photo !== null) {
    formData.append('document_with_photo', newData?.document_with_photo[0]);
  }

  if (newData?.social_name) {
    formData.append('social_name', newData.social_name);
  }

  if (newData?.ordering_by_social_name) {
    formData.append('ordering_by_social_name', newData.ordering_by_social_name);
  }

  if (newData?.restroom_type) {
    formData.append('restroom_type', newData.restroom_type);
  }

  const response = await configApi.patch(`/candidate/social-name/update/${oldData.id}/`, formData);
  return response;
};

export const useUpdateSocialName = () => {
  return useMutation({
    mutationFn: (data: ISocialNameData) => UpdateSocialName(data),
    mutationKey: ['UpdateSocialName'],
  });
};
