import { Button, Link, Spinner, Stack } from '@chakra-ui/react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { memo } from 'react';
import { TextXS } from '../../Text/Index';
import ProofLocation from '../proofLocation';
import { FaPrint } from 'react-icons/fa';
import { IProofLocationProps } from '../../../services/hooks/ProofLocation/useGetProofLocation';

interface IOwnProps {
  data?: IProofLocationProps;
  isModeViewer: boolean;
}

const ProofLocationButton: React.FC<IOwnProps> = ({ data, isModeViewer }) => {
  return (
    <Stack>
      <PDFDownloadLink document={<ProofLocation data={data} />} fileName="local_de_prova.pdf">
        {({ loading, blob }) => {
          if (isModeViewer) {
            if (!blob) return;
            return (
              <Button
                bg="primaryColor"
                color="white"
                _hover={{ backgroundColor: 'none' }}
                w="fit-content"
                whiteSpace="break-spaces"
                leftIcon={<FaPrint />}
              >
                <Link href={URL.createObjectURL(blob)} target="_blank">
                  {(loading && <Spinner />) || <TextXS>Imprimir Comprovante</TextXS>}
                </Link>
              </Button>
            );
          }
        }}
      </PDFDownloadLink>
    </Stack>
  );
};

export default memo(ProofLocationButton);
