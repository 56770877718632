import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Spinner,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, UseFormReturn, UseFormSetValue, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { validateMaxFile, validateTypeFile, validationSizeFile } from '../../../../../utils/validationForm';
import { useNewExemption } from '../../../../../services/hooks/Exemptions/UseNewExemption';
import { useRequestGetState } from '../../../../../services/hooks/SignUp/useRequestGetState';
import MessageError from '../../../../../components/MessageError';
import { IoChevronDownCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { TextXS } from '../../../../../components/Text/Index';

interface IOwnProps {
  exemptionId: string;
  registrationId: string;
  hideButton?: boolean;
  hookForms?: UseFormReturn<INISProps>;
  onSetValue?: UseFormSetValue<FieldValues>;
}

export interface INISProps {
  document_number?: string;
  document_issuing_body?: string;
  document_issuing_body_uf?: string;
  nis?: string;
  mother_name?: string;
}

const NIS: React.FC<IOwnProps> = ({ exemptionId, registrationId, hideButton = false, hookForms, onSetValue }) => {
  const newExemption = useNewExemption();
  const getState = useRequestGetState();
  const toast = useToast();
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    document_number: Yup.string().required('Campo Obrigatório'),
    document_issuing_body: Yup.string().required('Campo Obrigatório'),
    document_issuing_body_uf: Yup.string().required('Campo Obrigatório'),
    nis: Yup.string().required('Campo Obrigatório'),
    mother_name: Yup.string().required('Campo Obrigatório'),
    attachmentExemption: Yup.mixed()
      .test('validationSizeFile', 'Tamanho máximo para arquivos é de 2MB', (file) => {
        const listFile = file as FileList;

        if (listFile?.length > 0) {
          return validationSizeFile(listFile);
        }

        return false;
      })
      .test('validationMaxFile', 'Quantidade máxima de arquivos é de 5 arquivos', (file) => {
        const listFile = file as FileList;

        if (listFile?.length > 0) {
          return validateMaxFile(listFile);
        }
      })
      .test('validationTypeFile', 'Formato de arquivo não suportado', (file) => {
        const listFile = file as FileList;

        if (listFile?.length > 0) {
          return validateTypeFile(listFile);
        }
      }),
  });

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const handleCreateNewExemption = (data: FieldValues) => {
    exemptionSolicitation(data);
  };

  const handleGoToCandidateArea = (data: FieldValues) => {
    exemptionSolicitation(data, '/painel/isencoes');
  };

  const exemptionSolicitation = (data: FieldValues, route?: string) => {
    newExemption
      .mutateAsync({ ...data, type: exemptionId, registration: registrationId })
      .then(() => {
        toast({
          title: 'Sucesso',
          description: 'Pedido de isenção enviado com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        if (onSetValue) onSetValue('type_exemption', '');

        if (route) {
          navigate(route);
        }
      })
      .catch(() => {
        toast({
          title: 'Erro',
          description: 'Erro na solicitação, tente novamente',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      });
  };

  const allErrors = Object.entries((hookForms ? hookForms.formState : formState).errors);
  const isExemptionError = [
    'document_number',
    'document_issuing_body',
    'document_issuing_body_uf',
    'nis',
    'mother_name',
  ];

  const blankErrors = allErrors.filter((value) => value[1].type === 'required').map((e) => e[0]);

  const hasErrorExemption = isExemptionError.filter((elemento) => blankErrors.includes(elemento[0])).length > 0;

  return (
    <Stack>
      <Flex as="form" rounded={4} flexDir="column" borderRadius="4px" bg="lightGrey" p={5} gap={5}>
        <TextXS>
          Texto explicativo de orientação ao candidato que deve aparecer na página de solicitação do pedido de isenção
        </TextXS>
        <Stack w="fit-content">
          <Stack>
            <FormControl w="fit-content" flexDir="row" display="flex" alignItems="center">
              <FormLabel minW="150px" textAlign="right">
                RG
              </FormLabel>

              <Flex gap={1}>
                <Stack>
                  <Input
                    borderColor={
                      (hookForms ? hookForms.formState : formState).errors.document_number ? 'red' : 'transparent'
                    }
                    required
                    {...(hookForms ? hookForms.register('document_number') : register('document_number'))}
                    placeholder="Número do documento"
                    w="200px"
                    multiple
                    bg="white"
                    alignContent="center"
                  />
                </Stack>
                <Stack>
                  <Input
                    borderColor={
                      (hookForms ? hookForms.formState : formState).errors.document_issuing_body ? 'red' : 'transparent'
                    }
                    {...(hookForms
                      ? hookForms.register('document_issuing_body')
                      : register('document_issuing_body_uf'))}
                    placeholder="Órgão"
                    w="100px"
                    multiple
                    bg="white"
                    alignContent="center"
                  />
                </Stack>
                <Stack>
                  <Select
                    borderColor={
                      (hookForms ? hookForms.formState : formState).errors.document_issuing_body_uf
                        ? 'red'
                        : 'transparent'
                    }
                    {...(hookForms
                      ? hookForms.register('document_issuing_body_uf')
                      : register('document_issuing_body_uf'))}
                    w="130px"
                    bg="white"
                    alignContent="center"
                    fontSize="14px"
                    value={hookForms?.watch('document_issuing_body_uf')}
                  >
                    <option hidden value="">
                      Selecione
                    </option>
                    {getState.data?.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.initials}
                      </option>
                    ))}
                  </Select>
                </Stack>
              </Flex>
            </FormControl>
          </Stack>
          <FormControl flexDir="row" display="flex">
            <FormLabel textAlign="right" minW="150px">
              Número do NIS
            </FormLabel>
            <Input
              borderColor={(hookForms ? hookForms.formState : formState).errors.nis ? 'red' : 'transparent'}
              {...(hookForms ? hookForms.register('nis') : register('nis'))}
              w="437px"
              bg="white"
            />
          </FormControl>
          <FormControl flexDir="row" display="flex">
            <FormLabel textAlign="right" minW="150px">
              Nome da Mãe
            </FormLabel>
            <Input
              borderColor={(hookForms ? hookForms.formState : formState).errors.mother_name ? 'red' : 'transparent'}
              {...(hookForms ? hookForms.register('mother_name') : register('mother_name'))}
              w="437px"
              bg="white"
            />
          </FormControl>

          {hasErrorExemption && <MessageError>Preencha todos os campos de isenção</MessageError>}
          {hookForms?.formState.errors.nis?.message && (
            <MessageError>{hookForms?.formState.errors.nis?.message}</MessageError>
          )}

          {/* <FormControl flexDir="row" display="flex">
            <FormLabel textAlign="right" minW="150px">
              Arquivo
            </FormLabel>
            <Input
              {...register('attachmentExemption', {
                onChange: (value) => {
                  if (onSetValue && hideButton) {
                    onSetValue('attachmentExemption', value.target.files);
                  }
                },
              })}
              alignContent="center"
              type="file"
              w="437px"
              bg="white"
            />
          </FormControl>
          <MessageError>{formState.errors.attachmentExemption?.message}</MessageError> */}
          <Stack align="flex-end">
            {!hideButton && (
              <Menu>
                <MenuButton
                  isDisabled={newExemption.isPending}
                  bg="primaryColor"
                  color="white"
                  _hover="none"
                  as={Button}
                  rightIcon={<IoChevronDownCircleOutline />}
                >
                  {newExemption.isPending ? <Spinner /> : 'Enviar'}
                </MenuButton>
                <MenuList>
                  <MenuItem as="button" onClick={handleSubmit(handleCreateNewExemption)}>
                    Enviar e permanecer na pagina
                  </MenuItem>
                  <MenuItem as="button" onClick={handleSubmit(handleGoToCandidateArea)}>
                    Enviar e ir para Isenções
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Stack>
        </Stack>
      </Flex>
    </Stack>
  );
};

export default NIS;
