import { ListItem, UnorderedList, useToast } from '@chakra-ui/react';
import { IActions } from '../../../../services/hooks/CandidateArea/useRequestMyRegistration';
import { useNavigate } from 'react-router-dom';
import { TextXS } from '../../../../components/Text/Index';

interface IActionsList {
  actions: IActions;
  registrationId: string;
  selectiveProcessId: string;
}

const ActionList: React.FC<IActionsList> = ({ registrationId, actions, selectiveProcessId }) => {
  const navigate = useNavigate();
  const toast = useToast();

  const proofLocationFunction = () => {
    sessionStorage.setItem('registrationId', registrationId);
    navigate('/painel/local-de-prova');
  };

  const downloadItem = async () => {
    if (actions?.result_test && document?.body) {
      try {
        const response = await fetch(actions?.result_test);

        if (!response.ok) {
          toast({
            title: 'Erro ao fazer o download do arquivo',
            description: 'Tente novamente mais tarde',
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
          return;
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'folha_resposta.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Erro ao baixar o arquivo: ', error);
      }
    }
  };

  return (
    <UnorderedList maxW="300px" styleType="'> '" color="colorLink">
      <ListItem onClick={() => navigate('/painel/inscricao/', { state: registrationId })} cursor="pointer">
        <TextXS>Detalhes da inscrição</TextXS>
      </ListItem>

      {actions.show_test_location && (
        <ListItem onClick={proofLocationFunction} cursor="pointer">
          <TextXS>Local de prova</TextXS>
        </ListItem>
      )}
      {actions.has_result && (
        <ListItem onClick={() => navigate('/processo-seletivo/resultado', { state: registrationId })} cursor="pointer">
          <TextXS>Resultado preliminar</TextXS>
        </ListItem>
      )}

      {actions.result_test && (
        <ListItem onClick={downloadItem} cursor="pointer">
          <TextXS>Folha resposta</TextXS>
        </ListItem>
      )}

      {/* {actions.change_type && <ListItem>Alterar modalidade</ListItem>} */}

      {/* <ListItem
        cursor="pointer"
        onClick={() =>
          navigate('/painel/isencoes/solicitacao', {
            state: { registrationId: registrationId, previousRoute: '/painel' },
          })
        }
      >
        <TextXS>Solicitação de isenção</TextXS>
      </ListItem> */}
      {/* 
      {actions.speciall_condition && (
        <ListItem
          cursor="pointer"
          whiteSpace="break-spaces"
          onClick={() =>
            navigate('/painel/condicao-especial', {
              state: {
                registrationId: registrationId,
                previousRoute: '/painel',
                selectiveProcessId: selectiveProcessId,
              },
            })
          }
        >
          <TextXS>Condições especiais para a prova</TextXS>
        </ListItem>
      )} */}

      {actions.can_update_registration && (
        <ListItem
          cursor="pointer"
          onClick={() =>
            navigate(
              `/processo-seletivo/${selectiveProcessId}/editar-inscricao/${registrationId}?previousRoute=/painel`,
            )
          }
        >
          <TextXS>Modificar dados da inscrição</TextXS>
        </ListItem>
      )}

      {actions.second_billing_statement && (
        <ListItem
          cursor="pointer"
          onClick={() =>
            navigate('/painel/segunda-via-cobranca/', {
              state: { registrationId: registrationId, previousRoute: '/painel' },
            })
          }
        >
          <TextXS>Emitir boleto de cobrança</TextXS>
        </ListItem>
      )}

      {/* <ListItem
        cursor="pointer"
        onClick={() =>
          navigate('/processo-seletivo/inscricao/nome-social', {
            state: { registrationId: registrationId, previousRoute: '/painel' },
          })
        }
      >
        <TextXS> Informar nome social</TextXS>
      </ListItem> */}
    </UnorderedList>
  );
};

export default ActionList;

{
  /* <UnorderedList styleType="'> '" color="colorLink">
  <ListItem cursor="pointer">Mais informações</ListItem>
  {registration.actions.change_type && <ListItem>Alterar modalidade</ListItem>}
  {registration.actions.second_billing_statement && <ListItem>2ª via de cobrança</ListItem>}
  {registration.actions.speciall_condition && <ListItem>Condições especiais para a prova</ListItem>}
  {registration.actions.social_name && <ListItem>Nome Social</ListItem>}
</UnorderedList>; */
}
